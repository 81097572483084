(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _trees = require("./trees.js");

var _engine = require("./engine/engine.js");

var _scenes = require("./scenes/scenes.js");

function load() {
  _engine.engine.levels.push(_scenes.scenes.level0);
  _engine.engine.levels.push(_scenes.scenes.level2);
  _engine.engine.levels.push(_scenes.scenes.level3);
  _engine.engine.levels.push(_scenes.scenes.level4);
  _engine.engine.levels.push(_scenes.scenes.level5);
  _engine.engine.levels.push(_scenes.scenes.level6);
  _engine.engine.levels.push(_scenes.scenes.level7);
  _engine.engine.levels.push(_scenes.scenes.level8);
  _engine.engine.levels.push(_scenes.scenes.level9);
  _scenes.scenes.level0();
}

_engine.engine.go(load);

},{"./engine/engine.js":56,"./scenes/scenes.js":87,"./trees.js":88}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.animate = undefined;

var _shapesregistry = require("./shapesregistry.js");

var shapesRegistry = new _shapesregistry.ShapesRegistry();
var now = void 0;
var then = Date.now();
var delta = void 0;
var pause = false;

function animate() {
  if (!shapesRegistry.static) {
    requestAnimationFrame(function () {
      pause || animate();
    });
  }

  now = Date.now();
  delta = now - then;

  if (delta > shapesRegistry._interval) {
    // update time stuffs

    // Just `then = now` is not enough.
    // Lets say we set fps at 10 which means
    // each frame must take 100ms
    // Now frame executes in 16ms (60fps) so
    // the loop iterates 7 times (16*7 = 112ms) until
    // delta > _interval === true
    // Eventually this lowers down the FPS as
    // 112*10 = 1120ms (NOT 1000ms).
    // So we have to get rid of that extra 12ms
    // by subtracting delta (112) % _interval (100).
    // Hope that makes sense.

    then = now - delta % shapesRegistry._interval;

    // ... Code for Drawing the Frame ...
    if (shapesRegistry.length) {
      shapesRegistry.dynamicBackgroundCanvas.ctx.clearRect(0, 0, shapesRegistry.dynamicBackgroundCanvas.width, shapesRegistry.dynamicBackgroundCanvas.height);

      shapesRegistry.cache.forEach(function (shape) {
        try {
          if (!shapesRegistry.static && (shape.boundary.a.x > shape.canvas.width.percent(110) || shape.boundary.b.x < -shape.canvas.width.percent(10))) {
            //shapesRegistry.remove(shape);
            return;
          }

          if (!shapesRegistry.static && (shape.boundary.a.y > shape.canvas.currentY + window.innerHeight.percent(110) || shape.boundary.d.y < shape.canvas.currentY - shape.canvas.height.percent(10))) {
            //shapesRegistry.remove(shape);
            return;
          }
          if (shape.animate) {
            shape.animate();
          }
          if (shape.callback) {
            shape.callback();
          }

          if (shape.visible) shape.draw();
        } catch (e) {
          pause = true;
          console.error(e);
        }
      });
    }
  }
}

exports.animate = animate;

},{"./shapesregistry.js":64}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Canvas = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _point = require("./point.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

CanvasRenderingContext2D.prototype.curve = function (points) {
  if (points) {
    this.bezierCurveTo(points.cp1.x, points.cp1.y, points.cp2.x, points.cp2.y, points.end.x, points.end.y);
  }
};

CanvasRenderingContext2D.prototype.yLine = function (a) {
  this.lineTo(a.x, a.y);
};

CanvasRenderingContext2D.prototype.yRect = function (rect) {
  this.yMove(rect.a);
  this.yLine(rect.b);
  this.yLine(rect.c);
  this.yLine(rect.d);
  this.yLine(rect.a);
};
CanvasRenderingContext2D.prototype.yMove = function (point) {
  this.moveTo(point.x, point.y);
};
CanvasRenderingContext2D.prototype.yArc = function (arc) {
  this.arc(arc.x, arc.y, arc.r, arc.sAngle, arc.eAngle);
};

var Canvas = function () {
  function Canvas(parentNodeName) {
    _classCallCheck(this, Canvas);

    this.element = document.createElement("canvas");
    this.ctx = this.element.getContext("2d");
    this._center = new _point.Point(this.element.width / 2, this.element.height / 2);
    this._width = this.element.width;
    this._height = this.element.height;

    this._currentY = 0;

    this.parentNode = document.getElementById(parentNodeName) || document.body;

    var dpr = window.devicePixelRatio || 1;
    var bsr = this.ctx.webkitBackingStorePixelRatio || this.ctx.mozBackingStorePixelRatio || this.ctx.msBackingStorePixelRatio || this.ctx.oBackingStorePixelRatio || this.ctx.backingStorePixelRatio || 1;

    this.pixelRatio = dpr / bsr;

    //Create canvas with the device resolution.
    // this.createCanvas(500, 250);

    //Create canvas with a custom resolution.
    //var myCustomCanvas = createHiDPICanvas(500, 200, 4);
  }

  _createClass(Canvas, [{
    key: "measureText",
    value: function measureText(text, font) {
      this.ctx.font = font;
      return this.ctx.measureText(text).width;
    }
  }, {
    key: "createCanvas",
    value: function createCanvas(w, h, ratio) {
      try {
        this.parentNode.removeChild(this.element);
      } catch (e) {}

      if (!ratio) {
        ratio = this.pixelRatio;
      }
      var can = document.createElement("canvas");
      can.width = w * ratio;
      can.height = h * ratio;
      can.style.width = w + "px";
      can.style.height = h + "px";

      this.ctx = can.getContext("2d");
      this.element = can;
      this.ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
      this.parentNode.appendChild(this.element);
      console.log("created canvas", this);
    }
  }, {
    key: "setSize",
    value: function setSize(width, height) {
      this.width = width;
      this.height = height;
      this._center = new _point.Point(this._center.x, height / 2);
      this.createCanvas(width, this.height);
    }
  }, {
    key: "addEventListener",
    value: function addEventListener(event, func) {
      this.element.addEventListener(event, func, false);
    }
  }, {
    key: "dispatchEvent",
    value: function dispatchEvent(event) {
      this.element.dispatchEvent(event);
    }
  }, {
    key: "getBoundingClientRect",
    value: function getBoundingClientRect() {
      return this.element.getBoundingClientRect();
    }
  }, {
    key: "scroll",
    value: function scroll(amount) {
      if (this.currentY + amount > 0 && this.currentY + amount < this.height) {
        this.currentY += amount;
        this.element.style.marginTop = -this.currentY + "px";
      }
    }
  }, {
    key: "width",
    set: function set(width) {
      this._width = width;
    },
    get: function get() {
      return this._width;
    }
  }, {
    key: "height",
    set: function set(height) {
      this._height = height;
    },
    get: function get() {
      return this._height;
    }
  }, {
    key: "center",
    get: function get() {
      return this._center;
    }
  }, {
    key: "currentY",
    get: function get() {
      return this._currentY;
    },
    set: function set(currentY) {
      this._currentY = currentY;
    }
  }]);

  return Canvas;
}();

exports.Canvas = Canvas;

},{"./point.js":63}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Arrow = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require('../simples/simples.js');

var _complexShape = require('../complex/complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Arrow = function (_ComplexShape) {
    _inherits(Arrow, _ComplexShape);

    function Arrow(x, y, width, height, angle) {
        _classCallCheck(this, Arrow);

        var _this = _possibleConstructorReturn(this, (Arrow.__proto__ || Object.getPrototypeOf(Arrow)).call(this, x, y, width, height, angle));

        _this.type = "Arrow";

        var rectangle = new _simples.simples.Rectangle(x, y + height / 4, width / 2, height / 2, 0);
        var triangle = new _simples.simples.Triangle(x + width / 4, y + height / 4, width, height / 2, 90);
        _this.addShape(rectangle);
        _this.addShape(triangle);
        return _this;
    }

    _createClass(Arrow, [{
        key: 'draw',
        value: function draw(ctx) {
            _get(Arrow.prototype.__proto__ || Object.getPrototypeOf(Arrow.prototype), 'draw', this).call(this, ctx);

            ctx.beginPath();

            this.shape.forEach(function (shape) {
                shape.draw(ctx);
            });

            ctx.fill();
            if (this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }]);

    return Arrow;
}(_complexShape.ComplexShape);

exports.Arrow = Arrow;

},{"../complex/complexShape.js":37,"../simples/simples.js":69}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Bird = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require("../simples/simples.js");

var _complexShape = require("../complex/complexShape.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Bird = function (_ComplexShape) {
  _inherits(Bird, _ComplexShape);

  function Bird(x, y, width, height, angle) {
    _classCallCheck(this, Bird);

    var _this = _possibleConstructorReturn(this, (Bird.__proto__ || Object.getPrototypeOf(Bird)).call(this, x, y, width, height, angle));

    _this.type = "Tree";

    _this.tzoid = new _simples.simples.Trapezoid(_this.center.x - _this.width / 2, y, _this.width, _this.height / 2, 135, 45);
    var rectangle = new _simples.simples.Rectangle(x, _this.center.y - height / 4, width * 0.8, height / 8);
    _this.tzoid.color = "#333333";
    rectangle.color = "#333333";
    var triangle2 = new _simples.simples.Triangle(x, y + height / 4, width / 4, height / 4, 0);
    var triangle3 = new _simples.simples.Triangle(x, y, width, height / 4, 0);

    _this.addShape(_this.tzoid);
    _this.addShape(rectangle);
    // this.addShape(triangle2);
    // this.addShape(triangle3);
    // this.addShape(rectangle);
    _this.counter = 0;
    return _this;
  }

  _createClass(Bird, [{
    key: "animate",
    value: function animate() {
      _get(Bird.prototype.__proto__ || Object.getPrototypeOf(Bird.prototype), "animate", this).call(this);
      // this.tzoid.rotate(30, this.tzoid.center);
      if (this.counter >= 2) {
        this.tzoid.flipVertical();
        this.counter = 0;
      }
      this.counter++;
    }
  }, {
    key: "draw",
    value: function draw(ctx) {
      ctx = ctx || this.canvas && this.canvas.ctx;
      _get(Bird.prototype.__proto__ || Object.getPrototypeOf(Bird.prototype), "draw", this).call(this, ctx);

      ctx.beginPath();

      this.shape.forEach(function (shape) {
        shape.draw(ctx);
      });

      ctx.fill();
      if (this.lineColor) ctx.stroke();
      ctx.closePath();
    }
  }]);

  return Bird;
}(_complexShape.ComplexShape);

exports.Bird = Bird;

},{"../complex/complexShape.js":37,"../simples/simples.js":69}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Cave = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _simples = require('../simples/simples.js');

var _complex = require('../complex/complex.js');

var _client = require('./client.js');

var _patterns = require('../patterns/patterns.js');

var _complexShape = require('../complex/complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Cave = function (_ComplexShape) {
    _inherits(Cave, _ComplexShape);

    function Cave(x, y, width, height) {
        _classCallCheck(this, Cave);

        var _this = _possibleConstructorReturn(this, (Cave.__proto__ || Object.getPrototypeOf(Cave)).call(this, x, y, width, height));

        _this.type = "Cave";

        var rectangle1 = new _complex.complex.Box(x - width.percent(3), y - width.percent(3), width.percent(30), height.percent(15));
        rectangle1.rotate(-20, rectangle1.center);
        var edge1 = rectangle1.lines()[2];

        var rectangle2 = new _complex.complex.Box(width.percent(45), y - width.percent(5), width.percent(30), height.percent(15));
        rectangle2.rotate(20, rectangle2.center);
        var edge2 = rectangle2.lines()[2];

        var rectangle3 = new _complex.complex.Box(width.percent(70), y - width.percent(5), width.percent(30), height.percent(15));
        rectangle3.rotate(-20, rectangle3.center);
        var edge3 = rectangle3.lines()[2];

        var rectangle4 = new _complex.complex.Box(x, y + height.percent(10), width.percent(3), height.percent(60));
        var edge4 = rectangle4.lines()[1];

        var rectangle5 = new _complex.complex.Box(x - width.percent(3), y + height.percent(75), width.percent(30), height.percent(15));
        rectangle5.rotate(20, rectangle5.center);
        var edge5 = rectangle5.lines()[0];

        var rectangle6 = new _complex.complex.Box(x + width.percent(25), y + height.percent(75), width.percent(75), height.percent(15));
        rectangle6.rotate(-10, rectangle6.center);
        var edge6 = rectangle6.lines()[0];

        var rectangle7 = new _complex.complex.Box(x + width.percent(97), y, width.percent(3), height.percent(80));
        var edge7 = rectangle7.lines()[3];

        var rectangle8 = new _complex.complex.Box(x, y + height.percent(80), width, height.percent(20));

        new _complex.complex.RockyBorder(rectangle1, width.percent(5), edge1);
        new _complex.complex.RockyBorder(rectangle2, width.percent(5), edge2);
        new _complex.complex.RockyBorder(rectangle3, width.percent(5), edge3);
        new _complex.complex.RockyBorder(rectangle4, width.percent(5), edge4);
        new _complex.complex.RockyBorder(rectangle5, width.percent(5), edge5);
        new _complex.complex.RockyBorder(rectangle6, width.percent(5), edge6);
        new _complex.complex.RockyBorder(rectangle7, width.percent(5), edge7);

        _this.addShape(rectangle1);
        _this.addShape(rectangle2);
        _this.addShape(rectangle3);
        _this.addShape(rectangle4);
        _this.addShape(rectangle5);
        _this.addShape(rectangle6);
        _this.addShape(rectangle7);
        _this.addShape(rectangle8);

        _this.addMushroom(edge5);
        _this.addMushroom(edge5);
        _this.addMushroom(edge6);
        _this.addMushroom(edge6);
        _this.addMushroom(edge6);
        _this.addMushroom(edge6);
        _this.addMushroom(edge6);
        _this.addMushroom(edge6);
        return _this;
    }

    _createClass(Cave, [{
        key: 'addMushroom',
        value: function addMushroom(edge) {
            var distance = trees.getDistance(edge.start, edge.end);
            var location = trees.getPointOnLine(edge.start, trees.random(0, distance), trees.getAngle(edge.start, edge.end));
            var mushroom = new _client.client.Mushroom(location.x, location.y - this.height.percent(5), this.width.percent(trees.random(1, 3)), this.height.percent(trees.random(4, 5)));
            this.addShape(mushroom);
        }
    }]);

    return Cave;
}(_complexShape.ComplexShape);

exports.Cave = Cave;

},{"../complex/complex.js":36,"../complex/complexShape.js":37,"../patterns/patterns.js":58,"../simples/simples.js":69,"./client.js":7}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.client = undefined;

var _arrow = require("./arrow.js");

var _doubleArrow = require("./doubleArrow.js");

var _door = require("./door.js");

var _tree = require("./tree.js");

var _wheel = require("./wheel.js");

var _waterWheel = require("./waterWheel.js");

var _mushroom = require("./mushroom.js");

var _octopus = require("./octopus.js");

var _cloud = require("./cloud.js");

var _mountains = require("./mountains.js");

var _hotAirBalloon = require("./hotAirBalloon.js");

var _stripedBalloon = require("./stripedBalloon.js");

var _fancyBalloon = require("./fancyBalloon.js");

var _cup = require("./cup.js");

var _spray = require("./spray.js");

var _erlenmeyer = require("./erlenmeyer.js");

var _testTube = require("./testTube.js");

var _shoreLine = require("./shoreLine.js");

var _lake = require("./lake.js");

var _cave = require("./cave.js");

var _bird = require("./bird.js");

var _crystal = require("./crystal.js");

var _gleamingCrystal = require("./gleamingCrystal.js");

var client = {
  Arrow: _arrow.Arrow,
  DoubleArrow: _doubleArrow.DoubleArrow,
  Door: _door.Door,
  Tree: _tree.Tree,
  Wheel: _wheel.Wheel,
  WaterWheel: _waterWheel.WaterWheel,
  Mushroom: _mushroom.Mushroom,
  Octopus: _octopus.Octopus,
  Cloud: _cloud.Cloud,
  Bird: _bird.Bird,
  Mountains: _mountains.Mountains,
  HotAirBalloon: _hotAirBalloon.HotAirBalloon,
  StripedBalloon: _stripedBalloon.StripedBalloon,
  FancyBalloon: _fancyBalloon.FancyBalloon,
  Cup: _cup.Cup,
  Spray: _spray.Spray,
  Erlenmeyer: _erlenmeyer.Erlenmeyer,
  TestTube: _testTube.TestTube,
  ShoreLine: _shoreLine.ShoreLine,
  Lake: _lake.Lake,
  Cave: _cave.Cave,
  Crystal: _crystal.Crystal,
  GleamingCrystal: _gleamingCrystal.GleamingCrystal
};

exports.client = client;

},{"./arrow.js":4,"./bird.js":5,"./cave.js":6,"./cloud.js":8,"./crystal.js":9,"./cup.js":10,"./door.js":11,"./doubleArrow.js":12,"./erlenmeyer.js":13,"./fancyBalloon.js":14,"./gleamingCrystal.js":15,"./hotAirBalloon.js":16,"./lake.js":17,"./mountains.js":18,"./mushroom.js":19,"./octopus.js":20,"./shoreLine.js":21,"./spray.js":22,"./stripedBalloon.js":23,"./testTube.js":24,"./tree.js":25,"./waterWheel.js":26,"./wheel.js":27}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Cloud = undefined;

var _simples = require("../simples/simples.js");

var _complex = require("../complex/complex.js");

var _complexShape = require("../complex/complexShape.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Cloud = function (_ComplexShape) {
  _inherits(Cloud, _ComplexShape);

  function Cloud(x, y, width, height, angle) {
    _classCallCheck(this, Cloud);

    var _this = _possibleConstructorReturn(this, (Cloud.__proto__ || Object.getPrototypeOf(Cloud)).call(this, x, y, width, height, angle));

    _this.type = "Cloud";

    var maxSize = _this.width / 2;
    var previousCircle = void 0;
    var circleX = void 0;
    var moreCircles = true;

    while (moreCircles) {
      //the x of the circle should overlap the right edge of the previous circle by 25%
      circleX = previousCircle ? previousCircle.b.x - previousCircle.width / 4 : _this.x;

      //the max size of any circle should be 50% of the width. Min size is 25%
      var size = trees.random(maxSize / 4, maxSize);

      //create the circle.
      var circle = new _complex.complex.SemiCircle(circleX, _this.d.y - size / 2, size, size / 2);
      _this.addShape(circle);
      previousCircle = circle;

      //if the circle's right edge exceeds the width of the cloud, move it over.
      //otherwise add another circle.
      if (circle.b.x > _this.b.x) {
        // circle.x = this.b.x - circle.width;
        moreCircles = false;
      }
    }
    return _this;
  }

  return Cloud;
}(_complexShape.ComplexShape);

exports.Cloud = Cloud;

},{"../complex/complex.js":36,"../complex/complexShape.js":37,"../simples/simples.js":69}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Crystal = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _point = require('../point.js');

var _simples = require('../simples/simples.js');

var _complexShape = require('../complex/complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Crystal = function (_ComplexShape) {
    _inherits(Crystal, _ComplexShape);

    function Crystal(x, y, width, height) {
        _classCallCheck(this, Crystal);

        var _this = _possibleConstructorReturn(this, (Crystal.__proto__ || Object.getPrototypeOf(Crystal)).call(this, x, y, width, height));

        _this.type = "Crystal";

        var horizontalLine = trees.random(_this.height.percent(10), _this.height.percent(50));
        var verticalLine1 = trees.random(0, _this.width / 2);
        var verticalLine2 = trees.random(verticalLine1, _this.width);

        var top = new _point.Point(_this.center.x, _this.y);
        var bottom = new _point.Point(_this.center.x, _this.y + _this.height);
        var left = new _point.Point(_this.x, _this.y + horizontalLine);
        var centerLeft = new _point.Point(_this.x + verticalLine1, _this.y + horizontalLine);
        var centerRight = new _point.Point(_this.x + verticalLine2, _this.y + horizontalLine);
        var right = new _point.Point(_this.x + _this.width, _this.y + horizontalLine);

        _this.triangle1 = new _simples.simples.Triangle(x, y, width, height);
        _this.triangle2 = new _simples.simples.Triangle(x, y, width, height);
        _this.triangle3 = new _simples.simples.Triangle(x, y, width, height);
        _this.triangle4 = new _simples.simples.Triangle(x, y, width, height);
        _this.triangle5 = new _simples.simples.Triangle(x, y, width, height);
        _this.triangle6 = new _simples.simples.Triangle(x, y, width, height);

        _this.triangle1.points = [left, top, centerLeft];
        _this.triangle2.points = [centerLeft, top, centerRight];
        _this.triangle3.points = [centerRight, top, right];
        _this.triangle4.points = [left, bottom, centerLeft];
        _this.triangle5.points = [centerLeft, bottom, centerRight];
        _this.triangle6.points = [centerRight, bottom, right];

        _this.addShape(_this.triangle1);
        _this.addShape(_this.triangle2);
        _this.addShape(_this.triangle3);
        _this.addShape(_this.triangle4);
        _this.addShape(_this.triangle5);
        _this.addShape(_this.triangle6);

        return _this;
    }

    _createClass(Crystal, [{
        key: 'color',
        get: function get() {
            return _get(Crystal.prototype.__proto__ || Object.getPrototypeOf(Crystal.prototype), 'color', this);
        },
        set: function set(color) {
            var _this2 = this;

            _set(Crystal.prototype.__proto__ || Object.getPrototypeOf(Crystal.prototype), 'color', color, this);
            this.shape.forEach(function (shape) {
                shape.color = trees.shadeColor(_this2.color, trees.random(-50, 50) / 100);
            });
        }
    }]);

    return Crystal;
}(_complexShape.ComplexShape);

exports.Crystal = Crystal;

},{"../complex/complexShape.js":37,"../point.js":63,"../simples/simples.js":69}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Cup = undefined;

var _simples = require("../simples/simples.js");

var _complexShape = require("../complex/complexShape.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Cup = function (_ComplexShape) {
  _inherits(Cup, _ComplexShape);

  function Cup(x, y, width, height, taper) {
    _classCallCheck(this, Cup);

    var _this = _possibleConstructorReturn(this, (Cup.__proto__ || Object.getPrototypeOf(Cup)).call(this, x, y, width, height));

    _this.type = "Cup";
    _this.taper = taper;
    var shape = new _simples.simples.Trapezoid(x, y, width, height, taper, taper);
    shape.openingIndex = 0;
    shape.bottomIndex = 2;
    _this.addShape(shape);
    return _this;
  }

  return Cup;
}(_complexShape.ComplexShape);

exports.Cup = Cup;

},{"../complex/complexShape.js":37,"../simples/simples.js":69}],11:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Door = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require('../simples/simples.js');

var _complexShape = require('../complex/complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Door = function (_ComplexShape) {
    _inherits(Door, _ComplexShape);

    function Door(x, y, width, height, angle) {
        _classCallCheck(this, Door);

        var _this = _possibleConstructorReturn(this, (Door.__proto__ || Object.getPrototypeOf(Door)).call(this, x, y, width, height, angle));

        _this.type = "Door";

        var circle = new _simples.simples.Circle(x, y, width, width, 0);
        var rectangle = new _simples.simples.Rectangle(x, y + width / 2, width, height - width / 2, 0);
        _this.addShape(circle);
        _this.addShape(rectangle);
        return _this;
    }

    _createClass(Door, [{
        key: 'draw',
        value: function draw(ctx) {
            _get(Door.prototype.__proto__ || Object.getPrototypeOf(Door.prototype), 'draw', this).call(this, ctx);

            ctx.beginPath();
            this.shape.forEach(function (shape) {
                shape.draw(ctx);
            });

            ctx.fill();
            if (this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }]);

    return Door;
}(_complexShape.ComplexShape);

exports.Door = Door;

},{"../complex/complexShape.js":37,"../simples/simples.js":69}],12:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.DoubleArrow = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _complex = require('../complex/complex.js');

var _simples = require('../simples/simples.js');

var _complexShape = require('../complex/complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DoubleArrow = function (_ComplexShape) {
    _inherits(DoubleArrow, _ComplexShape);

    function DoubleArrow(x, y, width, height, angle) {
        _classCallCheck(this, DoubleArrow);

        var _this = _possibleConstructorReturn(this, (DoubleArrow.__proto__ || Object.getPrototypeOf(DoubleArrow)).call(this, x, y, width, height, angle));

        _this.type = "DoubleArrow";

        var arrow1 = new _complex.complex.Arrow(x, y, width / 2, height / 2, 0);
        var arrow2 = new _complex.complex.Arrow(x + width / 2, y, width / 2, height / 2, 180);
        var nonagon = new _simples.simples.Polygon(x, y + height / 2, width / 2, height / 2, 0, 9);
        _this.addShape(arrow1);
        _this.addShape(arrow2);
        _this.addShape(nonagon);
        return _this;
    }

    _createClass(DoubleArrow, [{
        key: 'draw',
        value: function draw(ctx) {
            this.rotate(1, this.center);
            _get(DoubleArrow.prototype.__proto__ || Object.getPrototypeOf(DoubleArrow.prototype), 'draw', this).call(this, ctx);
            ctx.beginPath();
            this.shape.forEach(function (shape) {
                shape.draw(ctx);
            });
            ctx.fill();
            if (this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }]);

    return DoubleArrow;
}(_complexShape.ComplexShape);

exports.DoubleArrow = DoubleArrow;

},{"../complex/complex.js":36,"../complex/complexShape.js":37,"../simples/simples.js":69}],13:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Erlenmeyer = undefined;

var _simples = require('../simples/simples.js');

var _containerComposite = require('../complex/containers/containerComposite.js');

var _hose = require('../complex/hose.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Erlenmeyer = function (_ContainerComposite) {
    _inherits(Erlenmeyer, _ContainerComposite);

    function Erlenmeyer(x, y, width, height) {
        _classCallCheck(this, Erlenmeyer);

        var _this = _possibleConstructorReturn(this, (Erlenmeyer.__proto__ || Object.getPrototypeOf(Erlenmeyer)).call(this, x, y, width, height));

        _this.type = "Erlenmeyer";

        var lip = new _simples.simples.Rectangle(_this.x + _this.width / 4, _this.y, _this.width / 2, _this.height / 20);

        var neckWidth = _this.width / 2.5;

        var neck = new _simples.simples.Rectangle(trees.getCenterX(neckWidth, _this), _this.y + lip.height, neckWidth, _this.height / 4);
        var bottom = new _simples.simples.Rectangle(_this.x, _this.y + _this.height * 0.75, _this.width, _this.height / 4);
        var body = new _simples.simples.Trapezoid(_this.x, _this.y + lip.height + neck.height, _this.width, _this.height - lip.height - neck.height - bottom.height, trees.getAngle(neck.d, bottom.a), trees.getAngle(neck.d, bottom.a));

        lip.openingIndex = 0;
        _this.addShape(lip);

        _this.addShape(neck);
        _this.addShape(body);
        bottom.bottomIndex = 2;
        _this.addShape(bottom);
        return _this;
    }

    return Erlenmeyer;
}(_containerComposite.ContainerComposite);

exports.Erlenmeyer = Erlenmeyer;

},{"../complex/containers/containerComposite.js":39,"../complex/hose.js":46,"../simples/simples.js":69}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FancyBalloon = undefined;

var _simples = require("../simples/simples.js");

var _patterns = require("../patterns/patterns.js");

var _hotAirBalloon = require("./hotAirBalloon.js");

var _stripedBalloon = require("./stripedBalloon.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Randomly generates fancy patterns.
 */
var FancyBalloon = function (_StripedBalloon) {
  _inherits(FancyBalloon, _StripedBalloon);

  function FancyBalloon(x, y, width, height) {
    _classCallCheck(this, FancyBalloon);

    var _this = _possibleConstructorReturn(this, (FancyBalloon.__proto__ || Object.getPrototypeOf(FancyBalloon)).call(this, x, y, width, height));

    _this.type = "Balloon";
    _patterns.patterns.argyle(_this.balloon).forEach(function (stripe) {
      return _this.addShape(stripe);
    });
    return _this;
  }

  return FancyBalloon;
}(_stripedBalloon.StripedBalloon);

exports.FancyBalloon = FancyBalloon;

},{"../patterns/patterns.js":58,"../simples/simples.js":69,"./hotAirBalloon.js":16,"./stripedBalloon.js":23}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GleamingCrystal = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require('../simples/simples.js');

var _patterns = require('../patterns/patterns.js');

var _crystal = require('./crystal.js');

var _shapesregistry = require('../shapesregistry.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var shapesRegistry = new _shapesregistry.ShapesRegistry();

var GleamingCrystal = function (_Crystal) {
    _inherits(GleamingCrystal, _Crystal);

    function GleamingCrystal(x, y, width, height) {
        _classCallCheck(this, GleamingCrystal);

        var _this = _possibleConstructorReturn(this, (GleamingCrystal.__proto__ || Object.getPrototypeOf(GleamingCrystal)).call(this, x, y, width, height));

        _this.type = "GleamingCrystal";
        return _this;
    }

    _createClass(GleamingCrystal, [{
        key: 'addStripes',
        value: function addStripes() {
            var _this2 = this;

            if (this.stripeWidth && this.stripeSpacing && this.stripeColor && this.stripeOrientation) {
                this.stripes = _patterns.patterns.stripes(this.triangle5, this.stripeWidth, this.stripeSpacing, this.stripeColor, this.stripeOrientation);

                this.stripes.forEach(function (stripe) {
                    var counter = 0;
                    stripe.animate = function () {
                        if (counter < this.width.percent(200)) {
                            var next = trees.getPointOnLine(this.a, counter++, trees.getAngle(this.a, this.b));
                            stripe.x = next.x;
                            stripe.y = next.y;
                        } else {
                            counter = 0;
                        }
                    }.bind(_this2);
                    shapesRegistry.addToDynamicBackground(stripe);
                });
            }
        }
    }, {
        key: 'rotate',
        value: function rotate(deg, transformOrigin) {
            _get(GleamingCrystal.prototype.__proto__ || Object.getPrototypeOf(GleamingCrystal.prototype), 'rotate', this).call(this, deg, transformOrigin);
            this.stripes.forEach(function (stripe) {
                stripe.rotate(deg, transformOrigin);
            });
        }
    }, {
        key: 'stripeColor',
        get: function get() {
            return this._stripeColor;
        },
        set: function set(stripeColor) {
            this._stripeColor = stripeColor;
            this.addStripes();
        }
    }, {
        key: 'stripeWidth',
        get: function get() {
            return this._stripeWidth;
        },
        set: function set(stripeWidth) {
            this._stripeWidth = stripeWidth;
            this.addStripes();
        }
    }, {
        key: 'stripeSpacing',
        get: function get() {
            return this._stripeSpacing;
        },
        set: function set(stripeSpacing) {
            this._stripeSpacing = stripeSpacing;
            this.addStripes();
        }
    }, {
        key: 'stripeOrientation',
        get: function get() {
            return this._stripeOrientation;
        },
        set: function set(stripeOrientation) {
            this._stripeOrientation = stripeOrientation;
            this.addStripes();
        }
    }]);

    return GleamingCrystal;
}(_crystal.Crystal);

exports.GleamingCrystal = GleamingCrystal;

},{"../patterns/patterns.js":58,"../shapesregistry.js":64,"../simples/simples.js":69,"./crystal.js":9}],16:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.HotAirBalloon = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require('../simples/simples.js');

var _complex = require('../complex/complex.js');

var _complexShape = require('../complex/complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var HotAirBalloon = function (_ComplexShape) {
    _inherits(HotAirBalloon, _ComplexShape);

    function HotAirBalloon(x, y, width, height) {
        _classCallCheck(this, HotAirBalloon);

        var _this = _possibleConstructorReturn(this, (HotAirBalloon.__proto__ || Object.getPrototypeOf(HotAirBalloon)).call(this, x, y, width, height));

        _this.type = "HotAirBalloon";

        _this.balloon = new _complex.complex.Balloon(x, y, width, height);
        _this.basket = new _simples.simples.Rectangle(trees.getCenterX(width.percent(15), _this.balloon), y + height.percent(140), width.percent(15), height.percent(15));
        _this.leftString = new _simples.simples.Rectangle(trees.getCenterX(width.percent(15), _this.balloon) + width.percent(1), y + height.percent(125), width.percent(1), height.percent(15));
        _this.rightString = new _simples.simples.Rectangle(trees.getCenterX(width.percent(15), _this.balloon) + width.percent(13), y + height.percent(125), width.percent(1), height.percent(15));

        _this.addShape(_this.balloon);
        _this.addShape(_this.leftString);
        _this.addShape(_this.basket);
        _this.addShape(_this.rightString);

        return _this;
    }

    _createClass(HotAirBalloon, [{
        key: 'color',
        get: function get() {
            return _get(HotAirBalloon.prototype.__proto__ || Object.getPrototypeOf(HotAirBalloon.prototype), 'color', this);
        },
        set: function set(color) {
            _set(HotAirBalloon.prototype.__proto__ || Object.getPrototypeOf(HotAirBalloon.prototype), 'color', color, this);
            this.leftString.color = "black";
            this.rightString.color = "black";
            this.basket.color = "white";
        }
    }]);

    return HotAirBalloon;
}(_complexShape.ComplexShape);

exports.HotAirBalloon = HotAirBalloon;

},{"../complex/complex.js":36,"../complex/complexShape.js":37,"../simples/simples.js":69}],17:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Lake = undefined;

var _simples = require("../simples/simples.js");

var _client = require("./client.js");

var _complexShape = require("../complex/complexShape.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Lake = function (_ComplexShape) {
  _inherits(Lake, _ComplexShape);

  function Lake(x, y, width, height) {
    _classCallCheck(this, Lake);

    var _this = _possibleConstructorReturn(this, (Lake.__proto__ || Object.getPrototypeOf(Lake)).call(this, x, y, width, height));

    _this.type = "Lake";

    _this.wedge = new _simples.simples.Wedge(x + width.percent(50), y, width.percent(20), width.percent(20));
    _this.wedge.rotate(-90, _this.wedge.b);
    // this.addShape(this.wedge);

    _this.shoreLine1 = new _client.client.ShoreLine(x, y + height - width.percent(30), width.percent(70), width.percent(15));
    // this.addShape(this.shoreLine1);

    _this.shoreLine2 = new _client.client.ShoreLine(x + width.percent(15), y + height - width.percent(15), width.percent(40), width.percent(15));
    // this.addShape(this.shoreLine2);

    _this.rectangle1 = new _simples.simples.Rectangle(x + width.percent(70), y + height - width.percent(30), width.percent(30), width.percent(15));
    _this.rectangle2 = new _simples.simples.Rectangle(x + width.percent(90), y, width.percent(10), width.percent(20));
    // this.addShape(this.rectangle1);
    // this.addShape(this.rectangle2);

    _this.rectangle3 = new _simples.simples.Rectangle(x, y + height - width.percent(15), width.percent(15), width.percent(15));
    _this.rectangle4 = new _simples.simples.Rectangle(x + width.percent(55), y + height - width.percent(15), width.percent(45), width.percent(15));
    // this.addShape(this.rectangle3);
    // this.addShape(this.rectangle4);
    return _this;
  }

  return Lake;
}(_complexShape.ComplexShape);

exports.Lake = Lake;

},{"../complex/complexShape.js":37,"../simples/simples.js":69,"./client.js":7}],18:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Mountains = undefined;

var _simples = require("../simples/simples.js");

var _complex = require("../complex/complex.js");

var _complexShape = require("../complex/complexShape.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

//basically same rules as clouds.
var Mountains = function (_ComplexShape) {
  _inherits(Mountains, _ComplexShape);

  function Mountains(x, y, width, height, angle) {
    _classCallCheck(this, Mountains);

    var _this = _possibleConstructorReturn(this, (Mountains.__proto__ || Object.getPrototypeOf(Mountains)).call(this, x, y, width, height, angle));

    _this.type = "Mountains";
    var black = "#333333";
    var maxSize = height;
    var previoustriangle = void 0;
    var triangleX = void 0;
    var moretriangles = true;
    var snowcaps = [];

    while (moretriangles) {
      //the x of the triangle should overlap the right edge of the previous triangle by 25%
      var overlap = Math.floor(Math.random() * 3) + 1;
      triangleX = previoustriangle ? previoustriangle.b.x - previoustriangle.width * 0.3 : _this.x;

      //the max size of any triangle should be 1/5 of the width. Min size is 1/10 of that.
      var size = Math.floor(Math.random() * (maxSize - maxSize / 10)) + maxSize / 10;

      //create the triangle.
      var triangle = new _simples.simples.Triangle(triangleX, _this.d.y - size, size, size);
      triangle.color = black;
      if (size > maxSize * 0.8) {
        var snowcap = new _simples.simples.Triangle(triangleX + size / 2 - size / 5 / 2, _this.d.y - size, size / 5, size / 5);
        snowcap.color = "beige";
        snowcaps.push(snowcap);
      }
      _this.addShape(triangle);
      previoustriangle = triangle;

      //if the triangle's right edge exceeds the width of the Mountains, move it over.
      //otherwise add another triangle.
      if (triangle.b.x > _this.b.x) {
        triangle.x = _this.b.x - triangle.width;
        moretriangles = false;
      }
    }
    snowcaps.forEach(function (snowcap) {
      return _this.addShape(snowcap);
    });
    return _this;
  }

  return Mountains;
}(_complexShape.ComplexShape);

exports.Mountains = Mountains;

},{"../complex/complex.js":36,"../complex/complexShape.js":37,"../simples/simples.js":69}],19:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Mushroom = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require('../simples/simples.js');

var _complex = require('../complex/complex.js');

var _complexShape = require('../complex/complexShape.js');

var _patterns = require('../patterns/patterns.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Mushroom = function (_ComplexShape) {
    _inherits(Mushroom, _ComplexShape);

    function Mushroom(x, y, width, height) {
        _classCallCheck(this, Mushroom);

        var _this = _possibleConstructorReturn(this, (Mushroom.__proto__ || Object.getPrototypeOf(Mushroom)).call(this, x, y, width, height));

        _this.type = "Mushroom";

        var stem = new _simples.simples.Rectangle(_this.center.x - _this.width / 10, y + height / 4, _this.width / 5, _this.height * 0.75);
        stem.color = "#F8A068";
        var cap = new _complex.complex.SemiCircle(x, y, width, width / 2);
        var circle = new _simples.simples.Circle(x, y, width, width);
        cap.color = "#AF001E";
        var rectangle = new _simples.simples.Rectangle(x, y + width / 2, width, height / 8);
        rectangle.color = "#AF001E";
        _this.addShape(stem);
        _this.addShape(cap);

        for (var i = 0; i < 5; i++) {
            var v_width = width / 5;
            var v_height = v_width / 2;
            var veil = new _complex.complex.SemiCircle(x + i * v_width, rectangle.d.y, v_width, v_height);
            veil.rotate(180, veil.center);
            veil.color = "#FFFCC4";
            _this.addShape(veil);
        }
        _this.addShape(rectangle);
        _patterns.patterns.randomSpotsOnCircle(circle).forEach(function (spot) {
            //make sure it is above the semicircle.
            if (spot.y + spot.height < rectangle.d.y) {
                spot.color = "#F8A068";
                spot.collidable = false;
                _this.addShape(spot);
            }
        });
        return _this;
    }

    _createClass(Mushroom, [{
        key: 'draw',
        value: function draw(ctx) {
            _get(Mushroom.prototype.__proto__ || Object.getPrototypeOf(Mushroom.prototype), 'draw', this).call(this, ctx);

            ctx.beginPath();

            this.shape.forEach(function (shape) {
                shape.draw(ctx);
            });

            ctx.fill();
            if (this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }, {
        key: 'color',
        get: function get() {
            return this._color;
        },
        set: function set(color) {
            this._color = color;
        }
    }]);

    return Mushroom;
}(_complexShape.ComplexShape);

exports.Mushroom = Mushroom;

},{"../complex/complex.js":36,"../complex/complexShape.js":37,"../patterns/patterns.js":58,"../simples/simples.js":69}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Octopus = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _simples = require('../simples/simples.js');

var _complex = require('../complex/complex.js');

var _complexShape = require('../complex/complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var a = 0;
var i = 1;

var Octopus = function (_ComplexShape) {
    _inherits(Octopus, _ComplexShape);

    function Octopus(x, y, width, height) {
        _classCallCheck(this, Octopus);

        var _this = _possibleConstructorReturn(this, (Octopus.__proto__ || Object.getPrototypeOf(Octopus)).call(this, x, y, width, height));

        _this.type = "Octopus";

        var main = new _simples.simples.Circle(_this.center.x - width / 8, y, width / 4, height / 4);
        var rect = new _simples.simples.Rectangle(main.x, main.center.y, width / 4, height / 8);
        _this.addShape(main);
        _this.addShape(rect);
        var direction = 1;
        for (var _i = 0; _i < 8; _i++) {
            var tentacle = new _complex.complex.Hose(rect.d.x + _i * rect.width / 8, rect.d.y - rect.width / 8, _this.width * 0.75, rect.width / 8);
            tentacle.direction = direction;
            tentacle.type = "Tentacle";
            _this.addShape(tentacle);
            tentacle.rotate(90, tentacle.d);
            direction = -direction;
        }

        return _this;
    }

    _createClass(Octopus, [{
        key: 'animate',
        value: function animate() {

            this.shape.forEach(function (shape) {
                if (shape.type === "Tentacle") {
                    if (a > 30) {
                        i++;
                        a = 0;
                    }
                    var length = Math.floor(Math.random() * shape.length);
                    shape.selectSection(shape.shape[i]);
                    shape.sectionLength = 5;
                    shape.bend(shape.direction);
                    shape.selectSection(shape.shape[0]);
                    shape.sectionLength = i;
                    shape.bend(-shape.direction);
                    a++;
                }
            });
        }
    }]);

    return Octopus;
}(_complexShape.ComplexShape);

exports.Octopus = Octopus;

},{"../complex/complex.js":36,"../complex/complexShape.js":37,"../simples/simples.js":69}],21:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ShoreLine = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _simples = require('../simples/simples.js');

var _complex = require('../complex/complex.js');

var _complexShape = require('../complex/complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ShoreLine = function (_ComplexShape) {
    _inherits(ShoreLine, _ComplexShape);

    function ShoreLine(x, y, width, height) {
        _classCallCheck(this, ShoreLine);

        var _this = _possibleConstructorReturn(this, (ShoreLine.__proto__ || Object.getPrototypeOf(ShoreLine)).call(this, x, y, width, height));

        _this.type = "ShoreLine";

        _this.water = new _simples.simples.Rectangle(x, y, width, height);
        _this.water.pathOnly = true;
        _this.addShape(_this.water);

        _this.leftEdge = new _complex.complex.YinYang(x, y, height, height);
        _this.addShape(_this.leftEdge);

        _this.rightEdge = new _complex.complex.YangYin(x + width - height, y, height, height);
        _this.addShape(_this.rightEdge);

        return _this;
    }

    _createClass(ShoreLine, [{
        key: 'draw',
        value: function draw(ctx) {
            ctx.save();
            ctx.beginPath();
            this.water.draw(ctx);
            ctx.clip();
            this.leftEdge.draw(ctx);
            this.rightEdge.draw(ctx);
            ctx.closePath();
            ctx.fill();
            ctx.restore();
        }
    }, {
        key: 'waterColor',
        get: function get() {
            return this.water.color;
        },
        set: function set(waterColor) {
            this.water.color = waterColor;
        }
    }]);

    return ShoreLine;
}(_complexShape.ComplexShape);

exports.ShoreLine = ShoreLine;

},{"../complex/complex.js":36,"../complex/complexShape.js":37,"../simples/simples.js":69}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Spray = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require('../simples/simples.js');

var _complexShape = require('../complex/complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Spray = function (_ComplexShape) {
    _inherits(Spray, _ComplexShape);

    function Spray(x, y, width, height, angle, thickness) {
        _classCallCheck(this, Spray);

        var _this = _possibleConstructorReturn(this, (Spray.__proto__ || Object.getPrototypeOf(Spray)).call(this, x, y, width, height, angle, thickness));

        _this.type = "Spray";

        for (var i = 0; i < thickness; i++) {
            _this.createParticle();
        }

        return _this;
    }

    _createClass(Spray, [{
        key: 'spray',
        value: function spray() {
            var _this2 = this;

            this.shape.forEach(function (shape) {
                shape.x += shape.xspeed;
                shape.y += shape.yspeed;
                if (shape.width > 0) {
                    shape.width -= 0.1;
                    shape.height -= 0.1;
                }
                if (shape.width <= 0) {
                    _this2.removeShape(shape);
                    _this2.createParticle();
                }
            });
        }
    }, {
        key: 'createParticle',
        value: function createParticle() {
            var size = trees.random(1, 5);
            var particle = new _simples.simples.Circle(this.a.x, this.a.y, size, size);
            particle.xspeed = trees.random(2, 4);
            particle.yspeed = trees.random(3, 5);
            particle.color = this.color;
            this.addShape(particle);
        }
    }, {
        key: 'draw',
        value: function draw(ctx) {
            _get(Spray.prototype.__proto__ || Object.getPrototypeOf(Spray.prototype), 'draw', this).call(this, ctx);

            ctx.beginPath();

            this.shape.forEach(function (shape) {
                shape.draw(ctx);
            });

            ctx.closePath();
        }
    }]);

    return Spray;
}(_complexShape.ComplexShape);

exports.Spray = Spray;

},{"../complex/complexShape.js":37,"../simples/simples.js":69}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.StripedBalloon = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _simples = require('../simples/simples.js');

var _patterns = require('../patterns/patterns.js');

var _hotAirBalloon = require('./hotAirBalloon.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var StripedBalloon = function (_HotAirBalloon) {
    _inherits(StripedBalloon, _HotAirBalloon);

    function StripedBalloon(x, y, width, height) {
        _classCallCheck(this, StripedBalloon);

        var _this = _possibleConstructorReturn(this, (StripedBalloon.__proto__ || Object.getPrototypeOf(StripedBalloon)).call(this, x, y, width, height));

        _this.type = "Balloon";
        return _this;
    }

    _createClass(StripedBalloon, [{
        key: 'addStripes',
        value: function addStripes() {
            var _this2 = this;

            if (this.stripeWidth && this.stripeSpacing && this.stripeColor && this.stripeOrientation) {
                this.stripes = _patterns.patterns.stripes(this.balloon, this.stripeWidth, this.stripeSpacing, this.stripeColor, this.stripeOrientation);

                this.stripes.forEach(function (stripe) {
                    _this2.addShape(stripe);
                });
            }
        }
    }, {
        key: 'stripeColor',
        get: function get() {
            return this._stripeColor;
        },
        set: function set(stripeColor) {
            this._stripeColor = stripeColor;
            this.addStripes();
        }
    }, {
        key: 'stripeWidth',
        get: function get() {
            return this._stripeWidth;
        },
        set: function set(stripeWidth) {
            this._stripeWidth = stripeWidth;
            this.addStripes();
        }
    }, {
        key: 'stripeSpacing',
        get: function get() {
            return this._stripeSpacing;
        },
        set: function set(stripeSpacing) {
            this._stripeSpacing = stripeSpacing;
            this.addStripes();
        }
    }, {
        key: 'stripeOrientation',
        get: function get() {
            return this._stripeOrientation;
        },
        set: function set(stripeOrientation) {
            this._stripeOrientation = stripeOrientation;
            this.addStripes();
        }
    }]);

    return StripedBalloon;
}(_hotAirBalloon.HotAirBalloon);

exports.StripedBalloon = StripedBalloon;

},{"../patterns/patterns.js":58,"../simples/simples.js":69,"./hotAirBalloon.js":16}],24:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TestTube = undefined;

var _simples = require('../simples/simples.js');

var _containerComposite = require('../complex/containers/containerComposite.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TestTube = function (_ContainerComposite) {
    _inherits(TestTube, _ContainerComposite);

    function TestTube(x, y, width, height) {
        _classCallCheck(this, TestTube);

        var _this = _possibleConstructorReturn(this, (TestTube.__proto__ || Object.getPrototypeOf(TestTube)).call(this, x, y, width, height));

        _this.type = "TestTube";

        var lip = new _simples.simples.Rectangle(_this.x + _this.width / 4, _this.y, _this.width / 2, _this.height / 20);
        lip.openingIndex = 0;
        _this.addShape(lip);
        _this.lip = lip;
        var bodyWidth = _this.width / 2.5;
        var body = new _simples.simples.Rectangle(trees.getCenterX(bodyWidth, _this), _this.y + lip.height, bodyWidth, _this.height - _this.height / 20);
        body.bottomIndex = 2;
        _this.addShape(body);
        return _this;
    }

    return TestTube;
}(_containerComposite.ContainerComposite);

exports.TestTube = TestTube;

},{"../complex/containers/containerComposite.js":39,"../simples/simples.js":69}],25:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tree = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require("../simples/simples.js");

var _complexShape = require("../complex/complexShape.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Tree = function (_ComplexShape) {
  _inherits(Tree, _ComplexShape);

  function Tree(x, y, width, height, angle) {
    _classCallCheck(this, Tree);

    var _this = _possibleConstructorReturn(this, (Tree.__proto__ || Object.getPrototypeOf(Tree)).call(this, x, y, width, height, angle));

    _this.type = "Tree";

    var rectangle = new _simples.simples.Rectangle(_this.center.x - _this.width / 8, y + height / 4, _this.width / 4, _this.height * 0.75, 0);
    var triangle1 = new _simples.simples.Triangle(x, y + height / 2, width, height / 4, 0);
    var triangle2 = new _simples.simples.Triangle(x, y + height / 4, width, height / 4, 0);
    var triangle3 = new _simples.simples.Triangle(x, y, width, height / 4, 0);

    _this.addShape(triangle1);
    _this.addShape(triangle2);
    _this.addShape(triangle3);
    _this.addShape(rectangle);
    return _this;
  }

  _createClass(Tree, [{
    key: "draw",
    value: function draw(ctx) {
      ctx = ctx || this.canvas && this.canvas.ctx;
      _get(Tree.prototype.__proto__ || Object.getPrototypeOf(Tree.prototype), "draw", this).call(this, ctx);

      ctx.beginPath();

      this.shape.forEach(function (shape) {
        shape.draw(ctx);
      });

      ctx.fill();
      if (this.lineColor) ctx.stroke();
      ctx.closePath();
    }
  }]);

  return Tree;
}(_complexShape.ComplexShape);

exports.Tree = Tree;

},{"../complex/complexShape.js":37,"../simples/simples.js":69}],26:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WaterWheel = undefined;

var _simples = require("../simples/simples.js");

var _complex = require("../complex/complex.js");

var _client = require("./client.js");

var _complexShape = require("../complex/complexShape.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var WaterWheel = function (_ComplexShape) {
  _inherits(WaterWheel, _ComplexShape);

  function WaterWheel(x, y, width, height, angle) {
    _classCallCheck(this, WaterWheel);

    var _this = _possibleConstructorReturn(this, (WaterWheel.__proto__ || Object.getPrototypeOf(WaterWheel)).call(this, x, y, width, height, angle));

    _this.type = "WaterWheel";

    var thickness = width.percent(3);

    var hub = new _complex.complex.Circle(x + width.percent(37.5), y + width.percent(37.5), width.percent(25), height.percent(25));
    hub.color = trees.setOpacity("white", 1);
    var rim = new _complex.complex.Donut(x, y, width, height, thickness);
    rim.color = trees.setOpacity("white", 1);
    _this.addShape(rim);

    var s_angle = 0;

    var color = ["red", "green", "blue", "yellow", "black", "orange", "purple", "white"];

    for (var i = 0; i < 8; i++) {
      var spoke = new _simples.simples.Rectangle(_this.center.x - thickness / 2, _this.center.y, thickness, height / 2, 0);
      _this.addShape(spoke);
      spoke.color = trees.setOpacity(color[i], 0.5);
      spoke.rotate(s_angle, _this.center);
      s_angle += 45;
    }
    _this.addShape(hub);

    for (var i = 0; i < 8; i++) {
      var container = new _client.client.Cup(x + width.percent(10), y + height.percent(1), width.percent(16), width.percent(16), 70);
      container.thickness = width.percent(1);
      container.color = trees.setOpacity(color[i], 0.5);
      _this.addShape(container);
      container.rotate(s_angle, _this.center);
      container.rotate(-s_angle, container.center);
      container.rotate(s_angle + 45, container.center);
      container.level = 50;
      s_angle += 45;
    }
    return _this;
  }

  return WaterWheel;
}(_complexShape.ComplexShape);

exports.WaterWheel = WaterWheel;

},{"../complex/complex.js":36,"../complex/complexShape.js":37,"../simples/simples.js":69,"./client.js":7}],27:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Wheel = undefined;

var _simples = require('../simples/simples.js');

var _complex = require('../complex/complex.js');

var _complexShape = require('../complex/complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Wheel = function (_ComplexShape) {
    _inherits(Wheel, _ComplexShape);

    function Wheel(x, y, width, height, angle) {
        _classCallCheck(this, Wheel);

        var _this = _possibleConstructorReturn(this, (Wheel.__proto__ || Object.getPrototypeOf(Wheel)).call(this, x, y, width, height, angle));

        _this.type = "Wheel";

        var thickness = width / 30;

        var hub = new _complex.complex.Circle(x + width / 2 - width / 8, y + width / 2 - width / 8, width / 4, height / 4, 0);
        var rim = new _complex.complex.Donut(x, y, width, height, 0, thickness);

        _this.addShape(hub);
        _this.addShape(rim);

        var s_angle = 0;

        for (var i = 0; i < 8; i++) {
            var spoke = new _simples.simples.Rectangle(_this.center.x - thickness / 2, _this.center.y, thickness, _this.height / 2, 0);
            _this.addShape(spoke);
            spoke.rotate(s_angle, _this.center);
            s_angle += 45;
        }

        return _this;
    }

    return Wheel;
}(_complexShape.ComplexShape);

exports.Wheel = Wheel;

},{"../complex/complex.js":36,"../complex/complexShape.js":37,"../simples/simples.js":69}],28:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Balloon = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _simples = require('../simples/simples.js');

var _complexShape = require('./complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Balloon = function (_ComplexShape) {
    _inherits(Balloon, _ComplexShape);

    function Balloon(x, y, width, height) {
        _classCallCheck(this, Balloon);

        var _this = _possibleConstructorReturn(this, (Balloon.__proto__ || Object.getPrototypeOf(Balloon)).call(this, x, y, width, height));

        _this.type = "Balloon";

        _this.circle = new _simples.simples.Circle(x, y, width, height);
        _this.circle.startAngle = 0.835;
        _this.circle.endAngle = 0.165;
        _this.trapezoid = new _simples.simples.Trapezoid(trees.getCenterX(width.percent(86), _this.circle), y + height.percent(75), width.percent(86), height.percent(50), 55);
        _this.height = _this.circle.height + _this.trapezoid.height;
        _this.circle.pathOnly = true;
        _this.trapezoid.pathOnly = true;

        _this.addShape(_this.circle);
        _this.addShape(_this.trapezoid);
        return _this;
    }

    _createClass(Balloon, [{
        key: 'draw',
        value: function draw(ctx) {
            ctx.beginPath();
            ctx.arc(this.circle.center.x, this.circle.center.y, this.circle.radius, this.circle.startAngle, this.circle.endAngle);
            ctx.yLine(this.trapezoid.bottomRight);
            ctx.yLine(this.trapezoid.bottomLeft);
            ctx.yLine(this.trapezoid.topLeft);
            ctx.fillStyle = this.color;
            ctx.strokeStyle = this.lineColor;
            if (!this.pathOnly) ctx.fill();
            if (!this.pathOnly && this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }]);

    return Balloon;
}(_complexShape.ComplexShape);

exports.Balloon = Balloon;

},{"../simples/simples.js":69,"./complexShape.js":37}],29:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Border = undefined;

var _sprite = require("../../sprite.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Border = function (_Sprite) {
    _inherits(Border, _Sprite);

    function Border(container, thickness, edgeLine, location) {
        _classCallCheck(this, Border);

        if (!container.addShape) {
            throw new Error("Supplied container cannot receive a border. Most likely cause is that it is not a subclass of complexShape.");
        }
        var startingPoint = trees.copyPoint(edgeLine.start);
        var length = trees.getDistance(edgeLine.start, edgeLine.end);

        var _this = _possibleConstructorReturn(this, (Border.__proto__ || Object.getPrototypeOf(Border)).call(this, startingPoint.x, startingPoint.y, length, thickness));

        _this.type = "Border";

        return _this;
    }

    return Border;
}(_sprite.Sprite);

exports.Border = Border;

},{"../../sprite.js":74}],30:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.RockyBorder = undefined;

var _simples = require('../../simples/simples.js');

var _border = require('./border.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RockyBorder = function (_Border) {
    _inherits(RockyBorder, _Border);

    function RockyBorder(container, thickness, edgeLine, location) {
        _classCallCheck(this, RockyBorder);

        var _this = _possibleConstructorReturn(this, (RockyBorder.__proto__ || Object.getPrototypeOf(RockyBorder)).call(this, container, thickness, edgeLine, location));

        _this.type = "RockyBorder";
        var numberOfRocks = Math.floor(_this.width / thickness * 2);
        var current = trees.copyPoint(edgeLine.start);

        var angle = trees.getAngle(edgeLine.start, edgeLine.end);

        for (var i = 0; i < numberOfRocks; i++) {
            var rock = new _simples.simples.Polygon(current.x, current.y, thickness, thickness, trees.random(3, 6));

            //keeps rock aligned to middle of line.
            rock.rotate(angle - 45, rock.a);

            rock.rotate(trees.random(0, 180), rock.center);
            rock.color = container.color;
            container.addShape(rock);
            current = trees.getPointOnLine(current, thickness.percent(50), angle);
        }

        return _this;
    }

    return RockyBorder;
}(_border.Border);

exports.RockyBorder = RockyBorder;

},{"../../simples/simples.js":69,"./border.js":29}],31:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ScallopedBorder = undefined;

var _simples = require('../../simples/simples.js');

var _border = require('./border.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ScallopedBorder = function (_Border) {
    _inherits(ScallopedBorder, _Border);

    function ScallopedBorder(container, thickness, edgeLine, location) {
        _classCallCheck(this, ScallopedBorder);

        var _this = _possibleConstructorReturn(this, (ScallopedBorder.__proto__ || Object.getPrototypeOf(ScallopedBorder)).call(this, container, thickness, edgeLine, location));

        _this.type = "ScallopedBorder";
        var number = Math.floor(_this.width / thickness);
        var current = trees.copyPoint(edgeLine.start);

        var angle = trees.getAngle(edgeLine.start, edgeLine.end);

        for (var i = 0; i < number; i++) {
            var rock = new _simples.simples.Circle(current.x, current.y, thickness, thickness);

            //keeps rock aligned to middle of line.
            rock.rotate(angle - 45, rock.a);
            rock.color = container.color;
            rock.startAngle = 0;
            rock.endAngle = 1;
            container.addShape(rock);
            current = trees.getPointOnLine(current, thickness, angle);
        }

        return _this;
    }

    return ScallopedBorder;
}(_border.Border);

exports.ScallopedBorder = ScallopedBorder;

},{"../../simples/simples.js":69,"./border.js":29}],32:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Box = undefined;

var _simples = require('../simples/simples.js');

var _complexShape = require('./complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Box = function (_ComplexShape) {
    _inherits(Box, _ComplexShape);

    function Box(x, y, width, height) {
        _classCallCheck(this, Box);

        var _this = _possibleConstructorReturn(this, (Box.__proto__ || Object.getPrototypeOf(Box)).call(this, x, y, width, height));

        _this.type = "Box";
        var rectangle = new _simples.simples.Rectangle(x, y, width, height);
        _this.addShape(rectangle);
        return _this;
    }

    return Box;
}(_complexShape.ComplexShape);

exports.Box = Box;

},{"../simples/simples.js":69,"./complexShape.js":37}],33:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Circle = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require('../../simples/simples.js');

var _circularShape = require('./circularShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Circle = function (_CircularShape) {
    _inherits(Circle, _CircularShape);

    function Circle(x, y, width, height) {
        _classCallCheck(this, Circle);

        var _this = _possibleConstructorReturn(this, (Circle.__proto__ || Object.getPrototypeOf(Circle)).call(this, x, y, width, height));

        _this.type = "Circle";

        _this.topRight = new _simples.simples.Wedge(x + width / 2, y, width / 2, height / 2);
        _this.topLeft = new _simples.simples.Wedge(x, y, width / 2, height / 2, 270);
        _this.topLeft.rotate(270, _this.topLeft.center);
        _this.bottomRight = new _simples.simples.Wedge(x + width / 2, y + height / 2, width / 2, height / 2, 90);
        _this.bottomRight.rotate(90, _this.bottomRight.center);
        _this.bottomLeft = new _simples.simples.Wedge(x, y + height / 2, width / 2, height / 2, 180);
        _this.bottomLeft.rotate(180, _this.bottomLeft.center);

        _this.addShape(_this.topRight);
        _this.addShape(_this.topLeft);
        _this.addShape(_this.bottomRight);
        _this.addShape(_this.bottomLeft);

        return _this;
    }

    _createClass(Circle, [{
        key: 'rotate',
        value: function rotate() {}
    }, {
        key: 'draw',
        value: function draw(ctx) {
            _get(Circle.prototype.__proto__ || Object.getPrototypeOf(Circle.prototype), 'draw', this).call(this, ctx);

            ctx.beginPath();

            this.shape.forEach(function (shape) {
                shape.draw(ctx);
            });

            ctx.closePath();
        }
    }]);

    return Circle;
}(_circularShape.CircularShape);

exports.Circle = Circle;

},{"../../simples/simples.js":69,"./circularShape.js":34}],34:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.CircularShape = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require('../../simples/simples.js');

var _complexShape = require('../complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CircularShape = function (_ComplexShape) {
    _inherits(CircularShape, _ComplexShape);

    function CircularShape(x, y, width, height, thickness) {
        _classCallCheck(this, CircularShape);

        var _this = _possibleConstructorReturn(this, (CircularShape.__proto__ || Object.getPrototypeOf(CircularShape)).call(this, x, y, width, height));

        _this.type = "CircularShape";
        _this._radius = _this.width / 2;
        return _this;
    }

    _createClass(CircularShape, [{
        key: 'createSATObject',
        value: function createSATObject() {
            return [new SAT.Circle(new SAT.Vector(this.center.x, this.center.y), this.radius)];
        }
    }, {
        key: 'radius',
        get: function get() {
            return this._radius;
        },
        set: function set(radius) {
            this._radius = radius;
        }
    }, {
        key: 'width',
        get: function get() {
            return _get(CircularShape.prototype.__proto__ || Object.getPrototypeOf(CircularShape.prototype), 'width', this);
        },
        set: function set(width) {
            _set(CircularShape.prototype.__proto__ || Object.getPrototypeOf(CircularShape.prototype), 'width', width, this);
            this.radius = width / 2;
        }
    }, {
        key: 'height',
        get: function get() {
            return _get(CircularShape.prototype.__proto__ || Object.getPrototypeOf(CircularShape.prototype), 'height', this);
        },
        set: function set(height) {
            _set(CircularShape.prototype.__proto__ || Object.getPrototypeOf(CircularShape.prototype), 'height', height, this);
            //this.radius = height/2;
        }
    }]);

    return CircularShape;
}(_complexShape.ComplexShape);

exports.CircularShape = CircularShape;

},{"../../simples/simples.js":69,"../complexShape.js":37}],35:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.SemiCircle = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _simples = require('../../simples/simples.js');

var _circularShape = require('./circularShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SemiCircle = function (_CircularShape) {
    _inherits(SemiCircle, _CircularShape);

    function SemiCircle(x, y, width, height, angle) {
        _classCallCheck(this, SemiCircle);

        var _this = _possibleConstructorReturn(this, (SemiCircle.__proto__ || Object.getPrototypeOf(SemiCircle)).call(this, x, y, width, height, angle));

        _this.type = "SemiCircle";

        _this.right = new _simples.simples.Wedge(x + width / 2, y, width / 2, height);
        _this.left = new _simples.simples.Wedge(x, y, width / 2, height, 270);
        _this.left.rotate(270, _this.left.center);
        _this.addShape(_this.left);
        _this.addShape(_this.right);
        return _this;
    }

    _createClass(SemiCircle, [{
        key: 'draw',
        value: function draw(ctx) {
            _get(SemiCircle.prototype.__proto__ || Object.getPrototypeOf(SemiCircle.prototype), 'draw', this).call(this, ctx);

            ctx.beginPath();
            ctx.yMove(this.d);
            ctx.curve(this.left.curve);
            ctx.curve(this.right.curve);
            ctx.yLine(this.d);
            ctx.fill();
            if (this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }]);

    return SemiCircle;
}(_circularShape.CircularShape);

exports.SemiCircle = SemiCircle;

},{"../../simples/simples.js":69,"./circularShape.js":34}],36:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.complex = undefined;

var _semiCircle = require('./circularShapes/semiCircle.js');

var _circle = require('./circularShapes/circle.js');

var _macaroni = require('./thickShapes/macaroni.js');

var _arch = require('./thickShapes/arch.js');

var _donut = require('./thickShapes/donut.js');

var _hose = require('./hose.js');

var _box = require('./box.js');

var _balloon = require('./balloon.js');

var _yinYang = require('./yinYang.js');

var _yangYin = require('./yangYin.js');

var _pourComposite = require('./containers/pourComposite.js');

var _container = require('./containers/container.js');

var _border = require('./borders/border.js');

var _rockyBorder = require('./borders/rockyBorder.js');

var _scallopedBorder = require('./borders/scallopedBorder.js');

var complex = {
    SemiCircle: _semiCircle.SemiCircle,
    Circle: _circle.Circle,
    Macaroni: _macaroni.Macaroni,
    Arch: _arch.Arch,
    Donut: _donut.Donut,
    Hose: _hose.Hose,
    Box: _box.Box,
    Balloon: _balloon.Balloon,
    YinYang: _yinYang.YinYang,
    YangYin: _yangYin.YangYin,
    Container: _container.Container,
    PourComposite: _pourComposite.PourComposite,
    Border: _border.Border,
    RockyBorder: _rockyBorder.RockyBorder,
    ScallopedBorder: _scallopedBorder.ScallopedBorder
};

exports.complex = complex;

},{"./balloon.js":28,"./borders/border.js":29,"./borders/rockyBorder.js":30,"./borders/scallopedBorder.js":31,"./box.js":32,"./circularShapes/circle.js":33,"./circularShapes/semiCircle.js":35,"./containers/container.js":38,"./containers/pourComposite.js":45,"./hose.js":46,"./thickShapes/arch.js":47,"./thickShapes/donut.js":48,"./thickShapes/macaroni.js":49,"./yangYin.js":51,"./yinYang.js":52}],37:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ComplexShape = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _sprite = require("../sprite.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ComplexShape = function (_Sprite) {
    _inherits(ComplexShape, _Sprite);

    function ComplexShape(x, y, width, height) {
        _classCallCheck(this, ComplexShape);

        var _this = _possibleConstructorReturn(this, (ComplexShape.__proto__ || Object.getPrototypeOf(ComplexShape)).call(this, x, y, width, height));

        _this.type = "ComplexShape";
        _this._shape = [];
        return _this;
    }

    _createClass(ComplexShape, [{
        key: "addShape",
        value: function addShape(shape) {
            if (this.shape.indexOf(shape) >= 0) {
                throw new Error("Attempted to add same shape twice.");
            }
            shape.relativeX = (shape.x - this.x) / this.width;
            shape.relativeY = (shape.y - this.y) / this.height;
            shape.relativeWidth = shape.width / this.width;
            shape.relativeHeight = shape.height / this.height;
            this.shape.push(shape);
        }
    }, {
        key: "moveDrawOrderBack",
        value: function moveDrawOrderBack(shape) {
            var shapeToMove = this.removeShape(shape);
            this.shape.unshift(shape);
        }
    }, {
        key: "removeShape",
        value: function removeShape(shape) {
            var index = this.shape.indexOf(shape);
            if (index >= 0) {
                this.shape.splice(index, 1);
            }
        }
    }, {
        key: "animate",
        value: function animate() {
            this.shape && this.shape.forEach(function (shape) {
                if (shape.animate) shape.animate();
            });
        }
    }, {
        key: "rotate",
        value: function rotate(deg, transformOrigin) {
            _get(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "rotate", this).call(this, deg, transformOrigin);
            this.shape && this.shape.forEach(function (shape) {
                shape.rotate(deg, transformOrigin);
            });
        }
    }, {
        key: "createSATObject",


        //merge all SAT objects into a single array.
        value: function createSATObject() {
            var response = [];
            this.shape.forEach(function (shape) {
                response = response.concat(shape.createSATObject());
            });
            return response;
        }
    }, {
        key: "wasClicked",
        value: function wasClicked(mouseX, mouseY) {
            var clicked = null;
            this.shape.forEach(function (shape) {
                clicked = clicked || shape.wasClicked(mouseX, mouseY);
            });
            return clicked;
        }
    }, {
        key: "draw",
        value: function draw(ctx) {
            ctx = ctx || this.canvas && this.canvas.ctx;

            _get(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "draw", this).call(this, ctx);

            ctx.beginPath();

            this.shape.forEach(function (shape) {
                if (shape.visible) shape.draw(ctx);
            });

            ctx.closePath();
        }
    }, {
        key: "shape",
        get: function get() {
            return this._shape;
        },
        set: function set(shape) {
            this._shape = shape;
        }
    }, {
        key: "lastShape",
        get: function get() {
            return this._shape[this._shape.length - 1];
        }
    }, {
        key: "firstShape",
        get: function get() {
            return this._shape[0];
        }
    }, {
        key: "pathOnly",
        get: function get() {
            return _get(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "pathOnly", this);
        },
        set: function set(pathOnly) {
            _set(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "pathOnly", pathOnly, this);
            this.shape.forEach(function (shape) {
                shape.pathOnly = pathOnly;
            });
        }
    }, {
        key: "color",
        get: function get() {
            return _get(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "color", this);
        },
        set: function set(color) {
            _set(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "color", color, this);
            this.shape.forEach(function (shape) {
                shape.color = color;
            });
        }
    }, {
        key: "lineColor",
        get: function get() {
            return _get(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "lineColor", this);
        },
        set: function set(lineColor) {
            _set(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "lineColor", lineColor, this);
            this.shape.forEach(function (shape) {
                shape.lineColor = lineColor;
            });
        }
    }, {
        key: "collidable",
        get: function get() {
            return _get(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "collidable", this);
        },
        set: function set(collidable) {
            if (typeof collidable !== "boolean") {
                throw new Error("Property collidable expects boolean value");
            }
            _set(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "collidable", collidable, this);
            this.shape.forEach(function (shape) {
                shape.collidable = collidable;
            });
        }
    }, {
        key: "x",
        get: function get() {
            return _get(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "x", this);
        },
        set: function set(x) {
            var oldX = this.x;
            var diffX = x - oldX;
            _set(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "x", x, this);
            this.shape.forEach(function (shape) {
                shape.x += diffX;
            });
        }
    }, {
        key: "y",
        get: function get() {
            return _get(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "y", this);
        },
        set: function set(y) {
            var oldY = this.y;
            var diffY = y - oldY;
            _set(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "y", y, this);
            this.shape.forEach(function (shape) {
                shape.y += diffY;
            });
        }
    }, {
        key: "width",
        get: function get() {
            return _get(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "width", this);
        },
        set: function set(width) {
            var oldwidth = this.width;
            var diffwidth = width - oldwidth;
            _set(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "width", width, this);
            this.shape.forEach(function (shape) {
                shape.width = width * shape.relativeWidth;
                shape.x = shape.x + diffwidth * shape.relativeX;
            });
        }
    }, {
        key: "height",
        get: function get() {
            return _get(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "height", this);
        },
        set: function set(height) {
            var oldheight = this.height;
            var diffheight = height - oldheight;
            _set(ComplexShape.prototype.__proto__ || Object.getPrototypeOf(ComplexShape.prototype), "height", height, this);
            this.shape.forEach(function (shape) {
                shape.height = height * shape.relativeHeight;
                shape.y = shape.y + diffheight * shape.relativeY;
            });
        }
    }]);

    return ComplexShape;
}(_sprite.Sprite);

exports.ComplexShape = ComplexShape;

},{"../sprite.js":74}],38:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Container = undefined;

var _liquid = require('./liquid.js');

function Container(shape) {

    shape.type = "Container";

    /**
     * .overflowing
     * returns true if the container has a liquid
     * which is currently intersecting with the container's
     * opening, if it has one.
     */
    shape._overflowing = false;

    /**
     * .openingIndex
     * specifies whether this container has an opening 
     * and if so, where to find that opening line in the 
     * container.lines() array. If there is no opening, 
     * this property returns -1;
     *
     */
    shape._openingIndex = shape.openingIndex >= 0 ? shape.openingIndex : -1;
    shape._bottomIndex = shape.bottomIndex >= 0 ? shape.bottomIndex : -1;

    shape._thickness = 0;

    Object.defineProperty(shape, 'overflowing', {
        get: function get() {
            return this._overflowing;
        },
        set: function set(overflowing) {
            this._overflowing = overflowing;
        }
    });

    Object.defineProperty(shape, 'openingIndex', {
        get: function get() {
            return this._openingIndex;
        },
        set: function set(openingIndex) {
            this._openingIndex = openingIndex;
        }
    });

    Object.defineProperty(shape, 'bottomIndex', {
        get: function get() {
            return this._bottomIndex;
        },
        set: function set(bottomIndex) {
            this._bottomIndex = bottomIndex;
        }
    });

    Object.defineProperty(shape, 'innerLines', {
        get: function get() {
            return this._innerLines;
        },
        set: function set(innerLines) {
            this._innerLines = innerLines;
        }
    });

    shape._innerLines = shape.lines().map(function (line, index) {
        return trees.copyLine(line);
    });

    Object.defineProperty(shape, 'thickness', {
        get: function get() {
            return this._thickness;
        },
        set: function set(thickness) {
            var oldThickness = this._thickness;
            this._thickness = thickness;
            var thicknessDiff = this.thickness - oldThickness;

            trees.resizeLine(shape.innerLines[0], thicknessDiff);
            trees.resizeLine(shape.innerLines[2], thicknessDiff);

            if (shape.bottomIndex >= 0) trees.moveLineVertical(shape.innerLines[shape.bottomIndex], -thicknessDiff);

            shape.innerLines[1].start.x = shape.innerLines[0].end.x;
            shape.innerLines[1].start.y = shape.innerLines[0].end.y;
            shape.innerLines[1].end.x = shape.innerLines[2].start.x;
            shape.innerLines[1].end.y = shape.innerLines[2].start.y;

            shape.innerLines[3].start.x = shape.innerLines[2].end.x;
            shape.innerLines[3].start.y = shape.innerLines[2].end.y;
            shape.innerLines[3].end.x = shape.innerLines[0].start.x;
            shape.innerLines[3].end.y = shape.innerLines[0].start.y;
        }
    });

    var oldRotate = shape.rotate;

    shape.rotate = function (deg, transformOrigin) {
        oldRotate.call(shape, deg, transformOrigin);
        shape.innerLines.forEach(function (line) {
            line.start = trees.rotatePoint(line.start, transformOrigin, deg);
            line.end = trees.rotatePoint(line.end, transformOrigin, deg);
        });
    };

    // let oldDraw = shape.draw;

    // shape.draw = function(ctx) {
    //     oldDraw.call(shape, ctx);
    //     let colors = ["red", "green", "blue", "yellow"]
    //     ctx.yMove(shape.innerLines[0].start);
    //     shape.innerLines.forEach((line, index) => {
    //         ctx.beginPath();
    //         ctx.fillStyle = colors[index] //trees.setOpacity("red", 0.5);
    //         ctx.rect(line.start.x - 1.5, line.start.y - 1.5, 3, 3)
    //         ctx.rect(line.end.x - 1.5, line.end.y - 1.5, 3, 3)
    //         ctx.fill();
    //         ctx.closePath();
    //     });
    //     ctx.fillStyle = shape.color;
    // }

    shape.createSATObject = function () {
        var response = [];
        var lines = shape.lines();
        if (lines.length) {
            lines.forEach(function (line, index) {
                if (index !== shape.openingIndex) {
                    response = response.concat(line.createSATObject());
                }
            });
        }
        return response;
    };

    return shape;
}

exports.Container = Container;

},{"./liquid.js":42}],39:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ContainerComposite = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _complexShape = require('../complexShape.js');

var _liquid = require('./liquid.js');

var _pourComposite = require('./pourComposite.js');

var _meniscus = require('./meniscus.js');

var _container = require('./container.js');

var _line = require('../../line.js');

var _point = require('../../point.js');

var _levelLine = require('./levelLine.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ContainerComposite = function (_ComplexShape) {
    _inherits(ContainerComposite, _ComplexShape);

    function ContainerComposite(x, y, width, height) {
        _classCallCheck(this, ContainerComposite);

        var _this = _possibleConstructorReturn(this, (ContainerComposite.__proto__ || Object.getPrototypeOf(ContainerComposite)).call(this, x, y, width, height));

        _this.type = "ContainerComposite";
        _this._liquidColor = "transparent";
        _this._containers = [];
        _this._liquids = [];
        _this._levelLine = new _levelLine.LevelLine(y);
        _this._speed = 10;
        _this._collidable = false;
        _this._thickness = 0;
        _this.drainVolume = 0.3;
        return _this;
    }

    _createClass(ContainerComposite, [{
        key: 'rotate',
        value: function rotate(deg, transformOrigin) {
            var oldArea = this.liquidArea;
            _get(ContainerComposite.prototype.__proto__ || Object.getPrototypeOf(ContainerComposite.prototype), 'rotate', this).call(this, deg, transformOrigin);

            if (!this.empty && this.liquidCenterPoint) {
                var center = this.liquidCenterPoint;
                this._levelLine.rotate(deg, transformOrigin);
                this._levelLine.rotate(-deg, center);
            }

            this.handleOverflow();
        }
    }, {
        key: 'addShape',
        value: function addShape(shape) {

            var container = (0, _container.Container)(shape);

            var liquid = new _liquid.Liquid(container);
            liquid.color = this.liquidColor;
            liquid.levelLine = this._levelLine;

            _get(ContainerComposite.prototype.__proto__ || Object.getPrototypeOf(ContainerComposite.prototype), 'addShape', this).call(this, container);
            _get(ContainerComposite.prototype.__proto__ || Object.getPrototypeOf(ContainerComposite.prototype), 'addShape', this).call(this, liquid);

            this.containers.push(container);
            this.liquids.push(liquid);
        }
    }, {
        key: 'drain',
        value: function drain(amount) {
            if (typeof amount !== 'number' || amount < 0) {
                throw new Error('Tried to use drain function with invalid amount.');
            }
            if (!this.empty) {
                this.levelLine += amount;
            }
        }
    }, {
        key: 'fill',
        value: function fill(amount) {
            if (typeof amount !== 'number' || amount < 0) {
                throw new Error('Tried to use drain function with invalid amount.');
            }
            if (!this.full) {
                this.levelLine -= amount;
            }
        }
    }, {
        key: 'startDraining',
        value: function startDraining() {
            this.draining = true;
        }
    }, {
        key: 'stopDraining',
        value: function stopDraining() {
            this.draining = false;
        }
    }, {
        key: 'addMeniscus',
        value: function addMeniscus() {
            this.removeMeniscus();
            this.meniscus = new _meniscus.Meniscus(this.overflowStart.x, this.overflowStart.y, this.pourWidth, 5, this.activeOpeningEdge, this.orientation);
            this.meniscus.color = this.liquidColor;
            _get(ContainerComposite.prototype.__proto__ || Object.getPrototypeOf(ContainerComposite.prototype), 'addShape', this).call(this, this.meniscus);
        }
    }, {
        key: 'removeMeniscus',
        value: function removeMeniscus() {
            this.removeShape(this.meniscus);
        }

        //this function is called continuously?

    }, {
        key: 'handleOverflow',
        value: function handleOverflow() {
            if (this.overflowing) {
                this.addMeniscus();
                this.startPour();
                this.startDraining();
            } else {
                this.removeMeniscus();
                this.stopPour();
                this.stopDraining();
            }
        }
    }, {
        key: 'startPour',
        value: function startPour() {
            var start = this.activeOpeningEdge;
            if (!this.pourComposite) {
                this.pourComposite = new _pourComposite.PourComposite(start.x, start.y, this.meniscus.overhangWidth, this.pourHeight);
                this.pourComposite.color = this.liquidColor;
                _get(ContainerComposite.prototype.__proto__ || Object.getPrototypeOf(ContainerComposite.prototype), 'addShape', this).call(this, this.pourComposite);
                // this.moveDrawOrderBack(this.pourComposite);
            } else {
                this.pourComposite.width = this.orientation === "I" || this.orientation === "IV" ? this.meniscus.overhangWidth : -this.meniscus.overhangWidth;
                this.pourComposite.x = start.x;
                this.pourComposite.y = start.y;
            }

            this.pourComposite.start();
        }
    }, {
        key: 'stopPour',
        value: function stopPour() {
            if (this.pourComposite && this.pourComposite.pouring) {
                this.pourComposite.stop();
            }
        }
    }, {
        key: 'animate',
        value: function animate() {
            _get(ContainerComposite.prototype.__proto__ || Object.getPrototypeOf(ContainerComposite.prototype), 'animate', this).call(this);
            if (this.draining) {
                this.drain(this.drainVolume);
            }
            if (this.filling) {
                this.fill(this.drainVolume);
            }
        }
    }, {
        key: 'createSATObject',
        value: function createSATObject() {
            var response = [];

            this.containers.forEach(function (container) {
                response = response.concat(container.createSATObject());
            });

            if (this.pourComposite) {
                response = response.concat(this.pourComposite.createSATObject());
            }
            if (this.liquidTop) {
                response = response.concat(this.liquidTop.createSATObject());
            }
            return response;
        }
    }, {
        key: 'draw',
        value: function draw(ctx) {
            ctx = ctx || this.canvas && this.canvas.ctx;

            if (this.liquids.length) {
                this.liquids.forEach(function (liquid) {
                    liquid.draw(ctx);
                });
            }
            if (this.containers.length) {
                this.containers.forEach(function (container) {
                    container.draw(ctx);
                });
            }
            if (this.shape.length) {
                this.shape.forEach(function (shape) {
                    if (shape.type !== "Container" && shape.type !== "Liquid") {
                        shape.draw(ctx);
                    }
                });
            }

            if (this.liquidCenterPoint) {
                ctx.beginPath();
                ctx.fillStyle = "red";
                ctx.rect(this.liquidCenterPoint.x - 2.5, this.liquidCenterPoint.y - 2.5, 5, 5);
                ctx.rect(this.liquidTop.start.x - 2.5, this.liquidTop.start.y - 2.5, 5, 5);
                ctx.rect(this.liquidTop.end.x - 2.5, this.liquidTop.end.y - 2.5, 5, 5);
                ctx.fill();
                ctx.fillStyle = this.color;
                ctx.closePath();
            }
        }
    }, {
        key: 'x',
        get: function get() {
            return _get(ContainerComposite.prototype.__proto__ || Object.getPrototypeOf(ContainerComposite.prototype), 'x', this);
        },
        set: function set(x) {
            var oldX = this.x;
            var diffX = x - oldX;
            _set(ContainerComposite.prototype.__proto__ || Object.getPrototypeOf(ContainerComposite.prototype), 'x', x, this);
            this.containers.forEach(function (container) {
                container.innerLines.forEach(function (line) {
                    line.start.x += diffX;
                    line.end.x += diffX;
                });
            });
            this.liquids.forEach(function (liquid) {
                liquid.lines.forEach(function (line) {
                    line.start.x += diffX;
                    line.end.x += diffX;
                });
            });
        }
    }, {
        key: 'y',
        get: function get() {
            return _get(ContainerComposite.prototype.__proto__ || Object.getPrototypeOf(ContainerComposite.prototype), 'y', this);
        },
        set: function set(y) {
            var oldY = this.y;
            var diffY = y - oldY;
            _set(ContainerComposite.prototype.__proto__ || Object.getPrototypeOf(ContainerComposite.prototype), 'y', y, this);
            this.containers.forEach(function (container) {
                container.innerLines.forEach(function (line) {
                    line.start.y += diffY;
                    line.end.y += diffY;
                });
            });
            this.liquids.forEach(function (liquid) {
                liquid.lines.forEach(function (line) {
                    line.start.y += diffY;
                    line.end.y += diffY;
                });
            });
            this.levelLine += diffY;
        }
    }, {
        key: 'liquidColor',
        get: function get() {
            return this._liquidColor;
        },
        set: function set(liquidColor) {
            this._liquidColor = liquidColor;
            this.liquids.forEach(function (shape) {
                shape.color = liquidColor;
            });
        }

        /**
         * .level
         * Represents liquid level line as percentage of container's overall height
         */

    }, {
        key: 'level',
        get: function get() {
            return (this.height - (this.levelLine - this.y)) / this.height * 100;
        },
        set: function set(level) {
            if (typeof level !== "number" || level < 0 || level > 100) {
                throw new Error("Level value must be a number between zero and 100.");
            }
            this.levelLine = this.y + this.height * (100 - level) / 100;
        }

        /**
         * .levelLine
         * Represents actual y-value of liquid level line.
         */

    }, {
        key: 'levelLine',
        get: function get() {
            return this._levelLine.y;
        },
        set: function set(y) {
            this._levelLine.y = y;

            this.liquids.forEach(function (liquid) {
                liquid.level();
            });

            this.handleOverflow();
        }
    }, {
        key: 'containers',
        get: function get() {
            return this._containers;
        }
    }, {
        key: 'liquids',
        get: function get() {
            return this._liquids;
        }

        /**
         * Represents the highest point of overflow against the container's opening
         * i.e., the size of the overflow
         */

    }, {
        key: 'overflowStart',
        get: function get() {
            var overflowStart = null;
            this.liquids.forEach(function (liquid) {
                overflowStart = overflowStart || liquid.overflowStart;
            });
            return overflowStart;
        }

        /**
         * .liquidTop
         * represents the 'top' line of the liquid in container
         * start = left, end = right
         */

    }, {
        key: 'liquidTop',
        get: function get() {
            var _this2 = this;

            var liquidTop = null;
            var leftMost = null;
            var rightMost = null;
            this.liquids.forEach(function (liquid) {
                liquid.lines.forEach(function (line) {
                    if (Math.abs(line.start.y - _this2.levelLine) < 0.0001) {
                        if (!leftMost || line.start.x < leftMost.x) leftMost = line.start;
                        if (!rightMost || line.start.x > rightMost.x) rightMost = line.start;
                    }
                    if (Math.abs(line.end.y - _this2.levelLine) < 0.0001) {
                        if (!leftMost || line.end.x < leftMost.x) leftMost = line.end;
                        if (!rightMost || line.end.x > rightMost.x) rightMost = line.end;
                    }
                });
            });

            if (leftMost && rightMost) liquidTop = new _line.Line(leftMost, rightMost);
            return liquidTop;
        }

        /**
         *.liquidCenterPoint
         * Represents the mid point of liquid top.
         */

    }, {
        key: 'liquidCenterPoint',
        get: function get() {
            var liquidTop = this.liquidTop;
            if (liquidTop) {
                return new _point.Point(liquidTop.start.x + (liquidTop.end.x - liquidTop.start.x) / 2, liquidTop.start.y);
            } else {
                return null;
            }
        }

        /**
         * .opening
         * Returns the first line defined as an opening for the container.
         * (Composite can only have one opening line).
         */

    }, {
        key: 'opening',
        get: function get() {
            var result = null;
            this.containers.forEach(function (container) {
                if (container.openingIndex >= 0) {
                    result = container.lines()[container.openingIndex] || result;
                }
            });
            return result;
        }

        /**
         * .activeOpeningEdge
         * represents the 'spout' of the container. point at which liquid exits.
         */

    }, {
        key: 'activeOpeningEdge',
        get: function get() {
            var opening = this.opening;
            if (opening) {
                return opening.start.y > opening.end.y ? opening.start : opening.end;
            }
            return opening;
        }

        /**
         * .pourWidth
         * Distance between the container opening and point of overflow
         */

    }, {
        key: 'pourWidth',
        get: function get() {
            var result = null;
            if (this.overflowStart && this.activeOpeningEdge) {
                result = Math.abs(trees.getDistance(this.overflowStart, this.activeOpeningEdge));
            }
            return result;
        }

        /**
         * .overflowing
         * Boolean 
         * Returns true if any opening of the composite is overflowing
         */

    }, {
        key: 'overflowing',
        get: function get() {
            var result = false;
            this.containers.forEach(function (container) {
                result = result || container.overflowing;
            });
            return result;
        }
    }, {
        key: 'full',
        get: function get() {
            return this.levelLine <= this.boundary.a.y;
        }
    }, {
        key: 'empty',
        get: function get() {
            var empty = true;
            this.liquids.forEach(function (liquid) {
                empty = empty && !liquid.lines.length;
            });
            return empty;
        }
    }, {
        key: 'speed',
        get: function get() {
            return this._speed;
        },
        set: function set(speed) {
            this._speed = speed;
        }
    }, {
        key: 'pourHeight',
        get: function get() {
            return this._pourHeight;
        },
        set: function set(pourHeight) {
            this._pourHeight = pourHeight;
        }
    }, {
        key: 'thickness',
        get: function get() {
            return this._thickness;
        },
        set: function set(thickness) {
            this._thickness = thickness;
            this.containers.forEach(function (container) {
                container.thickness = thickness;
            });
            this.liquids.forEach(function (liquid) {
                liquid.level();
            });
        }
    }, {
        key: 'liquidArea',
        get: function get() {
            var area = 0;
            this.liquids.forEach(function (liquid) {
                area += liquid.area;
            });
            return area;
        }
    }, {
        key: 'orientation',
        get: function get() {
            var opening = this.opening;
            var result = null;
            if (opening) {
                if (opening.start.x <= opening.end.x && opening.start.y <= opening.end.y) {
                    result = "I";
                }
                if (opening.start.x <= opening.end.x && opening.start.y > opening.end.y) {
                    result = "II";
                }
                if (opening.start.x > opening.end.x && opening.start.y > opening.end.y) {
                    result = "III";
                }
                if (opening.start.x > opening.end.x && opening.start.y <= opening.end.y) {
                    result = "IV";
                }
            }
            return result;
        }
    }]);

    return ContainerComposite;
}(_complexShape.ComplexShape);

exports.ContainerComposite = ContainerComposite;

},{"../../line.js":57,"../../point.js":63,"../complexShape.js":37,"./container.js":38,"./levelLine.js":41,"./liquid.js":42,"./meniscus.js":43,"./pourComposite.js":45}],40:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Drop = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _point = require('../../point.js');

var _line = require('../../line.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Drop = function (_Line) {
    _inherits(Drop, _Line);

    function Drop(origin, width) {
        _classCallCheck(this, Drop);

        var start = new _point.Point(origin.x, origin.y);
        var end = new _point.Point(origin.x + width, origin.y);

        var _this = _possibleConstructorReturn(this, (Drop.__proto__ || Object.getPrototypeOf(Drop)).call(this, start, end));

        _this.type = "Drop";
        return _this;
    }

    _createClass(Drop, [{
        key: 'y',
        get: function get() {
            return this.start.y;
        },
        set: function set(y) {
            this.start.y = y;
            this.end.y = y;
        }
    }, {
        key: 'x',
        get: function get() {
            return this.start.x;
        },
        set: function set(x) {
            var width = this.width;
            this.start.x = x;
            this.end.x = x + width;
        }
    }, {
        key: 'width',
        get: function get() {
            return this.end.x - this.start.x;
        },
        set: function set(width) {
            this.end.x = this.start.x + width;
        }
    }]);

    return Drop;
}(_line.Line);

exports.Drop = Drop;

},{"../../line.js":57,"../../point.js":63}],41:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.LevelLine = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _line = require('../../line.js');

var _point = require('../../point.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var START = -2000;
var END = 2000;

var LevelLine = function (_Line) {
    _inherits(LevelLine, _Line);

    function LevelLine(y) {
        _classCallCheck(this, LevelLine);

        if (typeof y !== "number") {
            throw new Error("LevelLine cannot be created without a valid y position");
        }

        var start = new _point.Point(START, y);
        var end = new _point.Point(END, y);
        return _possibleConstructorReturn(this, (LevelLine.__proto__ || Object.getPrototypeOf(LevelLine)).call(this, start, end));
    }

    _createClass(LevelLine, [{
        key: 'rotate',
        value: function rotate(deg, transformOrigin) {
            this.start = trees.rotatePoint(this.start, transformOrigin, deg);
            this.end = trees.rotatePoint(this.end, transformOrigin, deg);
        }
    }, {
        key: 'y',
        get: function get() {
            return this.start.y;
        },
        set: function set(y) {
            if (typeof y !== "number") {
                throw new Error("Invalid value supplied for LevelLine");
            }
            this.start.y = y;
            this.end.y = y;
        }
    }]);

    return LevelLine;
}(_line.Line);

exports.LevelLine = LevelLine;

},{"../../line.js":57,"../../point.js":63}],42:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Liquid = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _sprite = require('../../sprite.js');

var _line = require('../../line.js');

var _levelLine = require('./levelLine.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /*
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               approach:
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               for each complex object, it should specify an array of "lines" to be drawn in order.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               (note that it may be important to distinguish a contiguous object as 'complex' and an object 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               which contains non-contiguous portions as 'composite' in the future.)
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               a liquid class is created which has access to this array.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               once we have this array, we can draw a line across the entire sprite and check for intersections across 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               each line. Wherever an intersection is found, the intersection becomes the new endpoint for the intersecting
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               line. The liquid class is drawn using this new, improved set of instructions.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               whenever the liquid level is raised or lowered or the container is moved or rotated, 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               this intersection algorithm is re-calculated.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               this should achieve the desired result.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               */

var Liquid = function (_Sprite) {
    _inherits(Liquid, _Sprite);

    function Liquid(container) {
        _classCallCheck(this, Liquid);

        if (!container || container.type !== "Container") {
            throw new Error("Cannot create a liquid without a properly formed container sprite.");
        }

        var _this = _possibleConstructorReturn(this, (Liquid.__proto__ || Object.getPrototypeOf(Liquid)).call(this, container.x, container.y, container.width, container.height));

        _this.type = "Liquid";
        _this.container = container;
        _this.lines = _this.container.innerLines;
        _this.overflowStart = null;
        _this._levelLine = new _levelLine.LevelLine(container.y);
        return _this;
    }

    _createClass(Liquid, [{
        key: 'level',
        value: function level() {
            var _this2 = this;

            //since we are dealing with quadrilaterals
            //there are 2 intersections to track
            //the left and right.

            //we need to know which is which.

            //we will capture the first intersection and compare with the second.
            var firstIntersect = null;
            var firstIntersectIndex = null;

            this.lines = [];
            this.container.overflowing = false;

            this.container.innerLines.forEach(function (line, index) {
                var copiedLine = trees.copyLine(line);
                var intersection = trees.intersection(copiedLine, _this2.levelLine);

                if (intersection.onLine1 && intersection.onLine2) {
                    if (index === _this2.container.openingIndex) {
                        _this2.container.overflowing = true;
                        _this2.overflowStart = intersection;
                    }

                    _this2.lines.push(copiedLine);

                    if (!firstIntersect) {
                        firstIntersect = intersection;
                        firstIntersectIndex = _this2.lines.indexOf(copiedLine);
                    } else {
                        if (firstIntersect.x < intersection.x) {
                            copiedLine.start = intersection;
                            _this2.lines[firstIntersectIndex].end = firstIntersect;
                        } else {
                            copiedLine.end = intersection;
                            _this2.lines[firstIntersectIndex].start = firstIntersect;
                        }
                    }
                } else if (copiedLine.start.y > _this2.levelLine.y) {
                    //keep any lines that do not intersect the leveline
                    //as long as they are below it
                    if (index === _this2.container.openingIndex) {
                        _this2.container.overflowing = true;
                        _this2.overflowStart = copiedLine.start.y < copiedLine.end.y ? copiedLine.start : copiedLine.end;
                    }
                    _this2.lines.push(copiedLine);
                }
            });
        }
    }, {
        key: 'rotate',
        value: function rotate(deg, transformOrigin) {
            _get(Liquid.prototype.__proto__ || Object.getPrototypeOf(Liquid.prototype), 'rotate', this).call(this, deg, transformOrigin);
            this.level();
        }
    }, {
        key: 'draw',
        value: function draw(ctx) {
            _get(Liquid.prototype.__proto__ || Object.getPrototypeOf(Liquid.prototype), 'draw', this).call(this, ctx);
            if (this.lines.length) {
                ctx.beginPath();
                ctx.yMove(this.lines[0].start);
                this.lines.forEach(function (line, index) {
                    ctx.yLine(line.start);
                    ctx.yLine(line.end);
                });
                ctx.fill();
                ctx.closePath();
            }
            // ctx.yMove(this.levelLine.start);
            // ctx.yLine(this.levelLine.end);
            // ctx.stroke();
        }
    }, {
        key: 'container',
        get: function get() {
            return this._container;
        },
        set: function set(container) {
            this._container = container;
        }
    }, {
        key: 'overflowStart',
        get: function get() {
            return this._overflowStart;
        },
        set: function set(overflowStart) {
            this._overflowStart = overflowStart;
        }
    }, {
        key: 'levelLine',
        get: function get() {
            return this._levelLine;
        },
        set: function set(levelLine) {
            this._levelLine = levelLine;
        }
    }, {
        key: 'area',
        get: function get() {
            return trees.polygonArea(this.lines);
        }
    }]);

    return Liquid;
}(_sprite.Sprite);

exports.Liquid = Liquid;

},{"../../line.js":57,"../../sprite.js":74,"./levelLine.js":41}],43:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Meniscus = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _sprite = require('../../sprite.js');

var _curve = require('../../curve.js');

var _point = require('../../point.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Meniscus = function (_Sprite) {
    _inherits(Meniscus, _Sprite);

    function Meniscus(x, y, width, height, openingEdge, orientation) {
        _classCallCheck(this, Meniscus);

        var _this = _possibleConstructorReturn(this, (Meniscus.__proto__ || Object.getPrototypeOf(Meniscus)).call(this, x, y, width, height));

        if (!openingEdge) {
            throw new Error("Attempted to create meniscus without openingEdgepoint");
        }

        _this.type = "Meniscus";
        _this._cp1;
        _this._cp2;
        _this._overhang;
        _this._openingEdge = openingEdge;
        _this._factor = 0.25;
        _this._overhangWidth = width * _this._factor;

        // if (orientation === "I") {
        //     this._cp1 = new Point(x + this.width / 2, this.y);
        //     this._cp2 = new Point(x + this.width, this.y);
        //     this._overhang = new Point(this._openingEdge.x + this._overhangWidth, this._openingEdge.y);
        // } else if (orientation === "II") {
        //     this._cp1 = new Point(x - this.width / 2, this.y);
        //     this._cp2 = new Point(x - this.width, this.y);
        //     this._overhang = new Point(this._openingEdge.x - this._overhangWidth, this._openingEdge.y);
        // } else if (orientation === "III") {
        //     this._cp1 = new Point(x - this.width / 2, this.y);
        //     this._cp2 = new Point(x - this.width, this.y);
        //     this._overhang = new Point(this._openingEdge.x - this._overhangWidth, this._openingEdge.y);
        // } else if (orientation === "IV") {
        //     this._cp1 = new Point(x + this.width / 2, this.y);
        //     this._cp2 = new Point(x + this.width, this.y);
        //     this._overhang = new Point(this._openingEdge.x + this._overhangWidth, this._openingEdge.y);
        // }

        //no curves for now.

        if (orientation === "I") {
            _this._cp1 = _this.origin;
            _this._cp2 = new _point.Point(_this._openingEdge.x + _this._overhangWidth, _this._openingEdge.y);
            _this._overhang = new _point.Point(_this._openingEdge.x + _this._overhangWidth, _this._openingEdge.y);
        } else if (orientation === "II") {
            _this._cp1 = _this.origin;
            _this._cp2 = new _point.Point(_this._openingEdge.x - _this._overhangWidth, _this._openingEdge.y);
            _this._overhang = new _point.Point(_this._openingEdge.x - _this._overhangWidth, _this._openingEdge.y);
        } else if (orientation === "III") {
            _this._cp1 = _this.origin;
            _this._cp2 = new _point.Point(_this._openingEdge.x - _this._overhangWidth, _this._openingEdge.y);
            _this._overhang = new _point.Point(_this._openingEdge.x - _this._overhangWidth, _this._openingEdge.y);
        } else if (orientation === "IV") {
            _this._cp1 = _this.origin;
            _this._cp2 = new _point.Point(_this._openingEdge.x + _this._overhangWidth, _this._openingEdge.y);
            _this._overhang = new _point.Point(_this._openingEdge.x + _this._overhangWidth, _this._openingEdge.y);
        }

        _this._curve = new _curve.Curve(_this._cp1, _this._cp2, _this._overhang);
        return _this;
    }

    _createClass(Meniscus, [{
        key: 'draw',
        value: function draw(ctx) {
            _get(Meniscus.prototype.__proto__ || Object.getPrototypeOf(Meniscus.prototype), 'draw', this).call(this, ctx);
            ctx.beginPath();
            ctx.yMove(this.origin);
            ctx.curve(this._curve);
            ctx.yLine(this._openingEdge);
            ctx.yLine(this.origin);
            ctx.closePath();
            ctx.fill();

            // ctx.fillStyle = "red";
            // ctx.rect(this._cp1.x, this._cp1.y, 5, 5)
            // ctx.rect(this._cp2.x, this._cp2.y, 5, 5)
            // ctx.stroke();

            // ctx.fillStyle = this.color;
        }
    }, {
        key: 'factor',
        get: function get() {
            return this._factor;
        },
        set: function set(factor) {
            this._factor = factor;
        }
    }, {
        key: 'overhangWidth',
        get: function get() {
            return this._overhangWidth;
        },
        set: function set(overhangWidth) {
            this._overhangWidth = overhangWidth;
        }
    }]);

    return Meniscus;
}(_sprite.Sprite);

exports.Meniscus = Meniscus;

},{"../../curve.js":53,"../../point.js":63,"../../sprite.js":74}],44:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Pour = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _drop = require("./drop.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Pour = function () {
    function Pour(origin, width) {
        _classCallCheck(this, Pour);

        this.drops = [];
        this._width = width;
        this.origin = origin;
        this.addDrop();
        this.type = "Pour";

        this.oscillateSpeed = 4;
        this.oscillate = false;
        this.oscillateInterval = width.percent(1);

        this.oscillateCounter = 0;
    }

    _createClass(Pour, [{
        key: "animate",
        value: function animate() {
            if (this.oscillate) {
                if (this.oscillateCounter < this.oscillateSpeed) {
                    this.oscillateCounter++;
                } else {
                    this.oscillateCounter = 0;
                    this.oscillateInterval *= -1;
                }
            }
        }
    }, {
        key: "pour",
        value: function pour(amount) {
            var _this = this;

            this.drops.forEach(function (drop, index) {
                drop.y += amount;
                drop.y += amount;
                if (drop.y > _this.endPoint) {
                    // this.removeDrop(drop)
                }
            });
        }
    }, {
        key: "addDrop",
        value: function addDrop() {
            var drop = new _drop.Drop(this.origin, this.width);
            if (this.oscillate) {
                drop.x = this.origin.x + this.oscillateInterval;
            }
            this.drops.push(drop);
        }
    }, {
        key: "removeDrop",
        value: function removeDrop(drop) {
            var index = this.drops.indexOf(drop);
            if (index >= 0) {
                this.drops.splice(index, 1);
            }
        }
    }, {
        key: "createSATObject",
        value: function createSATObject() {
            if (this.drops.length) {
                var lastDrop = this.drops[0];
                return [new SAT.Polygon(new SAT.Vector(0, 0), [new SAT.Vector(lastDrop.end.x, lastDrop.end.y), new SAT.Vector(lastDrop.end.x, lastDrop.end.y - 100), new SAT.Vector(lastDrop.start.x, lastDrop.end.y - 100), new SAT.Vector(lastDrop.start.x, lastDrop.start.y)])];
            } else {
                return [];
            }
        }
    }, {
        key: "draw",
        value: function draw(ctx) {
            ctx = ctx || this.canvas && this.canvas.ctx;

            if (this.drops.length) {
                ctx.fillStyle = this.color;
                ctx.beginPath();
                ctx.yMove(this.drops[0].start);
                this.drops.forEach(function (drop) {
                    ctx.yLine(drop.start);
                });
                this.drops.reverse();
                ctx.yLine(this.drops[0].end);
                this.drops.forEach(function (drop) {
                    ctx.yLine(drop.end);
                });
                this.drops.reverse();
                ctx.yLine(this.drops[0].start);
                ctx.fill();
                ctx.closePath();
            }
        }
    }, {
        key: "width",
        get: function get() {
            return this._width;
        },
        set: function set(width) {
            this._width = width;
        }
    }]);

    return Pour;
}();

exports.Pour = Pour;

},{"./drop.js":40}],45:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.PourComposite = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _complexShape = require('../complexShape.js');

var _pour = require('./pour.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var POURSPEED = 6;

var PourComposite = function (_ComplexShape) {
    _inherits(PourComposite, _ComplexShape);

    function PourComposite(x, y, width, height) {
        _classCallCheck(this, PourComposite);

        var _this = _possibleConstructorReturn(this, (PourComposite.__proto__ || Object.getPrototypeOf(PourComposite)).call(this, x, y, width, height));

        _this.type = "PourComposite";
        _this.pours = [];
        _this._pourSpeed = POURSPEED;
        _this._pouring = false;
        return _this;
    }

    _createClass(PourComposite, [{
        key: 'pour',
        value: function pour() {
            var _this2 = this;

            if (this.pours.length) {
                this.pours.forEach(function (pour) {
                    pour.pour(_this2.pourSpeed);
                });
            }
            if (this.pouring) this.activePour.addDrop();
        }
    }, {
        key: 'addPour',
        value: function addPour() {
            var pour = new _pour.Pour(this.origin, this.width);
            this.pours.push(pour);
            pour.endPoint = this.y + this.height;
        }
    }, {
        key: 'animate',
        value: function animate() {
            if (this.pouring || this.finishing) this.pour();
            if (!this.pouring && this.finishing) {
                if (!this.activePour.drops.length) this.finishing = false;
            }
            this.pours.forEach(function (pour) {
                if (pour.animate) {
                    pour.animate();
                }
            });
        }
    }, {
        key: 'start',
        value: function start() {
            if (!this.pouring) {
                this.addPour();
                this.pouring = true;
            }
        }
    }, {
        key: 'stop',
        value: function stop() {
            this.pouring = false;
            this.finishing = true;
        }
    }, {
        key: 'createSATObject',
        value: function createSATObject() {
            var response = [];
            this.pours.forEach(function (pour) {
                response = response.concat(pour.createSATObject());
            });
            return response;
        }
    }, {
        key: 'draw',
        value: function draw(ctx) {
            ctx = ctx || this.canvas && this.canvas.ctx;
            _get(PourComposite.prototype.__proto__ || Object.getPrototypeOf(PourComposite.prototype), 'draw', this).call(this, ctx);
            if (this.pours.length) {
                this.pours.forEach(function (pour) {
                    pour.draw(ctx);
                });
            }
        }
    }, {
        key: 'x',
        get: function get() {
            return _get(PourComposite.prototype.__proto__ || Object.getPrototypeOf(PourComposite.prototype), 'x', this);
        },
        set: function set(x) {
            _set(PourComposite.prototype.__proto__ || Object.getPrototypeOf(PourComposite.prototype), 'x', x, this);
            this.activePour.origin.x = x;
        }
    }, {
        key: 'y',
        get: function get() {
            return _get(PourComposite.prototype.__proto__ || Object.getPrototypeOf(PourComposite.prototype), 'y', this);
        },
        set: function set(y) {
            _set(PourComposite.prototype.__proto__ || Object.getPrototypeOf(PourComposite.prototype), 'y', y, this);
            this.activePour.origin.y = y;
        }
    }, {
        key: 'width',
        get: function get() {
            return _get(PourComposite.prototype.__proto__ || Object.getPrototypeOf(PourComposite.prototype), 'width', this);
        },
        set: function set(width) {
            _set(PourComposite.prototype.__proto__ || Object.getPrototypeOf(PourComposite.prototype), 'width', width, this);
            this.activePour.width = width;
        }
    }, {
        key: 'pouring',
        get: function get() {
            return this._pouring;
        },
        set: function set(pouring) {
            this._pouring = pouring;
        }
    }, {
        key: 'pourSpeed',
        get: function get() {
            return this._pourSpeed;
        },
        set: function set(pourSpeed) {
            this._pourSpeed = pourSpeed;
        }
    }, {
        key: 'activePour',
        get: function get() {
            if (this.pours.length) {
                return this.pours[this.pours.length - 1];
            } else {
                return null;
            }
        }
    }]);

    return PourComposite;
}(_complexShape.ComplexShape);

exports.PourComposite = PourComposite;

},{"../complexShape.js":37,"./pour.js":44}],46:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Hose = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _point = require('../point.js');

var _simples = require('../simples/simples.js');

var _complexShape = require('./complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Hose = function (_ComplexShape) {
    _inherits(Hose, _ComplexShape);

    function Hose(x, y, width, height) {
        _classCallCheck(this, Hose);

        var _this = _possibleConstructorReturn(this, (Hose.__proto__ || Object.getPrototypeOf(Hose)).call(this, x, y, width, height));

        _this.type = "Hose";
        _this.sectionAngle = 0;
        _this.sectionLength = 5;
        _this.sectionColor = "black";
        _this.fullSectionColor = "orange";
        _this.length = Math.floor(width / height);
        _this.fullCounter = 0;

        for (var i = 0; i < _this.length; i++) {
            var link = new _simples.simples.Rectangle(x + i * height, y, height, height);
            _this.addShape(link);
        }
        return _this;
    }

    _createClass(Hose, [{
        key: 'addLink',
        value: function addLink() {
            var lastLink = this.shape[this.shape.length - 1];
            var link = new _simples.simples.Rectangle(lastLink.b.x, lastLink.b.y, this.height, this.height);
            var angle = trees.getAngle(lastLink.a, lastLink.b);
            link.rotate(angle, lastLink.b);
            this.addShape(link);
        }
    }, {
        key: 'selectSection',
        value: function selectSection(shape) {
            var _this2 = this;

            this.shape.forEach(function (shape) {
                shape.color = _this2.color;
                shape.selectedSection = false;
            });

            var index = this.shape.indexOf(shape);

            if (index > this.shape.length - this.sectionLength) {
                index = this.shape.length - this.sectionLength;
            }

            var section = this.shape.slice(index, index + this.sectionLength);
            if (section.length) {
                section.forEach(function (shape) {
                    shape.selectedSection = true;
                });
            }
        }
    }, {
        key: 'fill',
        value: function fill(counter) {

            this.empty = false;

            var next = this.shape[this.fullCounter];
            next.isFull = true;
            next.color = this.fullSectionColor;
            if (this.fullCounter === this.shape.length - 1) {
                this.full = true;
            } else {
                this.full = false;
                this.fullCounter += counter;
            }
        }
    }, {
        key: 'drain',
        value: function drain(counter) {

            this.full = false;

            for (var i = 0; i < this.shape.length; i++) {
                var next = this.shape[i];
                if (next.isFull) {
                    next.isFull = false;
                    next.color = this.color;
                    break;
                }
            }

            if (this.fullCounter === 0) {
                this.empty = true;
            } else {
                this.empty = false;
                this.fullCounter -= counter;
            }
        }
    }, {
        key: 'bend',
        value: function bend(degree) {
            var _this3 = this;

            var section = [];
            var end = 0;

            this.sectionAngle += degree;
            this.shape.forEach(function (shape) {
                if (shape.selectedSection) {
                    section.push(shape);
                    end = _this3.shape.indexOf(shape) + 1;
                }
            });

            var increment = degree / section.length;
            var i = 0;
            if (section.length) {
                section.forEach(function (shape, index) {

                    shape.rotate(i, shape.d);
                    if (index > 0) {
                        shape.x = section[index - 1].b.x;
                        shape.y = section[index - 1].b.y;
                    }
                    i += increment;
                });
                var remainder = this.shape.slice(end);
                remainder.forEach(function (shape, index) {
                    shape.rotate(degree, section[0].origin);
                    if (index > 0) {
                        shape.x = remainder[index - 1].b.x;
                        shape.y = remainder[index - 1].b.y;
                    } else {
                        shape.x = section[section.length - 1].b.x;
                        shape.y = section[section.length - 1].b.y;
                    }
                });
                this._updateBoundaries();
            }
        }
    }, {
        key: '_updateBoundaries',
        value: function _updateBoundaries() {
            _get(Hose.prototype.__proto__ || Object.getPrototypeOf(Hose.prototype), '_updateBoundaries', this).call(this);

            var leftMostShape = void 0;
            var rightMostShape = void 0;
            var topMostShape = void 0;
            var bottomMostShape = void 0;
            if (this.shape && this.shape.length > 0) {
                this.shape.forEach(function (shape) {
                    if (!leftMostShape || shape.a.x < leftMostShape.a.x) {
                        leftMostShape = shape;
                    }
                    if (!topMostShape || shape.a.y < topMostShape.a.y) {
                        topMostShape = shape;
                    }
                    if (!rightMostShape || shape.c.x > rightMostShape.c.x) {
                        rightMostShape = shape;
                    }
                    if (!bottomMostShape || shape.c.y > bottomMostShape.c.y) {
                        bottomMostShape = shape;
                    }
                });

                this.boundary.a = new _point.Point(leftMostShape.a.x, topMostShape.a.y);
                this.boundary.b = new _point.Point(rightMostShape.c.x, topMostShape.a.y);
                this.boundary.c = new _point.Point(rightMostShape.c.x, bottomMostShape.c.y);
                this.boundary.d = new _point.Point(leftMostShape.a.x, bottomMostShape.c.y);
            }
        }
    }, {
        key: 'draw',
        value: function draw(ctx) {
            //super.draw(ctx);
            ctx = ctx || this.canvas && this.canvas.ctx;

            ctx.fillStyle = this.fullSectionColor;
            this.drawHose(ctx, this.fullSection);

            ctx.fillStyle = this.color;
            this.drawHose(ctx, this.shape);

            ctx.fillStyle = this.sectionColor;
            this.drawHose(ctx, this.selectedSection);
        }
    }, {
        key: 'drawHose',
        value: function drawHose(ctx, hose) {
            ctx = ctx || this.canvas && this.canvas.ctx;

            if (hose.length) {
                ctx.beginPath();
                ctx.yMove(hose[0].a);

                hose.forEach(function (shape) {
                    ctx.yLine(shape.a);
                    ctx.yLine(shape.b);
                });

                hose.reverse();
                ctx.yLine(hose[0].c);

                hose.forEach(function (shape) {
                    ctx.yLine(shape.c);
                    ctx.yLine(shape.d);
                });

                hose.reverse();
                ctx.yLine(hose[0].a);

                ctx.fill();
                if (this.lineColor) ctx.stroke();
                ctx.closePath();
            }
        }
    }, {
        key: 'full',
        get: function get() {
            return this._full;
        },
        set: function set(full) {
            this._full = full;
        }
    }, {
        key: 'empty',
        get: function get() {
            return this._empty;
        },
        set: function set(empty) {
            this._empty = empty;
        }
    }, {
        key: 'fullSection',
        get: function get() {
            var section = [];
            this.shape.forEach(function (shape) {
                if (shape.isFull) {
                    section.push(shape);
                }
            });
            return section;
        }
    }, {
        key: 'selectedSection',
        get: function get() {
            var section = [];
            this.shape.forEach(function (shape) {
                if (shape.selectedSection) {
                    section.push(shape);
                }
            });
            return section;
        }
    }, {
        key: 'tip',
        get: function get() {
            return this.shape[this.shape.length - 1].center;
        }
    }]);

    return Hose;
}(_complexShape.ComplexShape);

exports.Hose = Hose;

},{"../point.js":63,"../simples/simples.js":69,"./complexShape.js":37}],47:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Arch = undefined;

var _complex = require('../complex.js');

var _thickShape = require('./thickShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Arch = function (_ThickShape) {
    _inherits(Arch, _ThickShape);

    function Arch(x, y, width, height, thickness) {
        _classCallCheck(this, Arch);

        var _this = _possibleConstructorReturn(this, (Arch.__proto__ || Object.getPrototypeOf(Arch)).call(this, x, y, width, height, thickness));

        _this.type = "Arch";

        _this.right = new _complex.complex.Macaroni(x + width / 2, y, width / 2, height, thickness);
        _this.left = new _complex.complex.Macaroni(x, y, width / 2, height, thickness);
        _this.left.rotate(270, _this.left.center);
        _this.addShape(_this.right);
        _this.addShape(_this.left);
        return _this;
    }

    return Arch;
}(_thickShape.ThickShape);

exports.Arch = Arch;

},{"../complex.js":36,"./thickShape.js":50}],48:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Donut = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _complex = require('../complex.js');

var _thickShape = require('./thickShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Donut = function (_ThickShape) {
    _inherits(Donut, _ThickShape);

    function Donut(x, y, width, height, thickness) {
        _classCallCheck(this, Donut);

        var _this = _possibleConstructorReturn(this, (Donut.__proto__ || Object.getPrototypeOf(Donut)).call(this, x, y, width, height, thickness));

        _this.type = "Donut";

        var top = new _complex.complex.Arch(x, y, width, height / 2, thickness);
        var bottom = new _complex.complex.Arch(x, y + height / 2, width, height / 2, thickness);
        bottom.rotate(180, bottom.center);
        _this.addShape(top);
        _this.addShape(bottom);

        return _this;
    }

    _createClass(Donut, [{
        key: 'rotate',
        value: function rotate() {}
    }, {
        key: 'draw',
        value: function draw(ctx) {
            _get(Donut.prototype.__proto__ || Object.getPrototypeOf(Donut.prototype), 'draw', this).call(this, ctx);

            ctx.beginPath();

            this.shape.forEach(function (shape) {
                shape.draw(ctx);
            });

            ctx.closePath();
        }
    }]);

    return Donut;
}(_thickShape.ThickShape);

exports.Donut = Donut;

},{"../complex.js":36,"./thickShape.js":50}],49:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Macaroni = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _wedge = require('../../simples/wedge.js');

var _thickShape = require('./thickShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Macaroni = function (_ThickShape) {
    _inherits(Macaroni, _ThickShape);

    function Macaroni(x, y, width, height, thickness) {
        _classCallCheck(this, Macaroni);

        var _this = _possibleConstructorReturn(this, (Macaroni.__proto__ || Object.getPrototypeOf(Macaroni)).call(this, x, y, width, height, thickness));

        _this.type = "Macaroni";

        _this.outer = new _wedge.Wedge(x, y, width, height);
        _this.inner = new _wedge.Wedge(x, y + thickness, width - thickness, height - thickness);
        _this.addShape(_this.inner);
        _this.addShape(_this.outer);
        return _this;
    }

    _createClass(Macaroni, [{
        key: 'draw',
        value: function draw(ctx) {
            ctx.beginPath();
            ctx.yMove(this.a);
            ctx.curve(this.outer.curve);
            ctx.yLine(this.inner.c);
            ctx.curve(this.inner.getReverseCurve());
            ctx.yLine(this.a);
            ctx.closePath();
            ctx.fill();
            if (this.lineColor) ctx.stroke();
        }
    }, {
        key: 'width',
        get: function get() {
            return _get(Macaroni.prototype.__proto__ || Object.getPrototypeOf(Macaroni.prototype), 'width', this);
        },
        set: function set(width) {
            var oldWidth = this.width;
            _set(Macaroni.prototype.__proto__ || Object.getPrototypeOf(Macaroni.prototype), 'width', width, this);
            var widthDiff = this.width - oldWidth;
            this.thickness = this.thickness + widthDiff * this.relativeThickness;

            this.inner.y = trees.getPointOnLine(this.outer.origin, this.thickness, trees.getAngle(this.a, this.d)).y;
            this.inner.x = trees.getPointOnLine(this.outer.origin, this.thickness, trees.getAngle(this.a, this.d)).x;
        }
    }]);

    return Macaroni;
}(_thickShape.ThickShape);

exports.Macaroni = Macaroni;

},{"../../simples/wedge.js":73,"./thickShape.js":50}],50:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ThickShape = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _circularShape = require("../circularShapes/circularShape.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ThickShape = function (_CircularShape) {
    _inherits(ThickShape, _CircularShape);

    function ThickShape(x, y, width, height, thickness) {
        _classCallCheck(this, ThickShape);

        var _this = _possibleConstructorReturn(this, (ThickShape.__proto__ || Object.getPrototypeOf(ThickShape)).call(this, x, y, width, height));

        _this.type = "ThickShape";
        _this._thickness = thickness || 1;
        return _this;
    }

    _createClass(ThickShape, [{
        key: "addShape",
        value: function addShape(shape) {
            _get(ThickShape.prototype.__proto__ || Object.getPrototypeOf(ThickShape.prototype), "addShape", this).call(this, shape);
            this.relativeThickness = this.thickness / this.width;
        }
    }, {
        key: "thickness",
        get: function get() {
            return this._thickness;
        },
        set: function set(thickness) {
            this._thickness = thickness;
        }
    }]);

    return ThickShape;
}(_circularShape.CircularShape);

exports.ThickShape = ThickShape;

},{"../circularShapes/circularShape.js":34}],51:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.YangYin = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _simples = require('../simples/simples.js');

var _complexShape = require('./complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var YangYin = function (_ComplexShape) {
    _inherits(YangYin, _ComplexShape);

    function YangYin(x, y, width, height) {
        _classCallCheck(this, YangYin);

        var _this = _possibleConstructorReturn(this, (YangYin.__proto__ || Object.getPrototypeOf(YangYin)).call(this, x, y, width, height));

        _this.type = "YangYin";

        _this.topWedge = new _simples.simples.Wedge(x + width.percent(50), y, width.percent(50), width.percent(50));
        _this.topWedge.rotate(-90, _this.topWedge.center);
        _this.bottomWedge = new _simples.simples.Wedge(x, y + width.percent(50), width.percent(50), width.percent(50));
        _this.bottomWedge.rotate(90, _this.bottomWedge.center);
        _this.addShape(_this.topWedge);
        _this.addShape(_this.bottomWedge);

        return _this;
    }

    _createClass(YangYin, [{
        key: 'draw',
        value: function draw(ctx) {
            ctx.beginPath();
            ctx.yMove(this.topWedge.origin);
            ctx.curve(this.topWedge.curve);
            ctx.yLine(this.c);
            ctx.yLine(this.d);
            ctx.bezierCurveTo(this.bottomWedge.cp2.x, this.bottomWedge.cp2.y, this.bottomWedge.cp1.x, this.bottomWedge.cp1.y, this.topWedge.a.x, this.topWedge.a.y);
            ctx.fillStyle = this.color;
            ctx.fill();
            if (this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }]);

    return YangYin;
}(_complexShape.ComplexShape);

exports.YangYin = YangYin;

},{"../simples/simples.js":69,"./complexShape.js":37}],52:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.YinYang = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _simples = require('../simples/simples.js');

var _complexShape = require('./complexShape.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var YinYang = function (_ComplexShape) {
    _inherits(YinYang, _ComplexShape);

    function YinYang(x, y, width, height) {
        _classCallCheck(this, YinYang);

        var _this = _possibleConstructorReturn(this, (YinYang.__proto__ || Object.getPrototypeOf(YinYang)).call(this, x, y, width, height));

        _this.type = "YinYang";

        _this.topWedge = new _simples.simples.Wedge(x, y, width.percent(50), width.percent(50));
        _this.bottomWedge = new _simples.simples.Wedge(x + width.percent(50), y + width.percent(50), width.percent(50), width.percent(50));
        _this.bottomWedge.rotate(180, _this.bottomWedge.center);

        _this.addShape(_this.topWedge);
        _this.addShape(_this.bottomWedge);

        return _this;
    }

    _createClass(YinYang, [{
        key: 'draw',
        value: function draw(ctx) {
            ctx.beginPath();
            ctx.yMove(this.topWedge.origin);
            ctx.curve(this.topWedge.curve);
            ctx.bezierCurveTo(this.bottomWedge.cp2.x, this.bottomWedge.cp2.y, this.bottomWedge.cp1.x, this.bottomWedge.cp1.y, this.c.x, this.c.y);
            ctx.yLine(this.d);
            ctx.fillStyle = this.color;
            ctx.fill();
            if (this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }]);

    return YinYang;
}(_complexShape.ComplexShape);

exports.YinYang = YinYang;

},{"../simples/simples.js":69,"./complexShape.js":37}],53:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Curve = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _point = require('./point.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Curve = function () {
    function Curve(cp1, cp2, end) {
        _classCallCheck(this, Curve);

        this._cp1 = cp1;
        this._cp2 = cp2;
        this._end = end;
        this._points = [this._cp1, this._cp2, this._end];
    }

    _createClass(Curve, [{
        key: 'points',
        get: function get() {
            return this._points;
        },
        set: function set(points) {
            this._points = points;
        }
    }, {
        key: 'cp1',
        get: function get() {
            return this._cp1;
        },
        set: function set(cp1) {
            this._cp1 = cp1;
        }
    }, {
        key: 'cp2',
        get: function get() {
            return this._cp2;
        },
        set: function set(cp2) {
            this._cp2 = cp2;
        }
    }, {
        key: 'end',
        get: function get() {
            return this._end;
        },
        set: function set(end) {
            this._end = end;
        }
    }]);

    return Curve;
}();

exports.Curve = Curve;

},{"./point.js":63}],54:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DrawInstruction = function () {
    function DrawInstruction(line, rule) {
        _classCallCheck(this, DrawInstruction);

        this._line = line;
        this._rule = rule;
    }

    _createClass(DrawInstruction, [{
        key: "line",
        get: function get() {
            return this._line;
        },
        set: function set(line) {
            this._line = line;
        }
    }, {
        key: "rule",
        get: function get() {
            return this._rule;
        },
        set: function set(rule) {
            this._rule = rule;
        }
    }]);

    return DrawInstruction;
}();

exports.DrawInstruction = DrawInstruction;

},{}],55:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.drawStaticShapes = undefined;

var _shapesregistry = require('./shapesregistry.js');

var shapesRegistry = new _shapesregistry.ShapesRegistry();

function drawStaticShapes() {

    requestAnimationFrame(function () {
        if (shapesRegistry.length) {
            if (!shapesRegistry.blur) {
                shapesRegistry.staticBackgroundCanvas.ctx.clearRect(0, 0, shapesRegistry.staticBackgroundCanvas.width, shapesRegistry.staticBackgroundCanvas.height);
                shapesRegistry.staticForegroundCanvas.ctx.clearRect(0, 0, shapesRegistry.staticForegroundCanvas.width, shapesRegistry.staticForegroundCanvas.height);
            }

            shapesRegistry.staticShapes.forEach(function (shape) {
                if (shape.visible) shape.draw();
            });
        }
    });
}

exports.drawStaticShapes = drawStaticShapes;

},{"./shapesregistry.js":64}],56:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.engine = undefined;

var _shapesregistry = require("./shapesregistry.js");

var _animate = require("./animate.js");

var _drawStaticShapes = require("./drawStaticShapes.js");

var _simples = require("./simples/simples.js");

var _complex = require("./complex/complex.js");

var _client = require("./client/client.js");

var _patterns = require("./patterns/patterns.js");

var _mouseEvents = require("./userInput/mouseEvents.js");

var _keyboardEvents = require("./userInput/keyboardEvents.js");

var _scrollEvents = require("./userInput/scrollEvents.js");

var shapesRegistry = new _shapesregistry.ShapesRegistry();
var level = 0;

var engine = {
  patterns: _patterns.patterns,
  shapesRegistry: shapesRegistry,
  drawStaticShapes: _drawStaticShapes.drawStaticShapes,
  simples: _simples.simples,
  complex: _complex.complex,
  client: _client.client,
  go: go,
  levels: []
};

exports.engine = engine;

//set canvas height, maps keys, calls game setup function, and begins animation.

function go(callback) {
  shapesRegistry.maxShapes = 10000;

  shapesRegistry.allCanvases.forEach(function (canvas) {
    canvas.setSize(window.innerWidth, window.innerHeight);
  });
  window.addEventListener("load", function () {
    callback();
    (0, _drawStaticShapes.drawStaticShapes)();
    (0, _animate.animate)();
  }, false);
}

},{"./animate.js":2,"./client/client.js":7,"./complex/complex.js":36,"./drawStaticShapes.js":55,"./patterns/patterns.js":58,"./shapesregistry.js":64,"./simples/simples.js":69,"./userInput/keyboardEvents.js":75,"./userInput/mouseEvents.js":76,"./userInput/scrollEvents.js":77}],57:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Line = function () {
    function Line(start, end) {
        _classCallCheck(this, Line);

        this._start = start;
        this._end = end;
    }

    _createClass(Line, [{
        key: "createSATObject",
        value: function createSATObject() {
            return [new SAT.Polygon(new SAT.Vector(0, 0), [new SAT.Vector(this.end.x, this.end.y), new SAT.Vector(this.start.x, this.start.y)])];
        }
    }, {
        key: "start",
        get: function get() {
            return this._start;
        },
        set: function set(start) {
            this._start = start;
        }
    }, {
        key: "end",
        get: function get() {
            return this._end;
        },
        set: function set(end) {
            this._end = end;
        }
    }]);

    return Line;
}();

exports.Line = Line;

},{}],58:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.patterns = undefined;

var _randomSpotsOnCircle = require("./randomSpotsOnCircle.js");

var _polkaDots = require("./polkaDots.js");

var _polkaTrapezoids = require("./polkaTrapezoids.js");

var _stripes = require("./stripes.js");

var patterns = {
  randomSpotsOnCircle: _randomSpotsOnCircle.randomSpotsOnCircle,
  polkaDots: _polkaDots.polkaDots,
  polkaTrapezoids: _polkaTrapezoids.polkaTrapezoids,
  stripes: _stripes.stripes,
  argyle: _stripes.argyle
};

exports.patterns = patterns;

},{"./polkaDots.js":59,"./polkaTrapezoids.js":60,"./randomSpotsOnCircle.js":61,"./stripes.js":62}],59:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * A function to add random spots to an object
 * @param  {Sprite} container
 * @param  {Sprite} shape  
 * @param  {Number} density
 * @param  {Number} minSize
 * @param  {Number} maxSize
 * @param  {String} color  
 */
function polkaDots(container, shape, density, minSize, maxSize, color) {

    for (var i = 0; i < density; i++) {
        var randomPoint1 = trees.getPointOnLine(container.a, trees.random(0, container.width), trees.getAngle(container.a, container.b));
        var randomPoint2 = trees.getPointOnLine(randomPoint1, trees.random(0, container.height), trees.getAngle(container.a, container.d));
        var x = randomPoint2.x;
        var y = randomPoint2.y;
        var size = trees.random(minSize, maxSize);
        var dot = new shape(x, y, size, size);
        dot.color = color;
        container.addShape(dot);
    }
}

exports.polkaDots = polkaDots;

},{}],60:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.polkaTrapezoids = undefined;

var _trapezoid = require('../simples/trapezoid.js');

/**
 * A function to add random spots to an object
 * @param  {Sprite} container
 * @param  {Sprite} shape  
 * @param  {Number} density
 * @param  {Number} minSize
 * @param  {Number} maxSize
 * @param  {String} color  
 */
function polkaTrapezoids(container, density, minSize, maxSize, color) {

    for (var i = 0; i < density; i++) {
        var randomPoint1 = trees.getPointOnLine(container.a, trees.random(0, container.width), trees.getAngle(container.a, container.b));
        var randomPoint2 = trees.getPointOnLine(randomPoint1, trees.random(0, container.height), trees.getAngle(container.a, container.d));
        var x = randomPoint2.x;
        var y = randomPoint2.y;
        var size = trees.random(minSize, maxSize);
        var dot = new _trapezoid.Trapezoid(x, y, size, size, trees.random(85, 105), trees.random(85, 105));
        dot.color = color;
        container.addShape(dot);
    }
}

exports.polkaTrapezoids = polkaTrapezoids;

},{"../simples/trapezoid.js":71}],61:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.randomSpotsOnCircle = undefined;

var _simples = require('../simples/simples.js');

function randomSpotsOnCircle(container) {

    var spots = [];

    var _loop = function _loop(i) {
        var location = randomCirclePoint(container.center, container.radius);
        var radius = randomRadius(location, container);
        var spot = new _simples.simples.Circle(location.x, location.y, radius, radius);

        var safe = true;
        spots.forEach(function (s) {

            var spotLeft = spot.center.x - spot.radius;
            var spotRight = spot.center.x + spot.radius;
            var spotTop = spot.center.y - spot.radius;
            var spotBottom = spot.center.y + spot.radius;

            var sLeft = s.center.x - s.radius;
            var sRight = s.center.x + s.radius;
            var sTop = s.center.y - s.radius;
            var sBottom = s.center.y + s.radius;

            if (spotLeft < sRight && spotRight > sLeft && spotTop < sBottom && spotBottom > sTop) {
                safe = false;
            }
        });

        if (safe) {
            spots.push(spot);
        }
    };

    for (var i = 0; i < 20; i++) {
        _loop(i);
    }

    return spots;
}

function randomRadius(location, container) {
    var angle = trees.getAngle(container.center, location);
    var edge = trees.getPointOnLine(container.center, container.radius, angle);
    var max = trees.getDistance(location, edge) / 2;
    var min = container.radius / 6 > max ? max : container.radius / 6;
    return Math.floor(Math.random() * (max - min)) + min;
}

function randomCirclePoint(center, radius) {
    var a = 2 * Math.PI * Math.random();
    var r = Math.sqrt(Math.random());
    var x = radius * r * Math.cos(a) + center.x;
    var y = radius * r * Math.sin(a) + center.y;
    return { x: x, y: y };
}

exports.randomSpotsOnCircle = randomSpotsOnCircle;

},{"../simples/simples.js":69}],62:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.argyle = exports.stripes = undefined;

var _simples = require("../simples/simples.js");

var _complex = require("../complex/complex.js");

var HORIZONTAL = "horizontal";
var VERTICAL = "vertical";
var DIAGONAL = "diagonal";
var REVERSE_DIAGONAL = "reverse-diagonal";

var getRandomColorList = function getRandomColorList() {
  return Array(25).fill().map(trees.randomColor);
};

function argyle(container) {
  return getRandomStripes(container);
}
function getRandomOrientation() {
  return [HORIZONTAL, VERTICAL, DIAGONAL, REVERSE_DIAGONAL][trees.random(0, 3)];
}
function getRandomStripes(container) {
  var stripeSize = container.width.percent(trees.random(1, 20));
  var stripeSpacing = container.width.percent(trees.random(1, 20));
  return stripes(container, stripeSize, stripeSpacing, getRandomColorList(), getRandomOrientation());
}
function stripes(container, stripeSize, stripeSpacing, color, orientation) {
  var stripes = [];
  var numStripes = container.width / (stripeSize + stripeSpacing) * 1.5;
  var totalWidth = numStripes * (stripeSize + stripeSpacing);
  var currentStripe = container.x - (totalWidth - container.width) / 2;

  var _loop = function _loop(i) {
    var stripeHeight = container.height * 1.5;
    var stripeY = container.y - (stripeHeight - container.height) / 2;

    var stripe = new _simples.simples.Rectangle(currentStripe, stripeY, stripeSize, stripeHeight);
    stripe.color = color[i % color.length];
    stripe.draw = function (ctx) {
      ctx = ctx || this.canvas && this.canvas.ctx;

      ctx.save();
      ctx.beginPath();
      container.pathOnly = true;
      container.draw(ctx);
      container.pathOnly = false;
      ctx.clip();
      ctx.closePath();
      ctx.beginPath();
      var rect = {
        a: this.a,
        b: this.b,
        c: this.c,
        d: this.d
      };
      ctx.translate(container.center.x, container.center.y);

      switch (orientation) {
        case "diagonal":
          ctx.rotate(45 * Math.PI / 180);
          break;
        case "reverse-diagonal":
          ctx.rotate(135 * Math.PI / 180);
          break;
        case "horizontal":
          ctx.rotate(Math.PI / 2);
          break;
      }

      ctx.translate(-container.center.x, -container.center.y);
      ctx.yMove(this.a);
      ctx.yRect(rect);
      ctx.fillStyle = color[i % color.length];
      ctx.fill();
      ctx.closePath();
      ctx.restore();
    };

    stripes.push(stripe);
    currentStripe += stripeSize + stripeSpacing;
  };

  for (var i = 0; i < numStripes; i++) {
    _loop(i);
  }

  return stripes;
}

exports.stripes = stripes;
exports.argyle = argyle;

},{"../complex/complex.js":36,"../simples/simples.js":69}],63:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Point = function () {
    function Point(x, y) {
        _classCallCheck(this, Point);

        this._x = x;
        this._y = y;
    }

    _createClass(Point, [{
        key: "x",
        get: function get() {
            return this._x;
        },
        set: function set(x) {
            this._x = x;
        }
    }, {
        key: "y",
        get: function get() {
            return this._y;
        },
        set: function set(y) {
            this._y = y;
        }
    }]);

    return Point;
}();

exports.Point = Point;

},{}],64:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShapesRegistry = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _canvas = require("./canvas.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var instance = null;

var ShapesRegistry = function () {
  function ShapesRegistry() {
    _classCallCheck(this, ShapesRegistry);

    if (!instance) {
      instance = this;
      this._shapes = {};
      this._dynamicShapes = {};
      this._staticShapes = [];
      this._shapeId = 0;
      this._maxShapes = 1000000;
      this._fps = 30;
      this._interval = 1000 / this._fps;
      this.blur = false;
      this._staticBackgroundCanvas = new _canvas.Canvas("staticBackgroundCanvas");
      this._staticForegroundCanvas = new _canvas.Canvas("staticForegroundCanvas");
      this._dynamicBackgroundCanvas = new _canvas.Canvas("dynamicBackgroundCanvas");
      this._dynamicForegroundCanvas = new _canvas.Canvas("dynamicForegroundCanvas");
      this.static = document.URL.split("?")[1] === "static";
      this.cache = [];
    }
    return instance;
  }

  _createClass(ShapesRegistry, [{
    key: "updateCache",
    value: function updateCache() {
      var _this = this;

      this.cache = Object.keys(this._dynamicShapes).map(function (key) {
        return _this._dynamicShapes[key];
      }).sort(function (a, b) {
        return a.height - b.height;
      });
    }
  }, {
    key: "addToStaticBackground",
    value: function addToStaticBackground(shape) {
      var _this2 = this;

      this.add(shape);
      if (!Array.isArray(shape)) {
        this.staticShapes.push(shape);
      } else {
        shape.forEach(function (s) {
          return _this2.staticShapes.push(s);
        });
      }
      shape.canvas = this.staticBackgroundCanvas;
    }
  }, {
    key: "addToStaticForeground",
    value: function addToStaticForeground(shape) {
      var _this3 = this;

      this.add(shape);
      if (!Array.isArray(shape)) {
        this.staticShapes.push(shape);
      } else {
        shape.forEach(function (s) {
          return _this3.staticShapes.push(s);
        });
      }
      shape.canvas = this.staticForegroundCanvas;
    }
  }, {
    key: "addToDynamicBackground",
    value: function addToDynamicBackground(shape) {
      var _this4 = this;

      this.add(shape);
      if (!Array.isArray(shape)) {
        this.dynamicShapes[shape.id] = shape;
      } else {
        shape.forEach(function (s) {
          return _this4.dynamicShapes[s.id] = s;
        });
      }
      shape.canvas = this.dynamicBackgroundCanvas;
      this.updateCache();
    }
  }, {
    key: "removeFromDynamicBackground",
    value: function removeFromDynamicBackground(shape) {
      var _this5 = this;

      this.remove(shape);
      if (!Array.isArray(shape)) {
        delete this.dynamicShapes[shape.id];
      } else {
        shape.forEach(function (s) {
          return delete _this5.dynamicShapes[s.id];
        });
      }
      this.updateCache();
    }
  }, {
    key: "addToDynamicForeground",
    value: function addToDynamicForeground(shape) {
      var _this6 = this;

      this.add(shape);
      if (!Array.isArray(shape)) {
        this.dynamicShapes[shape.id] = shape;
      } else {
        shape.forEach(function (s) {
          return _this6.dynamicShapes[s.id] = s;
        });
      }
      shape.canvas = this.dynamicForegroundCanvas;
      this.updateCache();
    }
  }, {
    key: "forEach",
    value: function forEach(callback) {
      var _this7 = this;

      Object.keys(this.shapes).forEach(function (key) {
        var obj = _this7.shapes[key];
        if (obj) {
          callback(obj);
        }
      });
    }
  }, {
    key: "add",
    value: function add(shape) {
      var _this8 = this;

      if (Array.isArray(shape)) {
        shape.forEach(function (obj) {
          addShape.call(_this8, obj);
        });
      } else {
        addShape.call(this, shape);
      }

      function addShape(s) {
        s.id = this.shapeId;
        this.shapeId++;
        if (this.length < this.maxShapes) {
          this._shapes[s.id] = s;
        }
        if (!s.canvas) s.canvas = this.staticBackgroundCanvas;
      }
    }
  }, {
    key: "remove",
    value: function remove(shape) {
      var shapesRegistry = this;
      setTimeout(function () {
        delete shapesRegistry._shapes[shape.id];
      }, 0);
    }
  }, {
    key: "reset",
    value: function reset() {
      this.shapeId = 0;
      this.shapes = {};
    }
  }, {
    key: "staticBackgroundCanvas",
    get: function get() {
      return this._staticBackgroundCanvas;
    },
    set: function set(staticBackgroundCanvas) {
      this._staticBackgroundCanvas = staticBackgroundCanvas;
    }
  }, {
    key: "dynamicBackgroundCanvas",
    get: function get() {
      return this._dynamicBackgroundCanvas;
    },
    set: function set(dynamicBackgroundCanvas) {
      this._dynamicBackgroundCanvas = dynamicBackgroundCanvas;
    }
  }, {
    key: "staticForegroundCanvas",
    get: function get() {
      return this._staticForegroundCanvas;
    },
    set: function set(staticForegroundCanvas) {
      this._staticForegroundCanvas = staticForegroundCanvas;
    }
  }, {
    key: "dynamicForegroundCanvas",
    get: function get() {
      return this._dynamicForegroundCanvas;
    },
    set: function set(dynamicForegroundCanvas) {
      this._dynamicBackgroundCanvas = dynamicBackgroundCanvas;
    }
  }, {
    key: "allCanvases",
    get: function get() {
      return [this.staticBackgroundCanvas, this.dynamicBackgroundCanvas, this.staticForegroundCanvas, this.dynamicForegroundCanvas];
    }
  }, {
    key: "shapes",
    get: function get() {
      return this._shapes;
    },
    set: function set(shapes) {
      this._shapes = shapes;
    }
  }, {
    key: "dynamicShapes",
    get: function get() {
      return this._dynamicShapes;
    },
    set: function set(dynamicShapes) {
      this._dynamicShapes = dynamicShapes;
    }
  }, {
    key: "staticShapes",
    get: function get() {
      return this._staticShapes;
    },
    set: function set(staticShapes) {
      this._staticShapes = staticShapes;
    }
  }, {
    key: "shapeId",
    get: function get() {
      return this._shapeId;
    },
    set: function set(id) {
      this._shapeId = id;
    }
  }, {
    key: "maxShapes",
    get: function get() {
      return this._maxShapes;
    },
    set: function set(n) {
      this._maxShapes = n;
    }
  }, {
    key: "length",
    get: function get() {
      return Object.keys(this.shapes).length;
    }
  }]);

  return ShapesRegistry;
}();

exports.ShapesRegistry = ShapesRegistry;

},{"./canvas.js":3}],65:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Circle = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _sprite = require("../sprite.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Circle = function (_Sprite) {
    _inherits(Circle, _Sprite);

    function Circle(x, y, width, height) {
        _classCallCheck(this, Circle);

        var _this = _possibleConstructorReturn(this, (Circle.__proto__ || Object.getPrototypeOf(Circle)).call(this, x, y, width, height));

        _this.type = "Circle";
        _this._radius = width / 2;
        _this.startAngle = 0;
        _this.endAngle = 2;
        return _this;
    }

    _createClass(Circle, [{
        key: "createSATObject",
        value: function createSATObject() {
            return [new SAT.Circle(new SAT.Vector(this.center.x, this.center.y), this.width / 2)];
        }
    }, {
        key: "draw",
        value: function draw(ctx) {
            ctx = ctx || this.canvas && this.canvas.ctx;

            _get(Circle.prototype.__proto__ || Object.getPrototypeOf(Circle.prototype), "draw", this).call(this, ctx);

            ctx.beginPath();

            ctx.arc(this.center.x, this.center.y, this.radius, this.startAngle, this.endAngle);

            if (!this.pathOnly) ctx.fill();
            if (!this.pathOnly && this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }, {
        key: "radius",
        get: function get() {
            return this._radius;
        },
        set: function set(radius) {
            this._radius = radius;
        }
    }, {
        key: "width",
        get: function get() {
            return _get(Circle.prototype.__proto__ || Object.getPrototypeOf(Circle.prototype), "width", this);
        },
        set: function set(width) {
            _set(Circle.prototype.__proto__ || Object.getPrototypeOf(Circle.prototype), "width", width, this);
            this.radius = width / 2;
        }
    }, {
        key: "height",
        get: function get() {
            return _get(Circle.prototype.__proto__ || Object.getPrototypeOf(Circle.prototype), "height", this);
        },
        set: function set(height) {
            _set(Circle.prototype.__proto__ || Object.getPrototypeOf(Circle.prototype), "height", height, this);
            //this.radius = height/2;
        }
    }, {
        key: "startAngle",
        get: function get() {
            return this._startAngle;
        },
        set: function set(startAngle) {
            this._startAngle = startAngle * Math.PI;
            //this.radius = height/2;
        }
    }, {
        key: "endAngle",
        get: function get() {
            return this._endAngle;
        },
        set: function set(endAngle) {
            this._endAngle = endAngle * Math.PI;
            //this.radius = height/2;
        }
    }]);

    return Circle;
}(_sprite.Sprite);

exports.Circle = Circle;

},{"../sprite.js":74}],66:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Polygon = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _sprite = require('../sprite.js');

var _point = require('../point.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Polygon = function (_Sprite) {
    _inherits(Polygon, _Sprite);

    function Polygon(x, y, width, height, sides) {
        _classCallCheck(this, Polygon);

        var _this = _possibleConstructorReturn(this, (Polygon.__proto__ || Object.getPrototypeOf(Polygon)).call(this, x, y, width, height));

        _this.type = "Polygon";
        _this._sides = sides;
        _this._points = [];
        _this._radius = _this.width / 2;
        _this.updatePolygon();
        return _this;
    }

    _createClass(Polygon, [{
        key: 'rotate',
        value: function rotate(deg, transformOrigin) {
            _get(Polygon.prototype.__proto__ || Object.getPrototypeOf(Polygon.prototype), 'rotate', this).call(this, deg, transformOrigin);
            this.points = this.points.map(function (point) {
                return trees.rotatePoint(point, transformOrigin, deg);
            });
        }
    }, {
        key: '_updatePoints',
        value: function _updatePoints() {
            var oldOrigin = this.origin;
            _get(Polygon.prototype.__proto__ || Object.getPrototypeOf(Polygon.prototype), '_updatePoints', this).call(this);
            if (!this.points) this.updatePolygon();

            var xDiff = this.origin.x - oldOrigin.x;
            var yDiff = this.origin.y - oldOrigin.y;

            this.points = this.points.map(function (point) {
                return new _point.Point(point.x + xDiff, point.y + yDiff);
            });
        }
    }, {
        key: 'updatePolygon',
        value: function updatePolygon() {
            this._points = [];

            var startingPoint = trees.getPointOnLine(this.a, this.width / 2, trees.getAngle(this.a, this.b));
            var a = Math.acos((startingPoint.x - this.center.x) / this.radius);

            for (var i = 0; i < this.sides; i++) {
                var x = this.center.x + this.radius * Math.cos(a + 2 * Math.PI * i / this.sides);
                var y = this.center.y + this.radius * Math.sin(a + 2 * Math.PI * i / this.sides);
                this._points[i] = new _point.Point(x, y);
            }
        }
    }, {
        key: 'createSATObject',
        value: function createSATObject() {
            var _this2 = this;

            var result = [];
            this.points.forEach(function (point) {
                result.push(new SAT.Vector(point.x - _this2.x, point.y - _this2.y));
            });
            return [new SAT.Polygon(new SAT.Vector(this.x, this.y), result)];
        }
    }, {
        key: 'draw',
        value: function draw(ctx) {
            ctx = ctx || this.canvas && this.canvas.ctx;
            _get(Polygon.prototype.__proto__ || Object.getPrototypeOf(Polygon.prototype), 'draw', this).call(this, ctx);
            var rect = {
                a: this.a,
                b: this.b,
                c: this.c,
                d: this.d
            };

            if (this.points.length) {
                ctx.beginPath();
                ctx.yMove(this.points[0]);

                this.points.forEach(function (point, index) {
                    ctx.yLine(point);
                });
                ctx.yLine(this.points[0]);

                if (this.lineColor) ctx.stroke();
                ctx.closePath();
                ctx.fill();
            }
        }
    }, {
        key: 'sides',
        get: function get() {
            return this._sides;
        },
        set: function set(sides) {
            this._sides = sides;
            this.updatePolygon();
        }
    }, {
        key: 'points',
        get: function get() {
            return this._points;
        },
        set: function set(points) {
            this._points = points;
        }
    }, {
        key: 'radius',
        get: function get() {
            return this._radius;
        },
        set: function set(radius) {
            this._radius = radius;
        }
    }, {
        key: 'width',
        get: function get() {
            return _get(Polygon.prototype.__proto__ || Object.getPrototypeOf(Polygon.prototype), 'width', this);
        },
        set: function set(width) {
            _set(Polygon.prototype.__proto__ || Object.getPrototypeOf(Polygon.prototype), 'width', width, this);
            this._radius = width / 2;
            this.updatePolygon();
        }
    }]);

    return Polygon;
}(_sprite.Sprite);

exports.Polygon = Polygon;

},{"../point.js":63,"../sprite.js":74}],67:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Rectangle = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _sprite = require('../sprite.js');

var _drawInstruction = require('../drawInstruction.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Rectangle = function (_Sprite) {
    _inherits(Rectangle, _Sprite);

    function Rectangle(x, y, width, height, angle) {
        _classCallCheck(this, Rectangle);

        var _this = _possibleConstructorReturn(this, (Rectangle.__proto__ || Object.getPrototypeOf(Rectangle)).call(this, x, y, width, height, angle));

        _this.type = "Rectangle";

        var lines = _this.lines();
        lines.forEach(function (line) {
            _this.drawingInstructions.push(new _drawInstruction.DrawInstruction(line));
        });
        return _this;
    }

    _createClass(Rectangle, [{
        key: 'createSATObject',
        value: function createSATObject() {
            return [new SAT.Polygon(new SAT.Vector(this.x, this.y), [new SAT.Vector(this.a.x - this.x, this.a.y - this.y), new SAT.Vector(this.b.x - this.x, this.b.y - this.y), new SAT.Vector(this.c.x - this.x, this.c.y - this.y), new SAT.Vector(this.d.x - this.x, this.d.y - this.y)])];
        }
    }, {
        key: 'draw',
        value: function draw(ctx) {
            ctx = ctx || this.canvas && this.canvas.ctx;
            _get(Rectangle.prototype.__proto__ || Object.getPrototypeOf(Rectangle.prototype), 'draw', this).call(this, ctx);
            var rect = {
                a: this.a,
                b: this.b,
                c: this.c,
                d: this.d
            };

            ctx.beginPath();
            ctx.yMove(this.a);
            ctx.yRect(rect);
            if (!this.pathOnly) ctx.fill();
            if (!this.pathOnly && this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }]);

    return Rectangle;
}(_sprite.Sprite);

exports.Rectangle = Rectangle;

},{"../drawInstruction.js":54,"../sprite.js":74}],68:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.SemiCircle = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _sprite = require("../sprite.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SemiCircle = function (_Sprite) {
    _inherits(SemiCircle, _Sprite);

    function SemiCircle(x, y, width, height, angle) {
        _classCallCheck(this, SemiCircle);

        var _this = _possibleConstructorReturn(this, (SemiCircle.__proto__ || Object.getPrototypeOf(SemiCircle)).call(this, x, y, width, height, angle));

        _this.type = "SemiCircle";
        _this._radius = width / 2;

        return _this;
    }

    _createClass(SemiCircle, [{
        key: "createSATObject",
        value: function createSATObject() {
            return [new SAT.Circle(new SAT.Vector(this.center.x, this.center.y), this.radius)];
        }
    }, {
        key: "draw",
        value: function draw(ctx) {
            _get(SemiCircle.prototype.__proto__ || Object.getPrototypeOf(SemiCircle.prototype), "draw", this).call(this, ctx);

            ctx.beginPath();

            ctx.arc(this.center.x, this.center.y, this.radius, 1 * Math.PI, 0);

            ctx.fill();
            if (this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }, {
        key: "radius",
        get: function get() {
            return this._radius;
        },
        set: function set(radius) {
            this._radius = radius;
        }
    }, {
        key: "width",
        get: function get() {
            return _get(SemiCircle.prototype.__proto__ || Object.getPrototypeOf(SemiCircle.prototype), "width", this);
        },
        set: function set(width) {
            _set(SemiCircle.prototype.__proto__ || Object.getPrototypeOf(SemiCircle.prototype), "width", width, this);
            this.radius = width / 2;
        }
    }, {
        key: "height",
        get: function get() {
            return _get(SemiCircle.prototype.__proto__ || Object.getPrototypeOf(SemiCircle.prototype), "height", this);
        },
        set: function set(height) {
            _set(SemiCircle.prototype.__proto__ || Object.getPrototypeOf(SemiCircle.prototype), "height", height, this);
            //this.radius = height/2;
        }
    }]);

    return SemiCircle;
}(_sprite.Sprite);

exports.SemiCircle = SemiCircle;

},{"../sprite.js":74}],69:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.simples = undefined;

var _rectangle = require('./rectangle.js');

var _triangle = require('./triangle.js');

var _circle = require('./circle.js');

var _polygon = require('./polygon.js');

var _semiCircle = require('./semiCircle.js');

var _wedge = require('./wedge.js');

var _trapezoid = require('./trapezoid.js');

var _text = require('./text.js');

var simples = {
    Rectangle: _rectangle.Rectangle,
    Triangle: _triangle.Triangle,
    Circle: _circle.Circle,
    Polygon: _polygon.Polygon,
    SemiCircle: _semiCircle.SemiCircle,
    Wedge: _wedge.Wedge,
    Trapezoid: _trapezoid.Trapezoid,
    Text: _text.Text
};

exports.simples = simples;

},{"./circle.js":65,"./polygon.js":66,"./rectangle.js":67,"./semiCircle.js":68,"./text.js":70,"./trapezoid.js":71,"./triangle.js":72,"./wedge.js":73}],70:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Text = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _sprite = require('../sprite.js');

var _shapesregistry = require('../shapesregistry.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var shapesRegistry = new _shapesregistry.ShapesRegistry();

var Text = function (_Sprite) {
    _inherits(Text, _Sprite);

    function Text(text, x, y, size, font) {
        _classCallCheck(this, Text);

        var _this = _possibleConstructorReturn(this, (Text.__proto__ || Object.getPrototypeOf(Text)).call(this, x, y, size, size));

        _this.type = "Text";
        _this._value = text;
        _this._size = size;
        _this._fontName = font;
        _this._font = size + "px " + font;
        return _this;
    }

    _createClass(Text, [{
        key: 'draw',
        value: function draw(ctx) {
            var _this2 = this;

            ctx = ctx || this.canvas && this.canvas.ctx;
            _get(Text.prototype.__proto__ || Object.getPrototypeOf(Text.prototype), 'draw', this).call(this, ctx);
            ctx.font = this.font;
            ctx.textBaseline = "hanging";
            ctx.textAlign = "center";
            if (Array.isArray(this.color)) {
                var letters = this.value.split("");
                var x = this.x;
                letters.forEach(function (letter, index) {
                    var color = void 0;
                    if (index <= _this2.color.length - 1) {
                        color = _this2.color[index];
                    } else {
                        color = _this2.color[index % _this2.color.length];
                    }
                    ctx.fillStyle = color;
                    ctx.fillText(letter, x, _this2.y + index * 20);
                    x += ctx.measureText(letter).width;
                });
            } else {
                ctx.fillText(this.value, this.x, this.y);
            }
        }
    }, {
        key: 'getWidth',
        get: function get() {
            return shapesRegistry.canvas.measureText(this.value, this.font);
        }
    }, {
        key: 'value',
        get: function get() {
            return this._value;
        },
        set: function set(value) {
            this._value = value;
        }
    }, {
        key: 'fontName',
        get: function get() {
            return this._fontName;
        },
        set: function set(fontName) {
            this._fontName = fontName;
        }
    }, {
        key: 'font',
        get: function get() {
            return this._font;
        },
        set: function set(font) {
            this.fontName = font;
            this._font = this.size + "px " + this.fontName;
        }
    }, {
        key: 'size',
        get: function get() {
            return this._size;
        },
        set: function set(size) {
            this._size = size;
            this._font = size + "px " + this.fontName;
        }
    }]);

    return Text;
}(_sprite.Sprite);

exports.Text = Text;

},{"../shapesregistry.js":64,"../sprite.js":74}],71:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Trapezoid = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _point = require("../point.js");

var _line = require("../line.js");

var _sprite = require("../sprite.js");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Trapezoid = function (_Sprite) {
  _inherits(Trapezoid, _Sprite);

  function Trapezoid(x, y, width, height, leftAngle, rightAngle) {
    _classCallCheck(this, Trapezoid);

    var _this = _possibleConstructorReturn(this, (Trapezoid.__proto__ || Object.getPrototypeOf(Trapezoid)).call(this, x, y, width, height));

    _this.type = "Trapezoid";

    if (typeof leftAngle !== "number") {
      throw new Error("No angle for trapezoid was supplied.");
    }

    if (typeof rightAngle !== "number") {
      rightAngle = leftAngle;
    }

    //left angle is the degree of the top left corner.
    //right angle is the degree of the top right corner.

    _this._leftAngle = leftAngle;
    _this._rightAngle = rightAngle;
    _this._setAngles();

    if (_this.topLeft.x > _this.topRight.x || _this.bottomLeft.x > _this.bottomRight.x || _this.topLeft.y > _this.bottomLeft.y || _this.topRight.y > _this.bottomRight.y) {
      throw new Error("Parameters do not define trapezoid.");
    }
    //this.showBoundingBox = true;
    return _this;
  }

  _createClass(Trapezoid, [{
    key: "animate",
    value: function animate() {
      // if (!this.collidingWithPlatform) this.fall();
    }
  }, {
    key: "flipVertical",
    value: function flipVertical() {
      this.rotate(90, this.center);
    }
  }, {
    key: "lines",
    value: function lines() {
      return [new _line.Line(this.topLeft, this.topRight), new _line.Line(this.topRight, this.bottomRight), new _line.Line(this.bottomRight, this.bottomLeft), new _line.Line(this.bottomLeft, this.topLeft)];
    }
  }, {
    key: "getSideLength",
    value: function getSideLength(angle, height) {
      var radians = trees.degToRad(180 - angle);
      return height / Math.sin(radians);
    }
  }, {
    key: "rotate",
    value: function rotate(deg, transformOrigin) {
      _get(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "rotate", this).call(this, deg, transformOrigin);
      if (this.topLeft) {
        this.topLeft = trees.rotatePoint(this.topLeft, transformOrigin, deg);
        this.topRight = trees.rotatePoint(this.topRight, transformOrigin, deg);
        this.bottomLeft = trees.rotatePoint(this.bottomLeft, transformOrigin, deg);
        this.bottomRight = trees.rotatePoint(this.bottomRight, transformOrigin, deg);
      }
    }
  }, {
    key: "trimTop",
    value: function trimTop(amount) {
      //main concern with this function is it does not adjust the width as trapezoid scales
      //therefore, we should be careful when collision testing, if that becomes necessary.
      var oldHeight = this.height;
      var oldLeftHypotenuse = this.getSideLength(this.leftAngle, oldHeight);
      var oldRightHypotenuse = this.getSideLength(this.rightAngle, oldHeight);

      var bottomLeft = trees.copyPoint(this.bottomLeft);
      var bottomRight = trees.copyPoint(this.bottomRight);
      var topRight = trees.copyPoint(this.topRight);
      var topLeft = trees.copyPoint(this.topLeft);

      _get(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "trimTop", this).call(this, amount);

      var newLeftHypotenuse = this.getSideLength(this.leftAngle, this.height);
      var newRightHypotenuse = this.getSideLength(this.rightAngle, this.height);

      this.topLeft = trees.getPointOnLine(topLeft, oldLeftHypotenuse - newLeftHypotenuse, trees.getAngle(this.topLeft, this.bottomLeft));
      this.topRight = trees.getPointOnLine(topRight, oldRightHypotenuse - newRightHypotenuse, trees.getAngle(this.topRight, this.bottomRight));

      this.bottomLeft = bottomLeft;
      this.bottomRight = bottomRight;
    }
  }, {
    key: "growTop",
    value: function growTop(amount) {
      this.trimTop(-amount);
    }
  }, {
    key: "_setAngles",
    value: function _setAngles() {
      if (this.leftAngle < 90) {
        this._topLeft = this.a;
        this._bottomLeft = trees.getPointOnLine(this.a, this.getSideLength(this.leftAngle, this.height), this.leftAngle);
      } else {
        this._topLeft = trees.getPointOnLine(this.d, -this.getSideLength(this.leftAngle, this.height), this.leftAngle);
        this._bottomLeft = this.d;
      }

      if (this.rightAngle < 90) {
        this._topRight = this.b;
        this._bottomRight = trees.getPointOnLine(this.b, this.getSideLength(this.rightAngle, this.height), 180 - this.rightAngle);
      } else {
        this._topRight = trees.getPointOnLine(this.c, -this.getSideLength(this.rightAngle, this.height), 180 - this.rightAngle);
        this._bottomRight = this.c;
      }
    }
  }, {
    key: "draw",
    value: function draw(ctx) {
      _get(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "draw", this).call(this, ctx);

      ctx.beginPath();
      ctx.yMove(this.topLeft);
      ctx.yLine(this.bottomLeft);
      ctx.yLine(this.bottomRight);
      ctx.yLine(this.topRight);
      ctx.yLine(this.topLeft);

      if (!this.pathOnly) ctx.fill();
      if (!this.pathOnly && this.lineColor) ctx.stroke();
      ctx.closePath();
    }
  }, {
    key: "x",
    set: function set(x) {
      var oldX = this.x;
      var diffX = x - oldX;
      _set(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "x", x, this);
      this.topLeft.x += diffX;
      this.topRight.x += diffX;
      this.bottomLeft.x += diffX;
      this.bottomRight.x += diffX;
    },
    get: function get() {
      return _get(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "x", this);
    }
  }, {
    key: "y",
    set: function set(y) {
      var oldY = this.y;
      var diffY = y - oldY;
      _set(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "y", y, this);
      this.topLeft.y += diffY;
      this.topRight.y += diffY;
      this.bottomLeft.y += diffY;
      this.bottomRight.y += diffY;
    },
    get: function get() {
      return _get(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "y", this);
    }
  }, {
    key: "width",
    get: function get() {
      return _get(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "width", this);
    },
    set: function set(width) {
      _set(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "width", width, this);
    }
  }, {
    key: "height",
    get: function get() {
      return _get(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "height", this);
    },
    set: function set(height) {
      _set(Trapezoid.prototype.__proto__ || Object.getPrototypeOf(Trapezoid.prototype), "height", height, this);
    }
  }, {
    key: "area",
    get: function get() {
      return 0.5 * (this.b1 + this.b2) * this.height;
    }
  }, {
    key: "b1",
    get: function get() {
      return this.topRight.x - this.topLeft.x;
    }
  }, {
    key: "b2",
    get: function get() {
      return this.bottomRight.x - this.bottomLeft.x;
    }
  }, {
    key: "bottomLeft",
    get: function get() {
      return this._bottomLeft;
    },
    set: function set(bottomLeft) {
      this._bottomLeft = bottomLeft;
    }
  }, {
    key: "bottomRight",
    get: function get() {
      return this._bottomRight;
    },
    set: function set(bottomRight) {
      this._bottomRight = bottomRight;
    }
  }, {
    key: "topLeft",
    get: function get() {
      return this._topLeft;
    },
    set: function set(topLeft) {
      this._topLeft = topLeft;
    }
  }, {
    key: "topRight",
    get: function get() {
      return this._topRight;
    },
    set: function set(topRight) {
      this._topRight = topRight;
    }
  }, {
    key: "leftAngle",
    get: function get() {
      return this._leftAngle;
    },
    set: function set(leftAngle) {
      this._leftAngle = leftAngle;
    }
  }, {
    key: "rightAngle",
    get: function get() {
      return this._rightAngle;
    },
    set: function set(rightAngle) {
      this._rightAngle = rightAngle;
    }
  }]);

  return Trapezoid;
}(_sprite.Sprite);

exports.Trapezoid = Trapezoid;

},{"../line.js":57,"../point.js":63,"../sprite.js":74}],72:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Triangle = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _sprite = require('../sprite.js');

var _point = require('../point.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Triangle = function (_Sprite) {
    _inherits(Triangle, _Sprite);

    function Triangle(x, y, width, height, angle) {
        _classCallCheck(this, Triangle);

        var _this = _possibleConstructorReturn(this, (Triangle.__proto__ || Object.getPrototypeOf(Triangle)).call(this, x, y, width, height, angle));

        _this.type = "Triangle";
        _this.ta = new _point.Point(_this.d.x, _this.d.y);
        _this.tb = new _point.Point(_this.c.x, _this.c.y);
        _this.tc = trees.getPointOnLine(_this.a, _this.width / 2, trees.getAngle(_this.a, _this.b));
        return _this;
    }

    _createClass(Triangle, [{
        key: 'rotate',
        value: function rotate(deg, transformOrigin) {
            _get(Triangle.prototype.__proto__ || Object.getPrototypeOf(Triangle.prototype), 'rotate', this).call(this, deg, transformOrigin);
            this.ta = trees.rotatePoint(this.ta, transformOrigin, deg);
            this.tb = trees.rotatePoint(this.tb, transformOrigin, deg);
            this.tc = trees.rotatePoint(this.tc, transformOrigin, deg);
        }
    }, {
        key: '_updatePoints',
        value: function _updatePoints() {
            var oldOrigin = this.origin;
            _get(Triangle.prototype.__proto__ || Object.getPrototypeOf(Triangle.prototype), '_updatePoints', this).call(this);

            var xDiff = this.origin.x - oldOrigin.x;
            var yDiff = this.origin.y - oldOrigin.y;
            if (!this.ta) {
                this.ta = new _point.Point(this.d.x, this.d.y);
                this.tb = new _point.Point(this.c.x, this.c.y);
                this.tc = trees.getPointOnLine(this.a, this.width / 2, trees.getAngle(this.a, this.b));
            }
            this.ta = new _point.Point(this.ta.x + xDiff, this.ta.y + yDiff);
            this.tb = new _point.Point(this.tb.x + xDiff, this.tb.y + yDiff);
            this.tc = new _point.Point(this.tc.x + xDiff, this.tc.y + yDiff);
        }
    }, {
        key: 'createSATObject',
        value: function createSATObject() {
            return [new SAT.Polygon(new SAT.Vector(this.x, this.y), [new SAT.Vector(this.ta.x - this.x, this.ta.y - this.y), new SAT.Vector(this.tc.x - this.x, this.tc.y - this.y), new SAT.Vector(this.tb.x - this.x, this.tb.y - this.y)])];
        }
    }, {
        key: 'draw',
        value: function draw(ctx) {
            _get(Triangle.prototype.__proto__ || Object.getPrototypeOf(Triangle.prototype), 'draw', this).call(this, ctx);
            ctx.beginPath();
            ctx.moveTo(this.ta.x, this.ta.y);
            ctx.lineTo(this.tb.x, this.tb.y);
            ctx.lineTo(this.tc.x, this.tc.y);
            ctx.lineTo(this.ta.x, this.ta.y);
            if (!this.pathOnly) ctx.fill();
            if (!this.pathOnly && this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }, {
        key: 'width',
        get: function get() {
            return _get(Triangle.prototype.__proto__ || Object.getPrototypeOf(Triangle.prototype), 'width', this);
        },
        set: function set(width) {
            _set(Triangle.prototype.__proto__ || Object.getPrototypeOf(Triangle.prototype), 'width', width, this);
            this.tb = new _point.Point(this.c.x, this.c.y);
            this.tc = trees.getPointOnLine(this.a, trees.getDistance(this.a, this.b) / 2, trees.getAngle(this.a, this.b));
        }
    }, {
        key: 'height',
        get: function get() {
            return _get(Triangle.prototype.__proto__ || Object.getPrototypeOf(Triangle.prototype), 'height', this);
        },
        set: function set(height) {
            _set(Triangle.prototype.__proto__ || Object.getPrototypeOf(Triangle.prototype), 'height', height, this);
            this.ta = new _point.Point(this.d.x, this.d.y);
            this.tb = new _point.Point(this.c.x, this.c.y);
        }
    }, {
        key: 'points',
        get: function get() {
            return [this.ta, this.tb, this.tc];
        },
        set: function set(arr) {
            if (!Array.isArray(arr) || arr.length < 3) {
                throw new Error("Triangle points property expects array with three Point objects");
            }
            this.ta = arr[0];
            this.tb = arr[1];
            this.tc = arr[2];
        }
    }]);

    return Triangle;
}(_sprite.Sprite);

exports.Triangle = Triangle;

},{"../point.js":63,"../sprite.js":74}],73:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Wedge = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _set = function set(object, property, value, receiver) { var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent !== null) { set(parent, property, value, receiver); } } else if ("value" in desc && desc.writable) { desc.value = value; } else { var setter = desc.set; if (setter !== undefined) { setter.call(receiver, value); } } return value; };

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _point = require('../point.js');

var _sprite = require('../sprite.js');

var _curve = require('../curve.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function getBezierDistance(n) {
    return n / 2 * 0.552284749831;
}

var Wedge = function (_Sprite) {
    _inherits(Wedge, _Sprite);

    function Wedge(x, y, width, height) {
        _classCallCheck(this, Wedge);

        // Here, it calls the parent class' constructor with lengths
        // provided for the Polygon's width and height
        return _possibleConstructorReturn(this, (Wedge.__proto__ || Object.getPrototypeOf(Wedge)).call(this, x, y, width, height));
    }

    _createClass(Wedge, [{
        key: '_updatePoints',
        value: function _updatePoints() {
            var oldOrigin = this.origin;
            _get(Wedge.prototype.__proto__ || Object.getPrototypeOf(Wedge.prototype), '_updatePoints', this).call(this);

            var xDiff = this.origin.x - oldOrigin.x;
            var yDiff = this.origin.y - oldOrigin.y;
            if (!this.cp1) {
                this._cp1 = new _point.Point(this.x + getBezierDistance(this.width * 2), this.y);
                this._cp2 = new _point.Point(this.b.x, this.c.y - getBezierDistance(this.height * 2));
                this._end = new _point.Point(this.c.x, this.c.y);
            }
            this.cp1 = new _point.Point(this.cp1.x + xDiff, this.cp1.y + yDiff);
            this.cp2 = new _point.Point(this.cp2.x + xDiff, this.cp2.y + yDiff);
            this.end = new _point.Point(this.end.x + xDiff, this.end.y + yDiff);

            this._curve = new _curve.Curve(this._cp1, this._cp2, this._end);
        }
    }, {
        key: 'rotate',
        value: function rotate(deg, transformOrigin) {
            _get(Wedge.prototype.__proto__ || Object.getPrototypeOf(Wedge.prototype), 'rotate', this).call(this, deg, transformOrigin);
            this.cp1 = trees.rotatePoint(this.cp1, transformOrigin, deg);
            this.cp2 = trees.rotatePoint(this.cp2, transformOrigin, deg);
            this.end = trees.rotatePoint(this.end, transformOrigin, deg);
            this.curve = new _curve.Curve(this.cp1, this.cp2, this.end);
        }
    }, {
        key: 'getReverseCurve',
        value: function getReverseCurve() {
            return new _curve.Curve(this.cp2, this.cp1, this.a);
        }
    }, {
        key: 'createSATObject',
        value: function createSATObject() {
            return [];
        }
    }, {
        key: 'draw',
        value: function draw(ctx) {
            _get(Wedge.prototype.__proto__ || Object.getPrototypeOf(Wedge.prototype), 'draw', this).call(this, ctx);
            ctx.beginPath();
            ctx.yMove(this.origin);
            ctx.curve(this.curve);
            ctx.yLine(this.d);
            ctx.yLine(this.a);
            ctx.fill();
            if (this.lineColor) ctx.stroke();
            ctx.closePath();
        }
    }, {
        key: 'width',
        get: function get() {
            return _get(Wedge.prototype.__proto__ || Object.getPrototypeOf(Wedge.prototype), 'width', this);
        },
        set: function set(width) {
            _set(Wedge.prototype.__proto__ || Object.getPrototypeOf(Wedge.prototype), 'width', width, this);
            this.cp1 = trees.getPointOnLine(this.a, getBezierDistance(this.width * 2), trees.getAngle(this.a, this.b));
            this.cp2 = trees.getPointOnLine(this.c, getBezierDistance(-this.height * 2), trees.getAngle(this.b, this.c));
            this.end = new _point.Point(this.c.x, this.c.y);
            this._curve = new _curve.Curve(this._cp1, this._cp2, this._end);
        }
    }, {
        key: 'height',
        get: function get() {
            return _get(Wedge.prototype.__proto__ || Object.getPrototypeOf(Wedge.prototype), 'height', this);
        },
        set: function set(height) {
            _set(Wedge.prototype.__proto__ || Object.getPrototypeOf(Wedge.prototype), 'height', height, this);
            this.cp1 = trees.getPointOnLine(this.a, getBezierDistance(this.width * 2), trees.getAngle(this.a, this.b));
            this.cp2 = trees.getPointOnLine(this.c, getBezierDistance(-this.height * 2), trees.getAngle(this.b, this.c));
            this.end = new _point.Point(this.c.x, this.c.y);
            this._curve = new _curve.Curve(this._cp1, this._cp2, this._end);
        }
    }, {
        key: 'cp1',
        get: function get() {
            return this._cp1;
        },
        set: function set(cp1) {
            this._cp1 = cp1;
        }
    }, {
        key: 'cp2',
        get: function get() {
            return this._cp2;
        },
        set: function set(cp2) {
            this._cp2 = cp2;
        }
    }, {
        key: 'end',
        get: function get() {
            return this._end;
        },
        set: function set(end) {
            this._end = end;
        }
    }, {
        key: 'curve',
        get: function get() {
            return this._curve;
        },
        set: function set(curve) {
            this._curve = curve;
        }
    }]);

    return Wedge;
}(_sprite.Sprite);

exports.Wedge = Wedge;

},{"../curve.js":53,"../point.js":63,"../sprite.js":74}],74:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Sprite = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _point = require('./point.js');

var _line = require('./line.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Sprite = function () {
    function Sprite(x, y, width, height) {
        _classCallCheck(this, Sprite);

        this._width = width || 0;
        this._height = height || 0;
        this._x = x || 0;
        this._y = y || 0;
        this._origin = new _point.Point(this.x, this.y);
        this._a = new _point.Point(this.x, this.y);
        this._b = new _point.Point(this.x + this.width, this.y);
        this._c = new _point.Point(this.x + this.width, this.y + this.height);
        this._d = new _point.Point(this.x, this.y + this.height);
        this._boundary = {};
        this._center = new _point.Point(this.x + this.width / 2, this.y + this.height / 2);
        this._updatePoints();
        this._lineWidth = 1;
        this._showBoundingBox = false;
        this._color = "transparent";
        this._lineColor = null;
        this._id = null;
        this._collidingWith = null;
        this._collidable = false;
        this._minHeight = 1;
        this._visible = true;
        this._pathOnly = false;
        this._drawingInstructions = [];
    }

    _createClass(Sprite, [{
        key: 'rotate',
        value: function rotate(deg, transformOrigin) {
            if (typeof deg !== "number" || typeof transformOrigin.x !== "number" || typeof transformOrigin.y !== "number") {
                throw new Error('Attempted to rotate using non-numeric value');
            }
            this._origin = trees.rotatePoint(this.origin, transformOrigin, deg);
            this._x = this.origin.x;
            this._y = this.origin.y;
            this.a = trees.rotatePoint(this.a, transformOrigin, deg);
            this.b = trees.rotatePoint(this.b, transformOrigin, deg);
            this.c = trees.rotatePoint(this.c, transformOrigin, deg);
            this.d = trees.rotatePoint(this.d, transformOrigin, deg);
            this.center = trees.rotatePoint(this.center, transformOrigin, deg);

            this._updateBoundaries();
        }
    }, {
        key: 'trimTop',
        value: function trimTop(amount) {
            amount = this.height - amount > this._minHeight ? amount : this.height - this._minHeight;
            this._height -= amount;
            var angle = trees.getAngle(this.a, this.d);
            var newOrigin = trees.getPointOnLine(this.a, amount, angle);

            this.x = newOrigin.x;
            this.y = newOrigin.y;
            this.c = trees.getPointOnLine(this.c, -amount, angle);
            this.d = trees.getPointOnLine(this.d, -amount, angle);

            this.center = trees.getPointOnLine(this.a, trees.getDistance(this.a, this.c) / 2, trees.getAngle(this.a, this.c));
            this._updateBoundaries();
        }
    }, {
        key: 'growTop',
        value: function growTop(amount) {
            this._height += amount;
            var angle = trees.getAngle(this.a, this.d);
            var newOrigin = trees.getPointOnLine(this.a, -amount, angle);
            this.x = newOrigin.x;
            this.y = newOrigin.y;
            this.c = trees.getPointOnLine(this.c, amount, angle);
            this.d = trees.getPointOnLine(this.d, amount, angle);
            this.center = trees.getPointOnLine(this.a, trees.getDistance(this.a, this.c) / 2, trees.getAngle(this.a, this.c));
            this._updateBoundaries();
        }
    }, {
        key: 'lines',
        value: function lines() {
            return [new _line.Line(this.a, this.b), new _line.Line(this.b, this.c), new _line.Line(this.c, this.d), new _line.Line(this.d, this.a)];
        }
    }, {
        key: 'wasClicked',
        value: function wasClicked(mouseX, mouseY) {
            if (this.boundary.a.x <= mouseX && this.boundary.b.x >= mouseX && this.boundary.a.y * 0.9 <= mouseY && this.boundary.d.y * 1.1 >= mouseY) {
                return this;
            }
            return null;
        }
    }, {
        key: '_updatePoints',
        value: function _updatePoints() {

            var oldOrigin = this.origin;
            this.origin = new _point.Point(this.x, this.y);
            var xDiff = this.origin.x - oldOrigin.x;
            var yDiff = this.origin.y - oldOrigin.y;
            this.center = new _point.Point(this.center.x + xDiff, this.center.y + yDiff);

            this.a = new _point.Point(this.a.x + xDiff, this.a.y + yDiff);
            this.b = new _point.Point(this.b.x + xDiff, this.b.y + yDiff);
            this.c = new _point.Point(this.c.x + xDiff, this.c.y + yDiff);
            this.d = new _point.Point(this.d.x + xDiff, this.d.y + yDiff);

            this._updateBoundaries();
        }
    }, {
        key: '_updateBoundaries',
        value: function _updateBoundaries() {
            var lowestX = Math.min(this.a.x, this.b.x, this.c.x, this.d.x);
            var highestX = Math.max(this.a.x, this.b.x, this.c.x, this.d.x);
            var lowestY = Math.min(this.a.y, this.b.y, this.c.y, this.d.y);
            var highestY = Math.max(this.a.y, this.b.y, this.c.y, this.d.y);
            var boundaryW = highestX - lowestX;
            var boundaryH = highestY - lowestY;

            this.boundary.a = new _point.Point(lowestX, lowestY);
            this.boundary.b = new _point.Point(lowestX + boundaryW, lowestY);
            this.boundary.c = new _point.Point(lowestX + boundaryW, lowestY + boundaryH);
            this.boundary.d = new _point.Point(lowestX, lowestY + boundaryH);
        }
    }, {
        key: 'draw',
        value: function draw(ctx) {
            ctx = ctx || this.canvas && this.canvas.ctx;

            if (!ctx || !ctx.beginPath || !ctx.closePath) {
                throw new Error("Attempted to draw without supplying context");
            }
            if (this.showBoundingBox) {
                ctx.beginPath();
                ctx.fillStyle = "red";
                ctx.lineWidth = 1;
                var size = 5;
                ctx.rect(this.x - size / 2, this.y - size / 2, size, size);
                ctx.rect(this.a.x - size / 2, this.a.y - size / 2, size, size);
                ctx.rect(this.center.x - size / 2, this.center.y - size / 2, size, size);
                ctx.fill();
                ctx.closePath();

                ctx.fillStyle = "transparent";

                ctx.strokeStyle = "blue";
                ctx.beginPath();
                ctx.yRect(this.boundary);
                ctx.fill();
                ctx.stroke();
                ctx.closePath();

                ctx.strokeStyle = "green";
                ctx.beginPath();
                ctx.yRect({
                    a: this.a,
                    b: this.b,
                    c: this.c,
                    d: this.d
                });
                ctx.fill();
                ctx.stroke();
                ctx.closePath();
            } else {

                ctx.lineJoin = 'miter';
                ctx.fillStyle = this.color;
                ctx.strokeStyle = this.lineColor;
                ctx.lineWidth = this.lineWidth;
            }
        }
    }, {
        key: 'x',
        set: function set(x) {
            if (typeof x !== "number") {
                throw new Error("x must be a number.");
            };
            this._x = x;
            this._updatePoints();
        },
        get: function get() {
            return this._x;
        }
    }, {
        key: 'y',
        set: function set(y) {
            if (typeof y !== "number") {
                throw new Error("y must be a number.");
            };
            this._y = y;
            this._updatePoints();
        },
        get: function get() {
            return this._y;
        }
    }, {
        key: 'center',
        get: function get() {
            return this._center;
        },
        set: function set(center) {
            this._center = center;
        }
    }, {
        key: 'origin',
        get: function get() {
            return this._origin;
        },
        set: function set(origin) {
            this._origin = origin;
        }
    }, {
        key: 'id',
        set: function set(id) {
            this._id = id;
        },
        get: function get() {
            return this._id;
        }
    }, {
        key: 'a',
        set: function set(obj) {
            this._a = obj;
        },
        get: function get() {
            return this._a;
        }
    }, {
        key: 'b',
        set: function set(obj) {
            this._b = obj;
        },
        get: function get() {
            return this._b;
        }
    }, {
        key: 'c',
        set: function set(obj) {
            this._c = obj;
        },
        get: function get() {
            return this._c;
        }
    }, {
        key: 'd',
        set: function set(obj) {
            this._d = obj;
        },
        get: function get() {
            return this._d;
        }
    }, {
        key: 'width',
        get: function get() {
            return this._width;
        },
        set: function set(width) {
            if (typeof width !== "number") {
                throw new Error("width must be a number.");
            };
            var oldWidth = this.width;
            this._width = width;
            var widthDiff = this.width - oldWidth;

            var angle = trees.getAngle(this.a, this.b);
            this.b = trees.getPointOnLine(this.b, widthDiff, angle);
            this.c = trees.getPointOnLine(this.c, widthDiff, angle);

            this.center = trees.getPointOnLine(this.a, trees.getDistance(this.a, this.c) / 2, trees.getAngle(this.a, this.c));
            this._updateBoundaries();
        }
    }, {
        key: 'height',
        get: function get() {
            return this._height;
        },
        set: function set(height) {
            if (typeof height !== "number") {
                throw new Error("height must be a number.");
            };
            var oldHeight = this.height;
            this._height = height;
            var heightDiff = this.height - oldHeight;
            var angle = trees.getAngle(this.a, this.d);

            this.c = trees.getPointOnLine(this.c, heightDiff, angle);
            this.d = trees.getPointOnLine(this.d, heightDiff, angle);

            this.center = trees.getPointOnLine(this.a, trees.getDistance(this.a, this.c) / 2, trees.getAngle(this.a, this.c));
            this._updateBoundaries();
        }
    }, {
        key: 'showBoundingBox',
        set: function set(bool) {
            this._showBoundingBox = bool;
        },
        get: function get() {
            return this._showBoundingBox;
        }
    }, {
        key: 'collidable',
        set: function set(collidable) {
            this._collidable = collidable;
        },
        get: function get() {
            return this._collidable;
        }
    }, {
        key: 'color',
        set: function set(color) {
            this._color = color;
        },
        get: function get() {
            return this._color;
        }
    }, {
        key: 'lineColor',
        set: function set(color) {
            this._lineColor = color;
        },
        get: function get() {
            return this._lineColor;
        }
    }, {
        key: 'lineWidth',
        set: function set(width) {
            this._lineWidth = width;
        },
        get: function get() {
            return this._lineWidth;
        }
    }, {
        key: 'boundary',
        get: function get() {
            return this._boundary;
        },
        set: function set(boundary) {
            this._boundary = boundary;
        }
    }, {
        key: 'collidingWith',
        get: function get() {
            return this._collidingWith;
        },
        set: function set(collidingWith) {
            this._collidingWith = collidingWith;
        }
    }, {
        key: 'visible',
        get: function get() {
            return this._visible;
        },
        set: function set(visible) {
            this._visible = visible;
        }
    }, {
        key: 'pathOnly',
        get: function get() {
            return this._pathOnly;
        },
        set: function set(pathOnly) {
            this._pathOnly = pathOnly;
        }
    }, {
        key: 'drawingInstructions',
        get: function get() {
            return this._drawingInstructions;
        },
        set: function set(drawingInstructions) {
            this._drawingInstructions = drawingInstructions;
        }
    }]);

    return Sprite;
}();

exports.Sprite = Sprite;

},{"./line.js":57,"./point.js":63}],75:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.keyboardEvents = undefined;

var _canvas = require('../canvas.js');

var _shapesregistry = require('../shapesregistry.js');

var keyboardEvents = {
    initialize: initialize
};

var shapes = new _shapesregistry.ShapesRegistry();
var boundingBoxes = false;
var i = 0;

var nowScrolling = false;

function initialize() {
    document.onkeydown = mapKeys;
}

function toggleBoundingBoxes() {
    boundingBoxes = !boundingBoxes;
    shapes.forEach(function (shape) {
        shape.showBoundingBox = boundingBoxes;
    });
}

function scrollUp() {

    nowScrolling = true;

    if (i < shapes.staticBackgroundCanvas.height.percent(2)) {
        shapes.allCanvases.forEach(function (canvas) {
            canvas.scroll(-3);
        });
        i++;
        setTimeout(scrollUp, 5);
    } else {
        nowScrolling = false;
    }
}

function scrollDown() {
    nowScrolling = true;
    if (i < shapes.staticBackgroundCanvas.height.percent(2)) {
        shapes.allCanvases.forEach(function (canvas) {
            canvas.scroll(3);
        });
        i++;
        setTimeout(scrollDown, 5);
    } else {
        nowScrolling = false;
    }
}

function mapKeys(e) {
    e = e || window.event;
    var downArrow = new CustomEvent('downArrow', {});
    var upArrow = new CustomEvent('upArrow', {});

    switch (e.keyCode) {
        case 32:
            // space
            toggleBoundingBoxes();
            break;
        case 38:
            shapes.allCanvases.forEach(function (canvas) {
                canvas.dispatchEvent(downArrow);
            });
            break;
        case 40:
            // down
            shapes.allCanvases.forEach(function (canvas) {
                canvas.dispatchEvent(upArrow);
            });
            break;
        default:
            return;
    }
    e.preventDefault();
};

exports.keyboardEvents = keyboardEvents;

},{"../canvas.js":3,"../shapesregistry.js":64}],76:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.mouseEvents = undefined;

var _canvas = require('../canvas.js');

var _shapesregistry = require('../shapesregistry.js');

var mouseEvents = {
    initialize: initialize
};

var shapesRegistry = new _shapesregistry.ShapesRegistry();
var clickedShape = null;
var mouse = { x: null, y: null };
var prevMouse = { x: null, y: null };
var direction = { left: false, right: false, up: false, down: false };
var mouseClick = null;
var mouseMove = null;

function initialize() {
    window.addEventListener("mousedown", clickObject);
    window.addEventListener("mouseup", releaseObject);
}

function clickObject(e) {
    // let bRect = shapesRegistry.canvas.getBoundingClientRect();
    // mouse.x = (e.clientX - bRect.left) * (shapesRegistry.canvas.width / bRect.width);
    // mouse.y = (e.clientY - bRect.top) * (shapesRegistry.canvas.height / bRect.height);

    // shapesRegistry.forEach(shape => {
    //     let clicked = shape.wasClicked(mouse.x, mouse.y);

    //     if (clicked) {

    //         mouseClick = new CustomEvent('mouseClick', {
    //             detail: { shape: clicked }
    //         });

    //         shapesRegistry.canvas.dispatchEvent(mouseClick);
    //         window.addEventListener("mousemove", dragObject, false)
    //     }
    // });
}

function dragObject(e) {
    // let bRect = shapesRegistry.canvas.getBoundingClientRect();
    // mouse.x = (e.clientX - bRect.left) * (shapesRegistry.canvas.width / bRect.width);
    // mouse.y = (e.clientY - bRect.top) * (shapesRegistry.canvas.height / bRect.height);

    // clickedShape = null;

    // shapesRegistry.forEach(shape => {
    //     clickedShape = clickedShape || shape.wasClicked(mouse.x, mouse.y);
    // });

    // if (prevMouse.x) {

    //     mouseMove = new CustomEvent('mouseMove', {
    //         detail: { direction: direction, mouse: mouse, shape: clickedShape }
    //     });

    //     direction.left = prevMouse.x > mouse.x;
    //     direction.right = prevMouse.x < mouse.x;
    //     direction.up = prevMouse.y > mouse.y;
    //     direction.down = prevMouse.y < mouse.y;
    //     shapesRegistry.canvas.dispatchEvent(mouseMove);

    // }

    // prevMouse.x = mouse.x;
    // prevMouse.y = mouse.y;


}

function releaseObject() {
    window.removeEventListener("mousemove", dragObject, false);
}

exports.mouseEvents = mouseEvents;

},{"../canvas.js":3,"../shapesregistry.js":64}],77:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.scrollEvents = undefined;

var _shapesregistry = require('../shapesregistry.js');

var scrollEvents = {
    initialize: initialize
};
var shapes = new _shapesregistry.ShapesRegistry();

function initialize() {

    var nowScrolling = false;
    var last_known_scroll_position = 0;
    var ticking = false;

    window.addEventListener('scroll', function (e) {
        last_known_scroll_position = window.scrollY;
        if (!ticking) {
            window.requestAnimationFrame(function () {
                shapes.allCanvases.forEach(function (canvas) {
                    canvas.currentY = last_known_scroll_position;
                    canvas.scroll(0);
                });
                document.getElementById("main").style.marginTop = -last_known_scroll_position + "px";
                ticking = false;
            });
        }
        ticking = true;
    });
}

exports.scrollEvents = scrollEvents;

},{"../shapesregistry.js":64}],78:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.level0 = undefined;

var _bird = require("../engine/client/bird.js");

var _stripedBalloon = require("../engine/client/stripedBalloon.js");

var _tree = require("../engine/client/tree.js");

var _balloon = require("../engine/complex/balloon.js");

var _engine = require("../engine/engine.js");

var shapes = _engine.engine.shapesRegistry;
var Width = void 0;
var Height = void 0;

var skyHeight = void 0;
var lakeHeight = void 0;
var earthHeight = void 0;
var caveHeight = void 0;

var BROWN = "#190D03";
var BLUE = "lightblue";
var GREEN = "springgreen";
var DARKPURPLE = "#1A001A";
var LIGHTPURPLE = "#44355B";
var PINK = "pink";
var WHITE = "white";
var BLACK = "#333333";
var GRAY = "gray";
var YELLOW = "gold";
var OLIVE = "#666633";

var backgroundColors = [GREEN, BLUE, YELLOW, PINK];

function level0() {
  Width = shapes.staticBackgroundCanvas.width;
  Height = shapes.staticBackgroundCanvas.height;

  skyHeight = Height.percent(100);
  //   lakeHeight = Height.percent(20);
  //   earthHeight = Height.percent(10);
  //   caveHeight = Height.percent(30);

  shapes.staticBackgroundCanvas.element.style.backgroundColor = backgroundColors[trees.random(0, backgroundColors.length - 1)];

  StripedBalloons();
  Mountains();
  Clouds();
  for (var i = 0; i < 5; i++) {
    newBird(i);
  }
  var counter = 0;

  document.body.addEventListener("click", function (e) {
    if (counter < 10) StripedBalloons(e.clientX, e.clientY);
    counter++;
  });

  //   Wheel();
  // Cave();
  //   Lake();
  //   Crystals();
  //   GleamingCrystals();
  //   WaterFall();
}

function StripedBalloons(x, y) {
  var numballoons = 1;
  for (var i = 0; i < numballoons; i++) {
    newBalloon(x, y);
  }
}

function newBalloon(x, y) {
  var size = trees.random(30, 100);
  y = y ? y - size / 2 : Height.percent(trees.random(15, 65));
  x = x ? x - size / 2 : -size;

  var balloon = new _engine.engine.client.FancyBalloon(x, y, size, size);
  balloon.color = trees.randomColor();
  var drift = size / 150;
  balloon.callback = function () {
    this.x += drift;
    // if ((Date.now() + this.id) % 2 === 0) {
    //   this.x += Math.random() < 0.5 ? -0.1 : 0.1;
    // }
    // this.y += drift;
    this.y = y + size / 2 * Math.sin(this.x / (2 * size));
    if (this.x > Width.percent(100) + size) {
      shapes.removeFromDynamicBackground(this);
      newBalloon();
    }
  };
  shapes.addToDynamicBackground(balloon);
}

function newBird(i) {
  var tree = new _bird.Bird(Width.percent(trees.random(110, 110 - i)), Height.percent(trees.random(80, 80 - i)), 10, 10);
  var speed = trees.random(2, 3);
  tree.color = "#333333";
  tree.callback = function () {
    this.x -= speed;
    // if ((Date.now() + this.id) % 2 === 0) {
    //   this.x -= Math.random() < 0.5 ? -0.5 : 0.5;
    // }
    this.y += speed / 10;
    if (this.x < 0 - 10) {
      shapes.removeFromDynamicBackground(this);
      newBird(i);
    }
  };
  // tree.lineColor = "red";
  shapes.addToDynamicBackground(tree);
}

function Clouds() {
  for (var i = 0; i < 10; i++) {
    var width = Width.percent(trees.random(10, 25));
    var x = Width.percent(i * 10);
    var y = trees.random(Height.percent(30), Height.percent(80));
    var height = width / 4;
    var cloud = new _engine.engine.client.Cloud(x, y, width, height);
    var opacity = 1 - width / 400;
    cloud.color = trees.setOpacity("beige", opacity);
    shapes.addToStaticForeground(cloud);
  }
}

function Mountains() {
  var width = Width.percent(120);
  var height = Height.percent(25);
  var x = Width.percent(-10);
  var y = skyHeight - height;
  var mountain = new _engine.engine.client.Mountains(x, y, width, height);
  shapes.addToStaticBackground(mountain);

  //   engine.patterns.polkaDots(mountain, engine.simples.Circle, 100, 1, 5, YELLOW);
}

function Wheel() {
  var width = Width.percent(32.3);
  var height = width;
  var x = Width.percent(15);
  var y = skyHeight - height / 2;

  var wheel = new _engine.engine.client.WaterWheel(x, y, width, height);

  wheel.callback = function () {
    wheel.rotate(0.5, wheel.center);
  };
  shapes.addToDynamicBackground(wheel);
}

function Crystals() {
  for (var i = 0; i < 50; i++) {
    var x = trees.random(0, Width);
    var y = trees.random(skyHeight + lakeHeight + earthHeight, skyHeight + lakeHeight + earthHeight + caveHeight);
    var height = trees.random(Width.percent(0.1), Width.percent(1));
    var width = height;

    var crystal = new _engine.engine.client.Crystal(x, y, width, height);
    crystal.color = trees.randomColor();
    crystal.rotate(trees.random(0, 180), crystal.center);
    shapes.addToStaticBackground(crystal);
  }
}

function GleamingCrystals() {
  for (var i = 0; i < 3; i++) {
    var x = trees.random(0, Width);
    var y = trees.random(skyHeight + lakeHeight + earthHeight, skyHeight + lakeHeight + earthHeight + caveHeight.percent(60));
    var height = trees.random(Width.percent(2), Width.percent(4));
    var width = height / 2;

    var gleamingCrystal = new _engine.engine.client.GleamingCrystal(x, y, width, height);
    gleamingCrystal.color = trees.randomColor();
    gleamingCrystal.stripeWidth = gleamingCrystal.width.percent(10);
    gleamingCrystal.stripeSpacing = gleamingCrystal.width.percent(90);
    gleamingCrystal.stripeColor = [trees.setOpacity(WHITE, 0.5)];
    gleamingCrystal.stripeOrientation = "vertical";
    gleamingCrystal.rotate(trees.random(0, 180), gleamingCrystal.center);
    shapes.addToStaticBackground(gleamingCrystal);
  }
}

function Lake() {
  var water = new _engine.engine.complex.Box(0, skyHeight, Width, lakeHeight);
  var lake = new _engine.engine.client.Lake(0, skyHeight, Width, lakeHeight);
  var earth = new _engine.engine.complex.Box(0, skyHeight + lakeHeight, Width, earthHeight);
  earth.color = BROWN;
  water.color = BLUE;
  lake.color = GREEN;

  _engine.engine.patterns.polkaDots(earth, _engine.engine.simples.Circle, 100, 1, 5, PINK);
  new _engine.engine.complex.RockyBorder(earth, Width.percent(3), earth.lines()[2]);
  new _engine.engine.complex.RockyBorder(lake, Width.percent(2), lake.lines()[2]);

  shapes.addToStaticForeground(earth);
  shapes.addToStaticForeground(water);
  shapes.addToStaticForeground(lake);
}

function Cave() {
  var caveBackground = new _engine.engine.complex.Box(0, skyHeight + lakeHeight + earthHeight, Width, caveHeight);
  var cave = new _engine.engine.client.Cave(0, skyHeight + lakeHeight + earthHeight, Width, caveHeight);
  var cavePool = new _engine.engine.complex.Box(0, skyHeight + lakeHeight + earthHeight + caveHeight - Height.percent(8), Width, Height.percent(8));

  caveBackground.color = DARKPURPLE;
  cave.color = LIGHTPURPLE;
  cavePool.color = BLUE;

  cave.shape.forEach(function (shape) {
    if (shape.type === "Box") _engine.engine.patterns.polkaTrapezoids(shape, 10, 1, 5, GRAY);
  });

  shapes.addToStaticBackground(caveBackground);
  shapes.addToStaticForeground(cavePool);
  shapes.addToStaticForeground(cave);
}

exports.level0 = level0;

},{"../engine/client/bird.js":5,"../engine/client/stripedBalloon.js":23,"../engine/client/tree.js":25,"../engine/complex/balloon.js":28,"../engine/engine.js":56}],79:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.level2 = undefined;

var _engine = require('../engine/engine.js');

var shapes = _engine.engine.shapesRegistry;

function createBubble() {
    var size = trees.random(10, 30);
    var thickness = trees.random(1, 6);
    var times = trees.random(-1, 1);
    var box = new _engine.engine.complex.Arch(trees.random(1, shapes.staticBackgroundCanvas.width), -size, size, size / 2, thickness);
    box.rotate(trees.random(0, 360), box.center);
    box.color = trees.randomColor();
    box.ySpeed = size / 10;
    box.callback = function () {
        this.y += this.ySpeed;
        this.rotate(this.ySpeed * times, this.center);
    };
    box.collidable = false;
    shapes.addToDynamicBackground(box);
}

function level2() {
    _engine.engine.timer = setInterval(createBubble, 100);
}

exports.level2 = level2;

},{"../engine/engine.js":56}],80:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.level3 = undefined;

var _engine = require("../engine/engine.js");

var shapes = _engine.engine.shapesRegistry;

function level3() {
    var width = shapes.staticBackgroundCanvas.width;
    var height = shapes.staticBackgroundCanvas.height;
    var x = 0;
    var y = 0;
    var box = new _engine.engine.complex.Box(x, y, width, height);
    box.radius = width;
    box.color = "black";

    _engine.engine.patterns.randomSpotsOnCircle(box).forEach(function (spot) {
        spot.color = "black";
        spot.collidable = false;
        box.addShape(spot);
    });
    var i = 0;
    _engine.engine.patterns.polkaDots(box, _engine.engine.simples.Circle, 100, 1, 5, "white");
    box.callback = function () {
        this.width++;
        this.height++;
        this.x -= 0.5;
        this.y -= 0.5;
    };
    shapes.addToDynamicBackground(box);
}

exports.level3 = level3;

},{"../engine/engine.js":56}],81:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.level4 = undefined;

var _engine = require("../engine/engine.js");

function level4() {
    var shapes = _engine.engine.shapesRegistry;
    var octopus = new _engine.engine.client.Octopus(300, 300, 300, 300);
    octopus.color = "black";
    shapes.addToDynamicBackground(octopus);
}

exports.level4 = level4;

},{"../engine/engine.js":56}],82:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.level5 = undefined;

var _engine = require("../engine/engine.js");

var shapes = _engine.engine.shapesRegistry;

function createDot(x) {
    var dot = new _engine.engine.simples.Circle(shapes.staticBackgroundCanvas.width / 2, shapes.staticBackgroundCanvas.height / 2, 1, 1);
    dot.color = "black";
    dot.xSpeed = 16;
    dot.ySpeed = 16;
    dot.collidable = false;
    var expanding = true;
    var done = false;
    dot.callback = function () {

        if (expanding) {
            this.width += this.xSpeed;
            this.height += this.ySpeed;
            this.y -= this.ySpeed / 2;
            this.x -= this.xSpeed / 2;
            if (this.radius > shapes.staticBackgroundCanvas.width) expanding = false;
        }
        if (!expanding) {
            this.width -= this.xSpeed;
            this.height -= this.ySpeed;
            this.y += this.ySpeed / 2;
            this.x += this.xSpeed / 2;
            if (this.width <= dot.xSpeed || this.height <= dot.ySpeed) expanding = true;
        }
        done = true;
        return done;
    };
    shapes.addToDynamicBackground(dot);
}

function level5() {
    createDot();
}

exports.level5 = level5;

},{"../engine/engine.js":56}],83:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.level6 = undefined;

var _engine = require('../engine/engine.js');

function level6() {
    var shapes = _engine.engine.shapesRegistry;

    var width = 50;
    var offsetFactor = width * -.12;
    var wOffset = 0;
    var hOffset = 0;
    var start = 0;

    var extraRows = width / -offsetFactor;

    var hexRows = shapes.staticBackgroundCanvas.height / width + extraRows;
    var hexColumns = shapes.staticBackgroundCanvas.width / width + extraRows;

    for (var n = 0; n < hexRows; n++) {

        wOffset = 0;
        hOffset += offsetFactor * 2;

        for (var i = 0; i < hexColumns; i++) {

            wOffset += offsetFactor;

            start = n % 2 ? width / 2 + offsetFactor / 2 : 0;

            var hex = new _engine.engine.simples.Polygon(i * width + wOffset - start, n * width + hOffset, width, width, 6);
            hex.color = trees.randomColor();
            //hex.lineColor = "black";
            hex.ySpeed = 3;
            shapes.addToDynamicBackground(hex);
        }
    }

    var length = shapes.length;
    _engine.engine.timer = setInterval(dropHex, 10);

    function dropHex() {
        var id = trees.random(0, length - 1);
        var shape = shapes.shapes[id];
        if (shape && !shape.called) {
            shape.callback = function () {
                this.y -= this.ySpeed;
                this.ySpeed *= 1.01;
                this.rotate(1, this.center);
            };
            shape.called = true;
        }
    }
}

exports.level6 = level6;

},{"../engine/engine.js":56}],84:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.level7 = undefined;

var _engine = require("../engine/engine.js");

function level7() {
    var shapes = _engine.engine.shapesRegistry;

    var wheel = new _engine.engine.client.WaterWheel(300, 300, 300, 300);
    // wheel.color = "black";
    shapes.addToDynamicBackground(wheel);

    var rect = new _engine.engine.simples.Rectangle(0, 600, shapes.staticBackgroundCanvas.width, shapes.staticBackgroundCanvas.height - 600);
    rect.color = "blue";
    shapes.addToDynamicBackground(rect);

    wheel.callback = function () {
        this.rotate(0.4, this.center);
        this.shape.forEach(function (shape) {
            if (shape.type === "Cup" && shape.y + shape.height > 620) {
                shape.fill(1);
            }
        });
    };
}

exports.level7 = level7;

},{"../engine/engine.js":56}],85:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.level8 = undefined;

var _engine = require("../engine/engine.js");

var shapes = _engine.engine.shapesRegistry;

function level8() {

    for (var i = 0; i < 20; i++) {
        shapes.addToDynamicBackground(createHose());
    }
}

function createHose() {

    var randomX = trees.random(0, shapes.staticBackgroundCanvas.width);
    var randomY = trees.random(0, shapes.staticBackgroundCanvas.height);
    var randomWidth = trees.random(5, shapes.staticBackgroundCanvas.width / 2);
    var randomHeight = randomWidth / trees.random(2, 100);
    var randomAngle = trees.random(0, 360);

    var hose = new _engine.engine.complex.Hose(randomX, randomY, 700, 10, randomAngle);

    hose.color = "brown"; //trees.randomColor();
    hose.sectionColor = "tan"; //trees.randomColor();

    var randSection = trees.random(1, hose.length);
    var randBend = trees.posNeg() * trees.random(1, 180);
    var randSectionLength = trees.random(5, hose.length - randSection);
    var sectionLengthCounter = 1;

    var sectionCounter = 0;
    var bendCounter = 0;
    var bendCounterInc = randBend < 0 ? -1 : 1;
    var sectionCounterInc = 1;
    var sectionLengthInc = 1;

    hose.callback = function () {
        if (sectionCounter !== randSection && sectionLengthCounter !== randSectionLength) {

            if (sectionCounter !== randSection) {
                hose.selectSection(hose.shape[sectionCounter]);
                sectionCounter += sectionCounterInc;
            }

            if (sectionLengthCounter !== randSectionLength) {
                hose.sectionLength = sectionLengthCounter;
                sectionLengthCounter += sectionLengthInc;
            }
        } else {
            if (bendCounter !== randBend) {
                hose.bend(bendCounterInc);
                bendCounter += bendCounterInc;
            } else {
                randSection = trees.random(1, hose.length);
                randBend = trees.posNeg() * trees.random(1, 180);
                randSectionLength = trees.random(5, hose.length - randSection);

                bendCounterInc = randBend < 0 ? -1 : 1;
                sectionCounterInc = randSection > sectionCounter ? 1 : -1;
                sectionLengthInc = randSectionLength > sectionLengthCounter ? 1 : -1;
                bendCounter = 0;
            }
        }
    };
    return hose;
}

exports.level8 = level8;

},{"../engine/engine.js":56}],86:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.level9 = undefined;

var _engine = require('../engine/engine.js');

var hose = void 0;
var cup = void 0;
var testTube = void 0;
var erlenmeyer = void 0;
var counter = 1;

function level9() {
    var shapes = _engine.engine.shapesRegistry;
    hose = createHose();
    cup = createCup();
    erlenmeyer = createErlenmeyer();
    testTube = createTestTube();
    //shapes.addToDynamicForeground(hose);

    shapes.addToDynamicForeground(cup);
    shapes.addToDynamicForeground(erlenmeyer);
    shapes.addToDynamicForeground(testTube);

    // hose.callback = function() {
    //     if (cup.empty) {
    //         hose.drain();
    //     } else {
    //         hose.fill();
    //     }
    // };

    cup.callback = function () {}
    //cup.rotate(1, cup.center);
    //cup.y+= 3
    // if (hose.full && !cup.empty) {
    //    cup.fill(1);
    //     hose.rotate(1, cup.center);
    //     spray.rotate(1, cup.center);
    // }


    // erlenmeyer.callback = function() {
    //     //this.x++;
    //     //this.drain(counter);
    //     //this.fill(counter);
    //     //this.rotate(-1, this.center)
    // }

    ;shapes.allCanvases.forEach(function (canvas) {
        canvas.addEventListener('upArrow', function (e) {
            hose.bend(-1);
            erlenmeyer.rotate(-1, erlenmeyer.center);
            cup.rotate(-1, cup.center);
            testTube.rotate(-1, testTube.center);
        });
        canvas.addEventListener('downArrow', function (e) {
            hose.bend(1);
            erlenmeyer.rotate(1, erlenmeyer.center);
            cup.rotate(1, cup.center);
            testTube.rotate(1, testTube.center);
        });
    });
}

function createCup() {
    var cup = new _engine.engine.client.Cup(200, 100, 200, 300, 85);
    cup.color = trees.setOpacity("white", 0.2);
    cup.liquidColor = trees.setOpacity("orange", 0.9);
    cup.level = 90;
    cup.thickness = 10;
    return cup;
}

function createTestTube() {
    var testTube = new _engine.engine.client.TestTube(600, 100, 200, 300);
    testTube.color = trees.setOpacity("white", 0.2);
    testTube.liquidColor = trees.setOpacity("orange", 0.9);
    testTube.level = 50;
    testTube.thickness = 10;
    testTube.lip.thickness = 20;
    return testTube;
}

function createErlenmeyer() {
    var erlenmeyer = new _engine.engine.client.Erlenmeyer(900, 300, 200, 300, 85);
    erlenmeyer.color = trees.setOpacity("white", 0.2);
    erlenmeyer.liquidColor = trees.setOpacity("orange", 0.9);
    erlenmeyer.level = 90;
    erlenmeyer.thickness = 10;
    return erlenmeyer;
}

function createHose() {

    var hose = new _engine.engine.complex.Hose(200, 344, 500, 10);
    hose.rotate(-85, hose.center);
    hose.color = trees.setOpacity("lightPink", 0.3); //trees.randomColor();
    hose.sectionColor = trees.setOpacity("pink", 0.3); //trees.randomColor();

    hose.selectSection(hose.shape[35]);
    hose.bend(110);

    return hose;
}

exports.level9 = level9;

},{"../engine/engine.js":56}],87:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.scenes = undefined;

var _level = require('./level0.js');

var _level2 = require('./level2.js');

var _level3 = require('./level3.js');

var _level4 = require('./level4.js');

var _level5 = require('./level5.js');

var _level6 = require('./level6.js');

var _level7 = require('./level7.js');

var _level8 = require('./level8.js');

var _level9 = require('./level9.js');

var scenes = {
	level0: _level.level0,
	level2: _level2.level2,
	level3: _level3.level3,
	level4: _level4.level4,
	level5: _level5.level5,
	level6: _level6.level6,
	level7: _level7.level7,
	level8: _level8.level8,
	level9: _level9.level9
};

exports.scenes = scenes;

},{"./level0.js":78,"./level2.js":79,"./level3.js":80,"./level4.js":81,"./level5.js":82,"./level6.js":83,"./level7.js":84,"./level8.js":85,"./level9.js":86}],88:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.trees = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _point = require('./engine/point.js');

var _line = require('./engine/line.js');

var _engine = require('./engine/engine.js');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * A SMALL utilities library using Underscore-like statics.
 * @return {Object}
 */

var d = void 0;

Number.prototype.percent = function (percentage) {
    return this.valueOf() * percentage / 100;
};

Array.prototype.move = function (old_index, new_index) {
    if (new_index >= this.length) {
        var k = new_index - this.length;
        while (k-- + 1) {
            this.push(undefined);
        }
    }
    this.splice(new_index, 0, this.splice(old_index, 1)[0]);
};

var trees = function () {
    function trees() {
        _classCallCheck(this, trees);
    }

    _createClass(trees, null, [{
        key: 'random',


        /**
         * Returns a random number between min and max, inclusive
         * @param  {Number} min
         * @param  {Number} max
         */
        value: function random(min, max) {
            if (max == null) {
                max = min;
                min = 0;
            }
            return min + Math.floor(Math.random() * (max - min + 1));
        }
    }, {
        key: 'randomColor',


        /**
         * Returns a random hex color
         * @return {String} 
         */
        value: function randomColor() {
            return '#' + Math.floor(Math.random() * 16777215).toString(16);
        }
    }, {
        key: 'moveToEnd',
        value: function moveToEnd(arr, numElements) {
            for (var i = 0; i < numElements; i++) {
                arr.push(arr.shift());
            }
        }
    }, {
        key: 'posNeg',
        value: function posNeg() {
            if (this.random(0, 1) === 0) return -1;else return 1;
        }
    }, {
        key: 'getRGB',
        value: function getRGB(color) {
            d = document.getElementById("staticBackgroundCanvas");
            d.style.color = color;
            document.body.appendChild(d);
            return window.getComputedStyle(d).color;
        }
    }, {
        key: 'setOpacity',
        value: function setOpacity(color, opacity) {
            var rgb = this.getRGB(color).replace("rgb", "rgba");
            return [rgb.slice(0, rgb.length - 1), ", " + opacity.toString(), rgb.slice(rgb.length - 1)].join("");
        }
    }, {
        key: 'getCenterX',
        value: function getCenterX(innerWidth, outer) {
            var outerCenter = outer.x + outer.width / 2;
            var innerOffset = innerWidth / 2;
            var innerX = outerCenter - innerOffset;
            return innerX;
        }
    }, {
        key: 'getBezierDistance',
        value: function getBezierDistance(n) {
            return n / 2 * 0.552284749831;
        }
    }, {
        key: 'degToRad',
        value: function degToRad(deg) {
            return deg * (Math.PI / 180);
        }
    }, {
        key: 'getAngle',
        value: function getAngle(p1, p2) {
            return Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
        }
    }, {
        key: 'getDistance',
        value: function getDistance(p1, p2) {
            return Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y));
        }
    }, {
        key: 'rotatePoint',
        value: function rotatePoint(point, origin, deg) {
            var angle = deg * Math.PI / 180.0;
            var x = Math.cos(angle) * (point.x - origin.x) - Math.sin(angle) * (point.y - origin.y) + origin.x;
            var y = Math.sin(angle) * (point.x - origin.x) + Math.cos(angle) * (point.y - origin.y) + origin.y;
            return new _point.Point(x, y);
        }
    }, {
        key: 'getPointOnLine',
        value: function getPointOnLine(firstPoint, width, angle) {
            var secondPointX = firstPoint.x + width * Math.cos(this.degToRad(angle));
            var secondPointY = firstPoint.y + width * Math.sin(this.degToRad(angle));
            return new _point.Point(secondPointX, secondPointY);
        }
    }, {
        key: 'copyPoint',
        value: function copyPoint(point) {
            return new _point.Point(point.x, point.y);
        }
    }, {
        key: 'copyLine',
        value: function copyLine(line) {
            return new _line.Line(this.copyPoint(line.start), this.copyPoint(line.end));
        }
    }, {
        key: 'resizeLine',
        value: function resizeLine(line, amount) {
            var angle = this.getAngle(line.start, line.end);
            line.start = this.getPointOnLine(line.start, amount, angle);
            line.end = this.getPointOnLine(line.end, -amount, angle);
        }
    }, {
        key: 'moveLineHorizontal',
        value: function moveLineHorizontal(line, amount) {
            line.start.x += amount;
            line.end.x += amount;
        }
    }, {
        key: 'moveLineVertical',
        value: function moveLineVertical(line, amount) {
            line.start.y += amount;
            line.end.y += amount;
        }
    }, {
        key: 'polygonArea',
        value: function polygonArea(lines) {

            var X = [];
            var Y = [];

            lines.forEach(function (line) {
                X.push(line.start.x);
                X.push(line.end.x);
                Y.push(line.start.y);
                Y.push(line.end.y);
            });

            var numPoints = X.length;

            var area = 0; // Accumulates area in the loop
            var j = numPoints - 1; // The last vertex is the 'previous' one to the first

            for (var i = 0; i < numPoints; i++) {
                area = area + (X[j] + X[i]) * (Y[j] - Y[i]);
                j = i; //j is previous vertex to i
            }
            return -area / 2;
        }
    }, {
        key: 'orientation',
        value: function orientation(line) {
            var result = null;
            if (line.start.x <= line.end.x && line.start.y <= line.end.y) {
                result = "I";
            }
            if (line.start.x <= line.end.x && line.start.y > line.end.y) {
                result = "II";
            }
            if (line.start.x > line.end.x && line.start.y > line.end.y) {
                result = "III";
            }
            if (line.start.x > line.end.x && line.start.y <= line.end.y) {
                result = "IV";
            }
            return result;
        }
    }, {
        key: 'intersection',
        value: function intersection(line1, line2) {
            // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
            var denominator,
                a,
                b,
                numerator1,
                numerator2,
                result = {
                x: null,
                y: null,
                onLine1: false,
                onLine2: false
            };
            denominator = (line2.end.y - line2.start.y) * (line1.end.x - line1.start.x) - (line2.end.x - line2.start.x) * (line1.end.y - line1.start.y);
            if (denominator == 0) {
                return result;
            }
            a = line1.start.y - line2.start.y;
            b = line1.start.x - line2.start.x;
            numerator1 = (line2.end.x - line2.start.x) * a - (line2.end.y - line2.start.y) * b;
            numerator2 = (line1.end.x - line1.start.x) * a - (line1.end.y - line1.start.y) * b;
            a = numerator1 / denominator;
            b = numerator2 / denominator;

            // if we cast these lines infinitely in both directions, they intersect here:
            result.x = line1.start.x + a * (line1.end.x - line1.start.x);
            result.y = line1.start.y + a * (line1.end.y - line1.start.y);
            /*
                    // it is worth noting that this should be the same as:
                    x = line2.start.x + (b * (line2.end.x - line2.start.x));
                    y = line2.start.x + (b * (line2.end.y - line2.start.y));
                    */
            // if line1 is a segment and line2 is infinite, they intersect if:
            if (a > 0 && a < 1) {
                result.onLine1 = true;
            }
            // if line2 is a segment and line1 is infinite, they intersect if:
            if (b > 0 && b < 1) {
                result.onLine2 = true;
            }
            // if line1 and line2 are segments, they intersect if both of the above are true
            return result;
        }
    }, {
        key: 'shadeColor',
        value: function shadeColor(color, percent) {
            var f = parseInt(color.slice(1), 16),
                t = percent < 0 ? 0 : 255,
                p = percent < 0 ? percent * -1 : percent,
                R = f >> 16,
                G = f >> 8 & 0x00FF,
                B = f & 0x0000FF;
            return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
        }
    }]);

    return trees;
}();

window.trees = trees;
exports.trees = trees;

},{"./engine/engine.js":56,"./engine/line.js":57,"./engine/point.js":63}]},{},[1]);
